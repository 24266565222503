export type Maybe<T> = T | null | undefined
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Long: { input: number; output: number }
  Currency: { input: string; output: string }
  Date: { input: string; output: string }
  DateTime: { input: string; output: string }
  Decimal: { input: number; output: number }
  DateTimeOffset: { input: string; output: string }
  Milliseconds: { input: number; output: number }
  Seconds: { input: number; output: number }
}

export type Query = {
  __typename: 'Query'
  addressesSchemaIsOk: Scalars['Boolean']['output']
  addressLookup: AddressLookupResult
  announcement?: Maybe<Announcement>
  appBanner?: Maybe<AppBanner>
  autocompleteAddress: AutocompleteAddressResult
  basketSchemaIsOk: Scalars['Boolean']['output']
  businessAvailableSubstitutions: Array<BusinessAvailableSubstitution>
  businessProductVariant?: Maybe<BusinessProductVariantResponse>
  businessSchemaIsOk: Scalars['Boolean']['output']
  colors: ColorsResult
  contactPreferencesSchemaIsOk: Scalars['Boolean']['output']
  content: Content
  contentDepartments: Array<ContentDepartment>
  contentSchemaIsOk: Scalars['Boolean']['output']
  countries: Array<Country>
  customerCreationsSchemaIsOk: Scalars['Boolean']['output']
  customerSchemaIsOk: Scalars['Boolean']['output']
  customFonts: CreationsCustomFontResponse
  defaultPackaging?: Maybe<DefaultPackagingResult>
  design: DesignResult
  designCollageGrids: DesignCollageGridsResult
  designDefault: DesignResult
  designImageConfig: DesignImageConfig
  designs: DesignsResult
  designSceneLayouts: DesignSceneLayoutsResult
  designSchemaIsOk: Scalars['Boolean']['output']
  /** Request a SmartText message to be used in a design. */
  designSmartText: DesignSmartTextResult
  /** Returns either the smart text config or design service error indicating something has gone wrong. */
  designSmartTextConfig: DesignSmartTextConfigResult
  favouritesSchemaIsOk: Scalars['Boolean']['output']
  filters: FilterResults
  filtersSchemaIsOk: Scalars['Boolean']['output']
  fonts: FontsResult
  getBasketById?: Maybe<Basket>
  getBusinessDeliveryOptions: BusinessDeliveryOptions
  groupCardAddMessageState: AddMessageStateResult
  groupCardAddMessageStateV2: AddMessageStateResultV2
  groupCardConfig: GroupCardConfig
  groupCardConfigV2: GroupCardConfigV2
  groupCardOccasions: GroupCardOccasionsResult
  groupCardsSchemaIsOk: Scalars['Boolean']['output']
  groupCardTestCustomer: Customer
  infoBanner?: Maybe<InfoBanner>
  inlay: InlayResult
  inlaysSchemaIsOk: Scalars['Boolean']['output']
  me: Me
  media: MediaResult
  mediaConfig: MediaConfig
  mediaRecipient: MediaRecipientResult
  mediaSchemaIsOk: Scalars['Boolean']['output']
  membershipsSchemaIsOk?: Maybe<Scalars['Boolean']['output']>
  multiSendSchemaIsOk: Scalars['Boolean']['output']
  nationalOccasionDates: Array<NationalOccasionDateResult>
  navigation: Navigation
  nextUnfulfilledReminder?: Maybe<Reminder>
  ordersByDeliveryPostcode?: Maybe<Orders>
  ordersByOrderId?: Maybe<Orders>
  ordersByOrderReference?: Maybe<Orders>
  ordersSchemaIsOk: Scalars['Boolean']['output']
  page?: Maybe<PageResult>
  paymentsSchemaIsOk: Scalars['Boolean']['output']
  postcodeLookup: PostcodeLookupResult
  productAddon?: Maybe<Addon>
  productAddons?: Maybe<Array<Addon>>
  productLookup: ProductLookupResults
  productSearch: ProductSearchResults
  productsLookupSchemaIsOk: Scalars['Boolean']['output']
  productsSchemaIsOk: Scalars['Boolean']['output']
  purchaseIsForReminder: Scalars['Boolean']['output']
  recipientsSchemaIsOk?: Maybe<Scalars['Boolean']['output']>
  recommendations: Array<Recommendation>
  recommendationsByCategory: Array<RecommendationCategory>
  recommendationsBySearchCriteria: Array<Product>
  recommendationsSchemaIsOk: Scalars['Boolean']['output']
  redemption: RedemptionResult
  redemptionSchemaIsOk: Scalars['Boolean']['output']
  reminder: GetReminderResult
  reminderOccasions: Array<ReminderOccasion>
  reminderRelationships: Array<ReminderRelationship>
  reminders: Array<Reminder>
  remindersSchemaIsOk: Scalars['Boolean']['output']
  /** @deprecated This feature has been decomissioned and will now always return an error result */
  rendererDynamicImage: RendererDynamicImageResult
  rendererSchemaIsOk: Scalars['Boolean']['output']
  rendererShareableImages: RendererShareableImagesResult
  searchInspirations: SearchInspirationsResults
  searchSchemaIsOk: Scalars['Boolean']['output']
  searchSuggest: SearchSuggestResults
  sharedReminder: SharedReminderResult
  stickers: StickersResult
  suggestedMessages: Array<SuggestedMessage>
  suggestions: Suggestions
  suggestionsSchemaIsOk: Scalars['Boolean']['output']
  suggestQuickAdds: Array<SuggestedReminder>
  suggestReminders: Array<SuggestedReminder>
  trackingInformation?: Maybe<TrackingInformationResult>
}

export type QueryaddressLookupArgs = {
  input: AddressLookupInput
}

export type QueryannouncementArgs = {
  name: Scalars['String']['input']
  preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryappBannerArgs = {
  preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryautocompleteAddressArgs = {
  input: AutocompleteAddressInput
}

export type QuerybusinessProductVariantArgs = {
  input: BusinessProductVariantInput
}

export type QuerycolorsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter: ColorsFilterInput
  first?: InputMaybe<Scalars['Int']['input']>
}

export type QuerycontentDepartmentsArgs = {
  filter?: InputMaybe<Array<Scalars['ID']['input']>>
  platform?: InputMaybe<ContentPlatform>
  preview?: InputMaybe<Scalars['Boolean']['input']>
  region?: InputMaybe<Region>
}

export type QuerydefaultPackagingArgs = {
  input?: InputMaybe<DefaultPackagingInput>
}

export type QuerydesignArgs = {
  filter?: InputMaybe<DesignFilterInput>
  id: Scalars['ID']['input']
}

export type QuerydesignDefaultArgs = {
  filter?: InputMaybe<DesignFilterInput>
  productKey: Scalars['String']['input']
}

export type QuerydesignsArgs = {
  filter?: InputMaybe<DesignsFilterInput>
}

export type QuerydesignSmartTextArgs = {
  input: DesignSmartTextInput
}

export type QueryfiltersArgs = {
  department?: InputMaybe<Array<InputMaybe<DepartmentsEnum>>>
  experimentValues?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<CriteriaInput>
  nbaAlgorithm?: InputMaybe<Scalars['String']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  useImprovedRecall?: InputMaybe<Scalars['Boolean']['input']>
  useStemming?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryfontsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter: FontsFilterInput
  first?: InputMaybe<Scalars['Int']['input']>
}

export type QuerygetBasketByIdArgs = {
  id: Scalars['ID']['input']
}

export type QuerygroupCardAddMessageStateArgs = {
  addMessageToken: Scalars['String']['input']
}

export type QuerygroupCardAddMessageStateV2Args = {
  addMessageToken: Scalars['String']['input']
}

export type QueryinfoBannerArgs = {
  preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryinlayArgs = {
  height: Scalars['Float']['input']
  id: Scalars['ID']['input']
  width: Scalars['Float']['input']
}

export type QuerymediaArgs = {
  activated?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  interactive?: InputMaybe<Scalars['Boolean']['input']>
}

export type QuerymediaRecipientArgs = {
  key: Scalars['String']['input']
}

export type QuerynavigationArgs = {
  capabilities?: InputMaybe<Array<NavigationCapability>>
  name: Scalars['String']['input']
  preview?: InputMaybe<Scalars['Boolean']['input']>
  region?: InputMaybe<Region>
}

export type QuerynextUnfulfilledReminderArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryordersByDeliveryPostcodeArgs = {
  input: OrdersByDeliveryPostcodeInput
}

export type QueryordersByOrderIdArgs = {
  input: OrdersByOrderIdInput
}

export type QueryordersByOrderReferenceArgs = {
  input: OrdersByOrderReferenceInput
}

export type QuerypageArgs = {
  path: Scalars['String']['input']
  platform?: InputMaybe<ContentPlatform>
  preview?: InputMaybe<Scalars['Boolean']['input']>
  variations?: InputMaybe<Array<ContentVariationInput>>
}

export type QuerypostcodeLookupArgs = {
  postcode: Scalars['String']['input']
}

export type QueryproductAddonArgs = {
  addonSku: Scalars['String']['input']
}

export type QueryproductAddonsArgs = {
  sku: Scalars['String']['input']
}

export type QueryproductLookupArgs = {
  experimentValues?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<CriteriaInput>
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  store?: InputMaybe<StoresEnumType>
}

export type QueryproductSearchArgs = {
  customerId?: InputMaybe<Scalars['String']['input']>
  department?: InputMaybe<Array<InputMaybe<DepartmentsEnum>>>
  departmentId?: InputMaybe<Scalars['Int']['input']>
  experimentValues?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<CriteriaInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  nbaAlgorithm?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  platform?: InputMaybe<Platform>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  skipSpellingCorrection?: InputMaybe<Scalars['Boolean']['input']>
  sortOrder?: InputMaybe<SortOrder>
  sponsoredProducts?: InputMaybe<Array<SponsoredProductInput>>
  store?: InputMaybe<StoresEnumType>
  useImprovedRecall?: InputMaybe<Scalars['Boolean']['input']>
  useStemming?: InputMaybe<Scalars['Boolean']['input']>
}

export type QuerypurchaseIsForReminderArgs = {
  name: Scalars['String']['input']
  occasion: Occasion
}

export type QueryrecommendationsArgs = {
  algorithmId?: InputMaybe<Scalars['String']['input']>
  productId: Scalars['String']['input']
}

export type QueryrecommendationsByCategoryArgs = {
  algorithmId?: InputMaybe<Scalars['String']['input']>
  facets?: InputMaybe<RecommendationFacets>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  productId: Scalars['String']['input']
}

export type QueryrecommendationsBySearchCriteriaArgs = {
  facetKeys: Array<Scalars['String']['input']>
  first?: Scalars['Int']['input']
  searchTerm: Scalars['String']['input']
}

export type QueryredemptionArgs = {
  input: RedemptionInput
}

export type QueryreminderArgs = {
  input: GetReminderInput
}

export type QueryremindersArgs = {
  input: GetRemindersInput
}

export type QueryrendererDynamicImageArgs = {
  elementHeight: Scalars['Int']['input']
  elementWidth: Scalars['Int']['input']
  format: RendererDynamicImageFormat
  id: Scalars['ID']['input']
  textParts: Array<Scalars['String']['input']>
}

export type QueryrendererShareableImagesArgs = {
  key: Scalars['String']['input']
}

export type QuerysearchSuggestArgs = {
  experimentValues?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  searchTerm: Scalars['String']['input']
  suggesterType?: InputMaybe<SuggesterType>
}

export type QuerysharedReminderArgs = {
  payload: Scalars['String']['input']
}

export type QuerystickersArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter: StickersFilterInput
  first?: InputMaybe<Scalars['Int']['input']>
}

export type QuerysuggestRemindersArgs = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type QuerytrackingInformationArgs = {
  input: TrackingInformationInput
}

export type AddressLookupInput = {
  country: Scalars['String']['input']
  houseNumber?: InputMaybe<Scalars['String']['input']>
  houseNumberExtension?: InputMaybe<Scalars['String']['input']>
  postalCode: Scalars['String']['input']
}

export type AddressLookupResult = AddressLookupError | AddressLookupSuccess

export type AddressLookupError = Error & {
  __typename: 'AddressLookupError'
  message: Scalars['String']['output']
}

export type Error = {
  message: Scalars['String']['output']
}

export type AddressLookupSuccess = {
  __typename: 'AddressLookupSuccess'
  addresses: Array<LookupAddress>
}

export type LookupAddress = {
  __typename: 'LookupAddress'
  addressFirstLine: Scalars['String']['output']
  city?: Maybe<Scalars['String']['output']>
  houseNumber?: Maybe<Scalars['String']['output']>
  houseNumberExtension?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  postcode: Scalars['String']['output']
  state?: Maybe<Scalars['String']['output']>
  streetName?: Maybe<Scalars['String']['output']>
}

export type Announcement = {
  __typename: 'Announcement'
  cta: CTA
  detail: Scalars['String']['output']
  heading: Scalars['String']['output']
  id: Scalars['ID']['output']
  image: ContentImage
  title: Scalars['String']['output']
}

export type CTA = {
  __typename: 'CTA'
  action: CTAAction
  /** @deprecated Use action */
  shouldFormat?: Maybe<Scalars['Boolean']['output']>
  title: Scalars['String']['output']
  /** @deprecated Use action */
  url: Scalars['String']['output']
}

export type CTAAction = AddMembershipToBasketAction | LinkAction

export type AddMembershipToBasketAction = {
  __typename: 'AddMembershipToBasketAction'
  membershipType: MembershipType
}

export enum MembershipType {
  MOONPIG_PLUS = 'MOONPIG_PLUS',
  PLUS = 'PLUS',
}

export type LinkAction = {
  __typename: 'LinkAction'
  shouldFormat?: Maybe<Scalars['Boolean']['output']>
  title?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type ContentImage = {
  __typename: 'ContentImage'
  description: Scalars['String']['output']
  dimensions: ContentDimensions
  url: Scalars['String']['output']
}

export type ContentImageurlArgs = {
  background?: InputMaybe<Scalars['String']['input']>
  fit?: InputMaybe<ContentImageFit>
  focus?: InputMaybe<ContentImageFocus>
  format?: InputMaybe<ContentImageFormat>
  height?: InputMaybe<Scalars['Int']['input']>
  quality?: InputMaybe<Scalars['Int']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type ContentDimensions = {
  __typename: 'ContentDimensions'
  height: Scalars['Int']['output']
  width: Scalars['Int']['output']
}

export enum ContentImageFit {
  crop = 'crop',
  fill = 'fill',
  thumb = 'thumb',
}

export enum ContentImageFocus {
  bottom = 'bottom',
  left = 'left',
  right = 'right',
  top = 'top',
}

export enum ContentImageFormat {
  jpg = 'jpg',
  png = 'png',
  webp = 'webp',
}

export type AppBanner = {
  __typename: 'AppBanner'
  /** @deprecated rating is depricated. Please use reviews.stars field instead */
  rating: Scalars['Float']['output']
  reviews: AppBannerReviews
  shouldFormat: Scalars['Boolean']['output']
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
  /** @deprecated totalRatings is depricated. Please use reviews.count field instead */
  totalRatings: Scalars['String']['output']
  url: Scalars['String']['output']
  voucherCode?: Maybe<Scalars['String']['output']>
}

export type AppBannerReviews = {
  __typename: 'AppBannerReviews'
  count: Scalars['Int']['output']
  stars: Scalars['Float']['output']
}

export type AutocompleteAddressInput = {
  country: Scalars['String']['input']
  query: Scalars['String']['input']
}

export type AutocompleteAddressResult =
  | AutocompleteAddressError
  | AutocompleteAddressSuccess

export type AutocompleteAddressError = Error & {
  __typename: 'AutocompleteAddressError'
  message: Scalars['String']['output']
}

export type AutocompleteAddressSuccess = {
  __typename: 'AutocompleteAddressSuccess'
  addresses: Array<AutocompleteAddress>
}

export type AutocompleteAddress = {
  __typename: 'AutocompleteAddress'
  addressFirstLine: Scalars['String']['output']
  city?: Maybe<Scalars['String']['output']>
  country: Scalars['String']['output']
  displayAddress: Scalars['String']['output']
  displayLines: Array<Scalars['String']['output']>
  houseNumber?: Maybe<Scalars['String']['output']>
  houseNumberExtension?: Maybe<Scalars['String']['output']>
  postcode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
}

export type BusinessAvailableSubstitution = {
  __typename: 'BusinessAvailableSubstitution'
  key: BusinessSubstitution
  placeholder: Scalars['String']['output']
}

export enum BusinessSubstitution {
  FIRST_NAME = 'FIRST_NAME',
  MESSAGE = 'MESSAGE',
  TO = 'TO',
}

export type BusinessProductVariantInput = {
  productKey: Scalars['String']['input']
  sku: Scalars['String']['input']
}

export type BusinessProductVariantResponse =
  | BusinessProductVariant
  | BusinessProductVariantNotFoundError

export type BusinessProductVariant = {
  __typename: 'BusinessProductVariant'
  images: Array<BusinessProductImage>
  price: Money
  productKey: Scalars['String']['output']
  sku: Scalars['String']['output']
}

export type BusinessProductImage = {
  __typename: 'BusinessProductImage'
  dimensions: BusinessProductImageDimensions
  url: Scalars['String']['output']
}

export type BusinessProductImageDimensions = {
  __typename: 'BusinessProductImageDimensions'
  h: Scalars['Long']['output']
  w: Scalars['Long']['output']
}

export type Money = {
  __typename: 'Money'
  amount: Scalars['String']['output']
  centAmount: Scalars['Long']['output']
  currencyCode: Scalars['Currency']['output']
  display: Scalars['String']['output']
  fractionDigits: Scalars['Int']['output']
}

export type BusinessProductVariantNotFoundError = Error & {
  __typename: 'BusinessProductVariantNotFoundError'
  message: Scalars['String']['output']
}

export type ColorsFilterInput = {
  colorSetIds: Array<Scalars['ID']['input']>
}

export type ColorsResult =
  | ColorEdges
  | DesignInvalidCursorError
  | DesignServiceError

export type ColorEdges = {
  __typename: 'ColorEdges'
  edges: Array<ColorEdge>
  pageInfo: DesignCustomisationPageInfo
  totalCount: Scalars['Int']['output']
}

export type ColorEdge = {
  __typename: 'ColorEdge'
  cursor: Scalars['ID']['output']
  node: Color
}

export type Color = {
  __typename: 'Color'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type DesignCustomisationPageInfo = {
  __typename: 'DesignCustomisationPageInfo'
  endCursor?: Maybe<Scalars['ID']['output']>
  hasNextPage: Scalars['Boolean']['output']
}

export type DesignInvalidCursorError = Error & {
  __typename: 'DesignInvalidCursorError'
  message: Scalars['String']['output']
}

/** Generic error response from a request to the Design API. */
export type DesignServiceError = Error & {
  __typename: 'DesignServiceError'
  message: Scalars['String']['output']
}

export type Content = {
  __typename: 'Content'
  headerLogo?: Maybe<HeaderLogo>
}

export type HeaderLogo = {
  __typename: 'HeaderLogo'
  icon?: Maybe<ContentImage>
}

export enum ContentPlatform {
  android = 'android',
  ios = 'ios',
  web = 'web',
}

export enum Region {
  au = 'au',
  ie = 'ie',
  nl = 'nl',
  uk = 'uk',
  us = 'us',
}

export type ContentDepartment = {
  __typename: 'ContentDepartment'
  dispatchCutOff: ContentDepartmentDispatchCutOff
  id: Scalars['ID']['output']
  longDeliveryInfo: Scalars['String']['output']
  promotionBanner?: Maybe<Scalars['String']['output']>
  shortDeliveryInfo: Scalars['String']['output']
  subdepartments: Array<ContentDepartment>
}

export type ContentDepartmentDispatchCutOff = {
  __typename: 'ContentDepartmentDispatchCutOff'
  weekdays: CutOff
  weekendsAndBankHolidays: CutOff
}

export type CutOff = {
  __typename: 'CutOff'
  message: Scalars['String']['output']
  time: Scalars['String']['output']
}

export type Country = {
  __typename: 'Country'
  code: Scalars['String']['output']
  label: Scalars['String']['output']
}

export type CreationsCustomFontResponse =
  | CreationsCustomFontError
  | CreationsCustomFontsList

export type CreationsCustomFontError = Error & {
  __typename: 'CreationsCustomFontError'
  message: Scalars['String']['output']
}

export type CreationsCustomFontsList = {
  __typename: 'CreationsCustomFontsList'
  edges: Array<CreationsCustomFontEdge>
  pageInfo: CreationsCustomFontsPageInfo
  totalCount: Scalars['Int']['output']
}

export type CreationsCustomFontEdge = {
  __typename: 'CreationsCustomFontEdge'
  cursor: Scalars['ID']['output']
  node: CreationsCustomFont
}

export type CreationsCustomFont = {
  __typename: 'CreationsCustomFont'
  createdAt: Scalars['String']['output']
  fontFace?: Maybe<CustomFontFace>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: FontStatus
  updatedAt: Scalars['String']['output']
  upload?: Maybe<CustomerCreationsFontUpload>
}

export type CustomFontFace = {
  __typename: 'CustomFontFace'
  id: Scalars['ID']['output']
  metrics?: Maybe<CustomFontFaceMetrics>
  name: Scalars['String']['output']
  urls: CustomFontFaceUrls
}

export type CustomFontFaceMetrics = {
  __typename: 'CustomFontFaceMetrics'
  head: CustomFontFaceMetricsHead
  hhea: CustomFontFaceMetricsHhea
  os2: CustomFontFaceMetricsOs2
}

export type CustomFontFaceMetricsHead = {
  __typename: 'CustomFontFaceMetricsHead'
  unitsPerEm: Scalars['Float']['output']
  yMax: Scalars['Float']['output']
  yMin: Scalars['Float']['output']
}

export type CustomFontFaceMetricsHhea = {
  __typename: 'CustomFontFaceMetricsHhea'
  ascender: Scalars['Float']['output']
  descender: Scalars['Float']['output']
  lineGap: Scalars['Float']['output']
}

export type CustomFontFaceMetricsOs2 = {
  __typename: 'CustomFontFaceMetricsOs2'
  fsSelection: Scalars['Float']['output']
  fsType: Scalars['Float']['output']
  sCapHeight: Scalars['Float']['output']
  sTypoAscender: Scalars['Float']['output']
  sTypoDescender: Scalars['Float']['output']
  sTypoLineGap: Scalars['Float']['output']
  sxHeight: Scalars['Float']['output']
  usWinAscent: Scalars['Float']['output']
  usWinDescent: Scalars['Float']['output']
}

export type CustomFontFaceUrls = {
  __typename: 'CustomFontFaceUrls'
  ttf: Scalars['String']['output']
  woff: Scalars['String']['output']
  woff2: Scalars['String']['output']
}

export enum FontStatus {
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
  UPLOADING = 'UPLOADING',
}

export type CustomerCreationsFontUpload = {
  __typename: 'CustomerCreationsFontUpload'
  fields: Array<CustomerCreationsFontUploadField>
  uploadUrl: Scalars['String']['output']
}

export type CustomerCreationsFontUploadField = {
  __typename: 'CustomerCreationsFontUploadField'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type CreationsCustomFontsPageInfo = {
  __typename: 'CreationsCustomFontsPageInfo'
  endCursor?: Maybe<Scalars['ID']['output']>
  hasNextPage: Scalars['Boolean']['output']
}

export type DefaultPackagingInput = {
  sku: Scalars['String']['input']
}

export type DefaultPackagingResult = VariantPackaging

export type VariantPackaging = {
  __typename: 'VariantPackaging'
  image: Image
  inStock: Scalars['Boolean']['output']
  price: Money
  sku: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type Image = {
  __typename: 'Image'
  /** @deprecated Please do not use this field. It will be removed in the coming releases */
  dimensions: Dimensions
  url: Scalars['String']['output']
}

export type Dimensions = {
  __typename: 'Dimensions'
  height: Scalars['Int']['output']
  width: Scalars['Int']['output']
}

export type DesignFilterInput = {
  includePrintOnlyScenes?: InputMaybe<Scalars['Boolean']['input']>
}

export type DesignResult =
  | Design
  | DesignAuthorizationError
  | DesignNotFoundError
  | DesignServiceError
  | DesignTemplatesServiceError

export type Design = {
  __typename: 'Design'
  availableStickerSets: Array<DesignAvailableStickerSet>
  availableTextStyles: DesignAvailableTextStyles
  capabilities: Array<DesignCapability>
  clientOrigin?: Maybe<DesignClientOrigin>
  compositions: Array<DesignComposition>
  createdAt: Scalars['String']['output']
  defaultTextStyle?: Maybe<DesignDefaultTextStyle>
  expiresOn: Scalars['Int']['output']
  id: Scalars['ID']['output']
  images: SceneImagesResult
  layout: DesignLayout
  /** @deprecated No longer supported */
  legacyCustomerId?: Maybe<Scalars['String']['output']>
  limits: DesignLimits
  originRegion: Scalars['String']['output']
  owner?: Maybe<Customer>
  productKey: Scalars['String']['output']
  requiresNewRenderer: Scalars['Boolean']['output']
  scenes: Array<DesignScene>
  sku?: Maybe<Scalars['String']['output']>
  sourceId?: Maybe<Scalars['ID']['output']>
  stampTheme: DesignStampTheme
  state: DesignState
  /**
   * Legacy store field. Not used by the Design API in any way and not used by any consumers.
   * Verified across all clients that this field is not used on 2023-04-26.
   * @deprecated No longer supported
   */
  store?: Maybe<DesignStore>
  token: Scalars['String']['output']
  updatedAt: Scalars['String']['output']
  usedFeatures: Array<EditorFeature>
  version: Scalars['Int']['output']
}

export type DesignimagesArgs = {
  composition?: InputMaybe<Scalars['Boolean']['input']>
  substitutions?: InputMaybe<Array<RendererSubstitutionInput>>
}

export type DesignAvailableStickerSet = {
  __typename: 'DesignAvailableStickerSet'
  id: Scalars['ID']['output']
}

export type DesignAvailableTextStyles = {
  __typename: 'DesignAvailableTextStyles'
  availableColors: Array<DesignColor>
  availableFontFaces: Array<DesignFontFace>
  availableFontSizes: Array<Scalars['Float']['output']>
}

export type DesignColor = {
  __typename: 'DesignColor'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type DesignFontFace = {
  __typename: 'DesignFontFace'
  id: Scalars['ID']['output']
  metrics?: Maybe<DesignFontFaceMetrics>
  name: Scalars['String']['output']
  urls: DesignFontFaceUrls
}

export type DesignFontFaceMetrics = {
  __typename: 'DesignFontFaceMetrics'
  head: DesignFontFaceMetricsHead
  hhea: DesignFontFaceMetricsHhea
  os2: DesignFontFaceMetricsOs2
}

export type DesignFontFaceMetricsHead = {
  __typename: 'DesignFontFaceMetricsHead'
  unitsPerEm: Scalars['Float']['output']
  yMax: Scalars['Float']['output']
  yMin: Scalars['Float']['output']
}

export type DesignFontFaceMetricsHhea = {
  __typename: 'DesignFontFaceMetricsHhea'
  ascender: Scalars['Float']['output']
  descender: Scalars['Float']['output']
  lineGap: Scalars['Float']['output']
}

export type DesignFontFaceMetricsOs2 = {
  __typename: 'DesignFontFaceMetricsOs2'
  fsSelection: Scalars['Float']['output']
  fsType: Scalars['Float']['output']
  sCapHeight: Scalars['Float']['output']
  sTypoAscender: Scalars['Float']['output']
  sTypoDescender: Scalars['Float']['output']
  sTypoLineGap: Scalars['Float']['output']
  sxHeight: Scalars['Float']['output']
  usWinAscent: Scalars['Float']['output']
  usWinDescent: Scalars['Float']['output']
}

export type DesignFontFaceUrls = {
  __typename: 'DesignFontFaceUrls'
  ttf: Scalars['String']['output']
  woff: Scalars['String']['output']
  woff2: Scalars['String']['output']
}

export enum DesignCapability {
  /** @deprecated Enabled by default */
  TEXT_STYLE_SETS = 'TEXT_STYLE_SETS',
}

export type DesignClientOrigin = {
  __typename: 'DesignClientOrigin'
  name: Scalars['String']['output']
  version?: Maybe<Scalars['String']['output']>
}

export type DesignComposition = {
  __typename: 'DesignComposition'
  height: Scalars['Int']['output']
  layers: Array<DesignCompositionLayer>
  width: Scalars['Int']['output']
}

export type DesignCompositionLayer =
  | DesignCompositionLayerMapping
  | DesignCompositionLayerStatic

export type DesignCompositionLayerMapping = {
  __typename: 'DesignCompositionLayerMapping'
  blendMode: DesignCompositionLayerBlendMode
  imageUrl: Scalars['String']['output']
  opacity: Scalars['Float']['output']
  sceneId: Scalars['ID']['output']
}

export enum DesignCompositionLayerBlendMode {
  COLOR = 'COLOR',
  COLOR_BURN = 'COLOR_BURN',
  COLOR_DODGE = 'COLOR_DODGE',
  DARKEN = 'DARKEN',
  DIFFERENCE = 'DIFFERENCE',
  EXCLUSION = 'EXCLUSION',
  HARD_LIGHT = 'HARD_LIGHT',
  HUE = 'HUE',
  LIGHTEN = 'LIGHTEN',
  LIGHTER = 'LIGHTER',
  MULTIPLY = 'MULTIPLY',
  OVERLAY = 'OVERLAY',
  SATURATION = 'SATURATION',
  SCREEN = 'SCREEN',
  SOFT_LIGHT = 'SOFT_LIGHT',
  SOURCE_OVER = 'SOURCE_OVER',
}

export type DesignCompositionLayerStatic = {
  __typename: 'DesignCompositionLayerStatic'
  blendMode: DesignCompositionLayerBlendMode
  imageUrl: Scalars['String']['output']
  opacity: Scalars['Float']['output']
}

export type DesignDefaultTextStyle = {
  __typename: 'DesignDefaultTextStyle'
  colorId: Scalars['String']['output']
  fontFaceId: Scalars['String']['output']
  fontSize: Scalars['Float']['output']
}

export type RendererSubstitutionInput = {
  name: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type SceneImagesResult = RendererServiceError | SceneImages

export type RendererServiceError = Error & {
  __typename: 'RendererServiceError'
  message: Scalars['String']['output']
}

export type SceneImages = {
  __typename: 'SceneImages'
  images: Array<SceneImage>
}

export type SceneImage = {
  __typename: 'SceneImage'
  title: Scalars['String']['output']
  variants: SceneImageVariants
}

export type SceneImageVariants = {
  __typename: 'SceneImageVariants'
  large: SceneImageVariant
  small: SceneImageVariant
}

export type SceneImageVariant = {
  __typename: 'SceneImageVariant'
  height: Scalars['Int']['output']
  url: Scalars['String']['output']
  width: Scalars['Int']['output']
}

export enum DesignLayout {
  CARD_LANDSCAPE = 'CARD_LANDSCAPE',
  CARD_PORTRAIT = 'CARD_PORTRAIT',
  CARD_SQUARE = 'CARD_SQUARE',
  GENERIC = 'GENERIC',
  LABELS_LANDSCAPE = 'LABELS_LANDSCAPE',
  LABELS_PORTRAIT = 'LABELS_PORTRAIT',
  MUG = 'MUG',
  POSTCARD_LANDSCAPE = 'POSTCARD_LANDSCAPE',
  POSTCARD_PORTRAIT = 'POSTCARD_PORTRAIT',
  SLEEVE_HORIZONTAL = 'SLEEVE_HORIZONTAL',
  SLEEVE_VERTICAL = 'SLEEVE_VERTICAL',
  TSHIRT = 'TSHIRT',
  TSHIRT_FRONT = 'TSHIRT_FRONT',
  TSHIRT_FRONT_BACK = 'TSHIRT_FRONT_BACK',
  UNKNOWN = 'UNKNOWN',
}

export type DesignLimits = {
  __typename: 'DesignLimits'
  maxHandwritingCount: Scalars['Int']['output']
  maxImageCount: Scalars['Int']['output']
  maxInlayCount: Scalars['Int']['output']
  maxMediaCount: Scalars['Int']['output']
  maxStickerCount: Scalars['Int']['output']
  maxTextCount: Scalars['Int']['output']
}

export type Customer = Versioned & {
  __typename: 'Customer'
  availableCredentials?: Maybe<AvailableCredentials>
  businessDetails?: Maybe<BusinessDetails>
  businessOrder?: Maybe<BusinessOrderResponse>
  businessOrders: Array<BusinessOrder>
  businessProject?: Maybe<BusinessProjectResponse>
  /** @deprecated Experimental feature */
  businessProjectRecipientPreSignedUrl: BusinessProjectRecipientPreSignedUrlResult
  contactPreferences: ContactPreferences
  credits?: Maybe<CustomerCredits>
  /** Customer crmDetails. Returns SailThru Id for Moonpig & Canopy Deploy Id for Greetz. Cookie information is just for Sailthru. */
  crmDetails?: Maybe<CrmDetails>
  email: Scalars['String']['output']
  favourites: Favourites
  firstName: Scalars['String']['output']
  groupCardProject: GroupCardProjectResult
  groupCardProjects: GroupCardProjectsResult
  groupCardProjectsV2: GroupCardProjectsResultV2
  groupCardProjectV2: GroupCardProjectResultV2
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  /** @deprecated Exists only for migration from commerce-api. Use contactPreferences property instead */
  marketingConsentPosition: MarketingConsentPosition
  membership?: Maybe<Membership>
  /** @deprecated Experimental. */
  multiSendIsEnabled: Scalars['Boolean']['output']
  /** @deprecated Experimental. */
  multiSendTrackBasket: MultiSendTrackBasketResult
  myAddresses: Array<ShippingAddress>
  order?: Maybe<Order>
  orders?: Maybe<Orders>
  otherAddresses: Array<ShippingAddress>
  /** @deprecated Not ready for use */
  recipient?: Maybe<BaseRecipient>
  /** @deprecated Not ready for use */
  recipients: Array<BaseRecipient>
  recommendations: CustomerRecommendationsResponse
  savedCards?: Maybe<Array<SavedCard>>
  title?: Maybe<Scalars['String']['output']>
  version: Scalars['Long']['output']
}

export type CustomerbusinessOrderArgs = {
  input: BusinessOrderInput
}

export type CustomergroupCardProjectArgs = {
  projectId: Scalars['ID']['input']
}

export type CustomergroupCardProjectV2Args = {
  projectId: Scalars['ID']['input']
}

export type CustomermultiSendTrackBasketArgs = {
  input: MultiSendTrackBasketInput
}

export type CustomerorderArgs = {
  id: Scalars['ID']['input']
}

export type CustomerordersArgs = {
  limit?: InputMaybe<Scalars['Long']['input']>
  offset?: InputMaybe<Scalars['Long']['input']>
}

export type CustomerrecipientArgs = {
  recipientId: Scalars['ID']['input']
}

export type CustomerrecommendationsArgs = {
  request: CustomerRecommendationsRequest
}

export type CustomersavedCardsArgs = {
  store?: InputMaybe<Store>
}

export type Versioned = {
  version: Scalars['Long']['output']
}

export type AvailableCredentials = {
  __typename: 'AvailableCredentials'
  oidc?: Maybe<Array<OidcProvider>>
  password: Scalars['Boolean']['output']
}

export type OidcProvider = {
  __typename: 'OidcProvider'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type BusinessDetails = {
  __typename: 'BusinessDetails'
  billingAddress?: Maybe<BillingAddress>
  companyName?: Maybe<Scalars['String']['output']>
}

export type BillingAddress = {
  __typename: 'BillingAddress'
  addressFirstLine?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
  postalCode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  /** @deprecated Use addressFirstLine */
  streetName?: Maybe<Scalars['String']['output']>
}

export type BusinessOrderInput = {
  id: Scalars['ID']['input']
}

export type BusinessOrderResponse = BusinessOrder | BusinessOrderNotFoundError

export type BusinessOrder = {
  __typename: 'BusinessOrder'
  card: BusinessOrderCard
  createdOn: Scalars['Date']['output']
  earliestDeliveryDate: Scalars['Date']['output']
  hasReceipt: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  price: BusinessOrderPrice
  recipients: Array<BusinessOrderRecipient>
  substitutions: BusinessSubstitutions
}

export type BusinessOrderCard = {
  __typename: 'BusinessOrderCard'
  designId: Scalars['String']['output']
  productKey: Scalars['String']['output']
  sku: Scalars['String']['output']
}

export type BusinessOrderPrice = {
  __typename: 'BusinessOrderPrice'
  itemisation: BusinessOrderPriceItemisation
  total: BusinessOrderPriceTotal
}

export type BusinessOrderPriceItemisation = {
  __typename: 'BusinessOrderPriceItemisation'
  card: Money
  shipping: Money
}

export type BusinessOrderPriceTotal = {
  __typename: 'BusinessOrderPriceTotal'
  amount: Money
  items: Scalars['Long']['output']
  shipping: Money
}

export type BusinessOrderRecipient = {
  __typename: 'BusinessOrderRecipient'
  address: BusinessOrderAddress
  delivery?: Maybe<BusinessOrderDelivery>
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
}

export type BusinessOrderAddress = {
  __typename: 'BusinessOrderAddress'
  addressLine1: Scalars['String']['output']
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  postcode: Scalars['String']['output']
}

export type BusinessOrderDelivery = {
  __typename: 'BusinessOrderDelivery'
  deliveryMethod: BusinessOrderDeliveryMethod
  deliveryOption: BusinessOrderDeliveryOption
  id: Scalars['String']['output']
}

export type BusinessOrderDeliveryMethod = {
  __typename: 'BusinessOrderDeliveryMethod'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type BusinessOrderDeliveryOption = {
  __typename: 'BusinessOrderDeliveryOption'
  deliveryDate: Scalars['Date']['output']
  despatchDate: Scalars['Date']['output']
}

export type BusinessSubstitutions = {
  __typename: 'BusinessSubstitutions'
  available: Array<BusinessAvailableSubstitution>
  preview: Array<BusinessPreviewSubstitution>
}

export type BusinessPreviewSubstitution = {
  __typename: 'BusinessPreviewSubstitution'
  placeholder: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type BusinessOrderNotFoundError = Error & {
  __typename: 'BusinessOrderNotFoundError'
  message: Scalars['String']['output']
}

export type BusinessProjectResponse = BusinessProject | InputValidationError

export type BusinessProject = {
  __typename: 'BusinessProject'
  cardCustomisation?: Maybe<BusinessCardCustomisation>
  cardDesign?: Maybe<BusinessCardDesign>
  id: Scalars['ID']['output']
  price?: Maybe<BusinessPrice>
  recipients: Array<BusinessRecipient>
  substitutions?: Maybe<BusinessSubstitutions>
}

export type BusinessCardCustomisation = {
  __typename: 'BusinessCardCustomisation'
  message?: Maybe<Scalars['String']['output']>
  to: BusinessCardTo
}

export type BusinessCardTo = {
  __typename: 'BusinessCardTo'
  greeting: Scalars['String']['output']
  nameFormat: BusinessNameFormat
}

export enum BusinessNameFormat {
  FIRST = 'FIRST',
  FULL = 'FULL',
  NONE = 'NONE',
}

export type BusinessCardDesign = {
  __typename: 'BusinessCardDesign'
  designId?: Maybe<Scalars['String']['output']>
  productKey: Scalars['String']['output']
  sku: Scalars['String']['output']
}

export type BusinessPrice = {
  __typename: 'BusinessPrice'
  card?: Maybe<Money>
}

export type BusinessRecipient = {
  __typename: 'BusinessRecipient'
  address: BusinessRecipientAddress
  deliveryDate?: Maybe<Scalars['Date']['output']>
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
}

export type BusinessRecipientAddress = {
  __typename: 'BusinessRecipientAddress'
  addressLine1: Scalars['String']['output']
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  postcode: Scalars['String']['output']
}

export type InputValidationError = Error & {
  __typename: 'InputValidationError'
  message: Scalars['String']['output']
}

export type BusinessProjectRecipientPreSignedUrlResult = {
  __typename: 'BusinessProjectRecipientPreSignedUrlResult'
  url: Scalars['String']['output']
}

export type ContactPreferences = Node & {
  __typename: 'ContactPreferences'
  id: Scalars['ID']['output']
  marketingReviewEligible: Scalars['Boolean']['output']
  preferences: Array<ContactPreference>
  source?: Maybe<ContactSource>
}

export type Node = {
  id: Scalars['ID']['output']
}

export type ContactPreference = {
  __typename: 'ContactPreference'
  status: ContactStatus
  type: ContactPreferenceType
}

export enum ContactStatus {
  NOT_ASKED = 'NOT_ASKED',
  OPTED_IN = 'OPTED_IN',
  OPTED_OUT = 'OPTED_OUT',
}

export enum ContactPreferenceType {
  FATHERS_DAY = 'FATHERS_DAY',
  GENERAL_MARKETING = 'GENERAL_MARKETING',
  GROUP_CARDS = 'GROUP_CARDS',
  MEMBERSHIPS = 'MEMBERSHIPS',
  /** @deprecated Use MEMBERSHIPS */
  MOONPIG_PLUS = 'MOONPIG_PLUS',
  MOTHERS_DAY = 'MOTHERS_DAY',
}

export enum ContactSource {
  App = 'App',
  AppCheckout = 'AppCheckout',
  CustomerServices = 'CustomerServices',
  EmailUnsubscribe = 'EmailUnsubscribe',
  GroupCardsSignUp = 'GroupCardsSignUp',
  LandingPage = 'LandingPage',
  MembershipStarted = 'MembershipStarted',
  Migration = 'Migration',
  MyAccountPage = 'MyAccountPage',
  ProspectEmailRegister = 'ProspectEmailRegister',
  RemindersInflowPage = 'RemindersInflowPage',
  WebCheckout = 'WebCheckout',
  WebRegister = 'WebRegister',
}

export type CustomerCredits = {
  __typename: 'CustomerCredits'
  accounts?: Maybe<Array<CreditAccount>>
  activity?: Maybe<Array<CreditActivity>>
  availableBalance?: Maybe<Money>
  id: Scalars['ID']['output']
}

export type CreditAccount = {
  __typename: 'CreditAccount'
  id: Scalars['ID']['output']
  records?: Maybe<Array<CreditRecord>>
  remainingBalance?: Maybe<Money>
  store: Scalars['String']['output']
}

export type CreditRecord = {
  __typename: 'CreditRecord'
  authCode?: Maybe<Scalars['String']['output']>
  createdDate?: Maybe<Scalars['String']['output']>
  expiredBy?: Maybe<Scalars['String']['output']>
  expiryDate?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  initialBalance: Money
  isExpired?: Maybe<Scalars['Boolean']['output']>
  reason?: Maybe<Scalars['String']['output']>
  remainingBalance: Money
}

export type CreditActivity = {
  __typename: 'CreditActivity'
  amount: Money
  authorizer?: Maybe<Scalars['String']['output']>
  creditId: Scalars['String']['output']
  date?: Maybe<Scalars['String']['output']>
  newBalance: Money
  orderId?: Maybe<Scalars['String']['output']>
  reason?: Maybe<Scalars['String']['output']>
  store?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
}

export type CrmDetails = {
  __typename: 'CrmDetails'
  cookie?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
}

export type Favourites = {
  __typename: 'Favourites'
  list: FavouritesList
}

export type FavouriteslistArgs = {
  listId?: Scalars['ID']['input']
}

export type FavouritesList = {
  __typename: 'FavouritesList'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  products: Array<Maybe<Product>>
}

export type Product = {
  __typename: 'Product'
  category: ProductCategory
  clickRankDocumentCount: Scalars['Int']['output']
  customisable: Scalars['Boolean']['output']
  dependencies: Array<Maybe<Dependencies>>
  description: Scalars['String']['output']
  editableTextFieldCount: Scalars['Int']['output']
  facets: Array<ProductFacet>
  hasAugmentedReality: Scalars['Boolean']['output']
  hasCollageMaker: Scalars['Boolean']['output']
  id: Scalars['String']['output']
  isFavourited?: Maybe<Scalars['Boolean']['output']>
  isLandscape: Scalars['Boolean']['output']
  isSponsored: Scalars['Boolean']['output']
  masterVariant: ProductVariant
  personalisation: ProductPersonalisationResult
  photoUploadCount: Scalars['Int']['output']
  primaryProductPill?: Maybe<ProductPill>
  productPills: Array<ProductPill>
  publishDate?: Maybe<Scalars['DateTime']['output']>
  rating?: Maybe<ProductRating>
  recommendations: Recommendations
  relatedTerms?: Maybe<RelatedTerms>
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  variants: Array<ProductVariant>
  voucherOptions?: Maybe<VoucherOptions>
}

export type ProductrecommendationsArgs = {
  algorithmId?: InputMaybe<Scalars['String']['input']>
  facets?: InputMaybe<RecommendationFacets>
}

export type ProductCategory = {
  __typename: 'ProductCategory'
  department: DepartmentsEnum
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  parentDepartment: DepartmentsEnum
  slug: Scalars['String']['output']
}

export enum DepartmentsEnum {
  ACRYLICS = 'ACRYLICS',
  ALCOHOL_GIFTS = 'ALCOHOL_GIFTS',
  ALL_CARDS = 'ALL_CARDS',
  ALL_FLOWERS_AND_PLANTS = 'ALL_FLOWERS_AND_PLANTS',
  ALL_GIFTS = 'ALL_GIFTS',
  ARENA_POSTAL_FLOWERS = 'ARENA_POSTAL_FLOWERS',
  BALLOONS = 'BALLOONS',
  BEAUTY = 'BEAUTY',
  CALENDAR_PHOTOBOX = 'CALENDAR_PHOTOBOX',
  CANVASES = 'CANVASES',
  CASES = 'CASES',
  DIGITAL_GIFT_CARDS = 'DIGITAL_GIFT_CARDS',
  DIGITAL_GIFTS = 'DIGITAL_GIFTS',
  FLOWERS = 'FLOWERS',
  FOOD_GIFTS = 'FOOD_GIFTS',
  FRAMES = 'FRAMES',
  GADGETS_AND_NOVELTIES = 'GADGETS_AND_NOVELTIES',
  GIFT_BOXES = 'GIFT_BOXES',
  GIFT_EXPERIENCES = 'GIFT_EXPERIENCES',
  GIFT_VOUCHERS = 'GIFT_VOUCHERS',
  GIFTS_FOR_HOME = 'GIFTS_FOR_HOME',
  GREETING_CARDS = 'GREETING_CARDS',
  GUERNSEY_POSTAL_FLOWERS = 'GUERNSEY_POSTAL_FLOWERS',
  INVITATIONS_AND_ANNOUNCEMENTS = 'INVITATIONS_AND_ANNOUNCEMENTS',
  JEWELLERY_AND_ACCESSORIES = 'JEWELLERY_AND_ACCESSORIES',
  LETTERBOX_GIFTS = 'LETTERBOX_GIFTS',
  MINI_CARDS = 'MINI_CARDS',
  MUGS = 'MUGS',
  NON_CUSTOMISABLE_GIFTS = 'NON_CUSTOMISABLE_GIFTS',
  PARTY = 'PARTY',
  PLANTS = 'PLANTS',
  SOFT_TOYS = 'SOFT_TOYS',
  STATIONERY_AND_CRAFT = 'STATIONERY_AND_CRAFT',
  TOYS_AND_GAMES = 'TOYS_AND_GAMES',
  TSHIRTS = 'TSHIRTS',
  WALL_ART = 'WALL_ART',
  WEDDING_STATIONERY = 'WEDDING_STATIONERY',
}

export enum Dependencies {
  DIRECT_SMILE = 'DIRECT_SMILE',
}

export type ProductFacet = {
  __typename: 'ProductFacet'
  facetKey?: Maybe<Scalars['String']['output']>
  label?: Maybe<Scalars['String']['output']>
  /** @deprecated Use 'group' instead. */
  name: Scalars['String']['output']
  /** @deprecated No longer supported */
  path?: Maybe<Scalars['String']['output']>
  /** @deprecated Use 'facetKey' instead as it is language agnostic */
  value: Scalars['String']['output']
}

export type ProductVariant = {
  __typename: 'ProductVariant'
  addons?: Maybe<Array<Addon>>
  availablePackaging?: Maybe<Packaging>
  bundles: Array<ProductVariantBundle>
  capabilities?: Maybe<Capabilities>
  content?: Maybe<ProductVariantContentResult>
  dimensions?: Maybe<ProductVariantDimensions>
  discountedPercentage?: Maybe<Scalars['Float']['output']>
  flows: ProductVariantFlow
  fullPrice: Money
  /** @deprecated Use new productImages fields */
  images: Array<Image>
  inStock: Scalars['Boolean']['output']
  key: Scalars['String']['output']
  locations?: Maybe<Array<Location>>
  /** @deprecated Use new productImages fields */
  masterImage: Image
  /** @deprecated Use 'quantity' instead. */
  minimumQuantity: Scalars['Int']['output']
  price: Money
  productImages: ProductImages
  quantityOption?: Maybe<QuantityOption>
  sizeId: Scalars['Int']['output']
  sku?: Maybe<Scalars['String']['output']>
  subtitle?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type ProductVariantaddonsArgs = {
  clientUsesDescription?: InputMaybe<Scalars['Boolean']['input']>
}

export type Addon = {
  __typename: 'Addon'
  description: Scalars['String']['output']
  image: Image
  inStock: Scalars['Boolean']['output']
  price: Money
  sku: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type Packaging = {
  __typename: 'Packaging'
  defaultSku: Scalars['String']['output']
  title: Scalars['String']['output']
  variants: Array<VariantPackaging>
}

export type ProductVariantBundle = {
  __typename: 'ProductVariantBundle'
  description: Scalars['String']['output']
  discountPercentage: Scalars['Float']['output']
  fullPrice: Money
  price: Money
  size: Scalars['Int']['output']
}

export type Capabilities = {
  __typename: 'Capabilities'
  /** @deprecated Use DesignScene.availableCustomisations instead */
  video: Scalars['Boolean']['output']
}

export type ProductVariantContentResult =
  | ProductVariantContent
  | ProductVariantContentNotFoundError

export type ProductVariantContent = {
  __typename: 'ProductVariantContent'
  modules: Array<ProductVariantContentModule>
}

export type ProductVariantContentModule = ProductVariantContentModuleDetails

export type ProductVariantContentModuleDetails = {
  __typename: 'ProductVariantContentModuleDetails'
  items: Array<ProductVariantContentModuleDetailsItem>
}

export type ProductVariantContentModuleDetailsItem = {
  __typename: 'ProductVariantContentModuleDetailsItem'
  key: Scalars['String']['output']
  title: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type ProductVariantContentNotFoundError = Error & {
  __typename: 'ProductVariantContentNotFoundError'
  message: Scalars['String']['output']
}

export type ProductVariantDimensions = {
  __typename: 'ProductVariantDimensions'
  description: Scalars['String']['output']
  height: Scalars['Int']['output']
  width: Scalars['Int']['output']
}

export type ProductVariantFlow = {
  __typename: 'ProductVariantFlow'
  addToBasket: AddToBasketFlow
}

export enum AddToBasketFlow {
  DIGITAL = 'DIGITAL',
  STANDARD = 'STANDARD',
}

export type Location = {
  __typename: 'Location'
  city: Scalars['String']['output']
  county: Scalars['String']['output']
  postcode: Scalars['String']['output']
}

export type ProductImages = {
  __typename: 'ProductImages'
  images: Array<ProductImage>
  mainImage: ProductImage
}

export type ProductImage = {
  __typename: 'ProductImage'
  extraLarge: SizedImage
  large: SizedImage
  medium: SizedImage
  original: SizedImage
  small: SizedImage
  thumb: SizedImage
}

export type SizedImage = {
  __typename: 'SizedImage'
  jpgUrl: Scalars['String']['output']
}

export type QuantityOption = FixedQuantity | RangeQuantity

export type FixedQuantity = {
  __typename: 'FixedQuantity'
  required: Scalars['Int']['output']
}

export type RangeQuantity = {
  __typename: 'RangeQuantity'
  maximum: Scalars['Int']['output']
  minimum: Scalars['Int']['output']
}

export type ProductPersonalisationResult =
  | DesignServiceError
  | ProductNotPersonalisableError
  | ProductPersonalisation

export type ProductNotPersonalisableError = Error & {
  __typename: 'ProductNotPersonalisableError'
  message: Scalars['String']['output']
}

export type ProductPersonalisation = {
  __typename: 'ProductPersonalisation'
  requiresEditing: Scalars['Boolean']['output']
}

export type ProductPill = {
  __typename: 'ProductPill'
  displayLabel: Scalars['String']['output']
  displayVariant: ProductPillType
}

export enum ProductPillType {
  ECO = 'ECO',
  MARKETING = 'MARKETING',
  PLUS = 'PLUS',
  PRICE = 'PRICE',
  UNIQUE_SELLING_POINT = 'UNIQUE_SELLING_POINT',
}

export type ProductRating = {
  __typename: 'ProductRating'
  count: Scalars['Int']['output']
  score: Scalars['Float']['output']
}

export type RecommendationFacets = {
  age?: InputMaybe<Scalars['String']['input']>
  occasion?: InputMaybe<Scalars['String']['input']>
  recipient?: InputMaybe<Scalars['String']['input']>
}

export type Recommendations = {
  __typename: 'Recommendations'
  categories: Array<RecommendationProductCategories>
  products: Array<Maybe<Product>>
  promotion?: Maybe<RecommendedPromotion>
  referenceLineItemId?: Maybe<Scalars['ID']['output']>
}

export type RecommendationscategoriesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type RecommendationProductCategories = {
  __typename: 'RecommendationProductCategories'
  description?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  products: Array<Maybe<Product>>
}

export type RecommendedPromotion = {
  __typename: 'RecommendedPromotion'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type RelatedTerms = {
  __typename: 'RelatedTerms'
  terms: Array<Scalars['String']['output']>
}

export type VoucherOptions = {
  __typename: 'VoucherOptions'
  defaultCard: Product
}

export type GroupCardProjectResult =
  | GroupCardNotFoundError
  | GroupCardProject
  | GroupCardServiceError

export type GroupCardNotFoundError = GroupCardError & {
  __typename: 'GroupCardNotFoundError'
  message: Scalars['String']['output']
}

export type GroupCardError = {
  message: Scalars['String']['output']
}

export type GroupCardProject = {
  __typename: 'GroupCardProject'
  addMessageToken: Scalars['String']['output']
  addMessageUrl: Scalars['String']['output']
  createdTime: Scalars['String']['output']
  deadlineTime?: Maybe<Scalars['String']['output']>
  imageUrl: Scalars['String']['output']
  messages: Array<GroupCardMessage>
  occasion?: Maybe<Occasion>
  productId?: Maybe<Scalars['String']['output']>
  productVariantSku?: Maybe<Scalars['String']['output']>
  projectId: Scalars['ID']['output']
  projectName: Scalars['String']['output']
  recipient?: Maybe<Scalars['String']['output']>
}

export type GroupCardProjectimageUrlArgs = {
  heightPx: Scalars['Int']['input']
  textColor?: InputMaybe<Scalars['String']['input']>
  widthPx: Scalars['Int']['input']
}

export type GroupCardMessage = {
  __typename: 'GroupCardMessage'
  authorName: Scalars['String']['output']
  message: Scalars['String']['output']
  messageId: Scalars['ID']['output']
}

export enum Occasion {
  ANNIVERSARY = 'ANNIVERSARY',
  BIRTHDAY = 'BIRTHDAY',
  BON_VOYAGE = 'BON_VOYAGE',
  CHINESE_NEW_YEAR = 'CHINESE_NEW_YEAR',
  CHRISTENINGS = 'CHRISTENINGS',
  CHRISTMAS = 'CHRISTMAS',
  CONFIRMATION = 'CONFIRMATION',
  CONGRATULATIONS = 'CONGRATULATIONS',
  DIWALI = 'DIWALI',
  DRIVING_TEST = 'DRIVING_TEST',
  EASTER = 'EASTER',
  EID = 'EID',
  ENGAGEMENT = 'ENGAGEMENT',
  EXAMS = 'EXAMS',
  FATHERS_DAY = 'FATHERS_DAY',
  GET_WELL = 'GET_WELL',
  GOOD_LUCK = 'GOOD_LUCK',
  GRADUATION = 'GRADUATION',
  HALLOWEEN = 'HALLOWEEN',
  HOLY_COMMUNION = 'HOLY_COMMUNION',
  IN_REMEMBRANCE = 'IN_REMEMBRANCE',
  JEWISH_CELEBRATIONS = 'JEWISH_CELEBRATIONS',
  JUST_A_NOTE = 'JUST_A_NOTE',
  LEAVING = 'LEAVING',
  LOVE = 'LOVE',
  MOTHERS_DAY = 'MOTHERS_DAY',
  NEW_BABY = 'NEW_BABY',
  NEW_HOME = 'NEW_HOME',
  NEW_JOB = 'NEW_JOB',
  NEW_SCHOOL = 'NEW_SCHOOL',
  NEW_YEAR = 'NEW_YEAR',
  PREGNANCY = 'PREGNANCY',
  RETIREMENT = 'RETIREMENT',
  SAINT_NICHOLAS = 'SAINT_NICHOLAS',
  SAINTS_DAYS = 'SAINTS_DAYS',
  SORRY = 'SORRY',
  SPECIAL_OCCASION = 'SPECIAL_OCCASION',
  SYMPATHY = 'SYMPATHY',
  THANK_YOU = 'THANK_YOU',
  THANKSGIVING = 'THANKSGIVING',
  THINKING_OF_YOU = 'THINKING_OF_YOU',
  VALENTINES_DAY = 'VALENTINES_DAY',
  WEDDING = 'WEDDING',
}

export type GroupCardServiceError = GroupCardError & {
  __typename: 'GroupCardServiceError'
  message: Scalars['String']['output']
}

export type GroupCardProjectsResult = GroupCardProjects | GroupCardServiceError

export type GroupCardProjects = {
  __typename: 'GroupCardProjects'
  projects: Array<GroupCardProject>
}

export type GroupCardProjectsResultV2 =
  | GroupCardProjectsV2
  | GroupCardServiceError

export type GroupCardProjectsV2 = {
  __typename: 'GroupCardProjectsV2'
  projects: Array<GroupCardProjectV2>
}

export type GroupCardProjectV2 = {
  __typename: 'GroupCardProjectV2'
  addMessageToken: Scalars['String']['output']
  addMessageUrl: Scalars['String']['output']
  createdTime: Scalars['String']['output']
  deadlineTime?: Maybe<Scalars['String']['output']>
  designId?: Maybe<Scalars['String']['output']>
  messageCount: Scalars['Int']['output']
  messages: Array<GroupCardMessageV2>
  occasion?: Maybe<Occasion>
  productId: Scalars['String']['output']
  productVariantSku: Scalars['String']['output']
  projectId: Scalars['ID']['output']
  projectName: Scalars['String']['output']
  recipient: Scalars['String']['output']
  scenes: Array<GroupCardsScene>
}

export type GroupCardProjectV2scenesArgs = {
  pages: Scalars['Int']['input']
}

export type GroupCardMessageV2 = {
  __typename: 'GroupCardMessageV2'
  authorName: Scalars['String']['output']
  colorId: Scalars['Int']['output']
  font: GroupCardFont
  fontFaceId: Scalars['Int']['output']
  isOwner: Scalars['Boolean']['output']
  message: Scalars['String']['output']
  messageId: Scalars['ID']['output']
}

export type GroupCardFont = {
  __typename: 'GroupCardFont'
  colorId: Scalars['String']['output']
  fontFaceId: Scalars['String']['output']
}

export type GroupCardsScene = {
  __typename: 'GroupCardsScene'
  height: Scalars['Float']['output']
  page: Scalars['Int']['output']
  sceneObjects: Array<GroupCardsSceneObject>
  width: Scalars['Float']['output']
}

export type GroupCardsSceneObject = GroupCardsSceneText

export type GroupCardsSceneText = {
  __typename: 'GroupCardsSceneText'
  colorId: Scalars['Int']['output']
  fixedFontSize: Scalars['Float']['output']
  font: GroupCardFont
  fontFaceId: Scalars['Int']['output']
  height: Scalars['Float']['output']
  isOwner: Scalars['Boolean']['output']
  lineHeight: Scalars['Float']['output']
  size: Scalars['Float']['output']
  text: Scalars['String']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type GroupCardProjectResultV2 =
  | GroupCardNotFoundError
  | GroupCardProjectV2
  | GroupCardServiceError

export type MarketingConsentPosition = {
  __typename: 'MarketingConsentPosition'
  position?: Maybe<ConsentPosition>
}

export enum ConsentPosition {
  OPTED_IN = 'OPTED_IN',
  OPTED_OUT = 'OPTED_OUT',
}

export type Membership = {
  __typename: 'Membership'
  amountPaid?: Maybe<Money>
  customerId: Scalars['ID']['output']
  expiryDate: Scalars['String']['output']
  gracePeriod: Scalars['Boolean']['output']
  label: Scalars['String']['output']
  membershipType: MembershipType
  paymentMethodId?: Maybe<Scalars['String']['output']>
  payments?: Maybe<Array<Maybe<MembershipPayment>>>
  refund: MembershipRefund
  renewalFrequency: RenewalFrequency
  savings?: Maybe<MembershipSavings>
  startDate: Scalars['String']['output']
  status: MembershipStatus
}

export type MembershipPayment = {
  __typename: 'MembershipPayment'
  date: Scalars['String']['output']
  interactionId?: Maybe<Scalars['String']['output']>
  money: Money
}

export type MembershipRefund = {
  __typename: 'MembershipRefund'
  eligible: Scalars['Boolean']['output']
  interactionId?: Maybe<Scalars['String']['output']>
  money?: Maybe<Money>
}

export type RenewalFrequency = {
  __typename: 'RenewalFrequency'
  intervalCount: Scalars['Int']['output']
  intervalUnit: RenewalFrequencyIntervalUnit
}

export enum RenewalFrequencyIntervalUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export type MembershipSavings = {
  __typename: 'MembershipSavings'
  total: Money
}

export enum MembershipStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  EXPERIMENT = 'EXPERIMENT',
  GRACE_PERIOD = 'GRACE_PERIOD',
  NON_RECURRING = 'NON_RECURRING',
}

export type MultiSendTrackBasketInput = {
  basketId: Scalars['ID']['input']
  basketItemsQuantity: Scalars['Int']['input']
}

export type MultiSendTrackBasketResult =
  | MultiSendServiceError
  | MultiSendTrackBasket

export type MultiSendServiceError = {
  __typename: 'MultiSendServiceError'
  message?: Maybe<Scalars['String']['output']>
}

export type MultiSendTrackBasket = {
  __typename: 'MultiSendTrackBasket'
  message?: Maybe<Scalars['String']['output']>
}

export type ShippingAddress = {
  __typename: 'ShippingAddress'
  addressFirstLine: Scalars['String']['output']
  city?: Maybe<Scalars['String']['output']>
  country: Scalars['String']['output']
  deliveryPointSuffix?: Maybe<Scalars['String']['output']>
  extraAddressLine?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  houseNumber?: Maybe<Scalars['String']['output']>
  houseNumberExtension?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  isMyAddress: Scalars['Boolean']['output']
  isTypeVerified?: Maybe<Scalars['Boolean']['output']>
  isVerified?: Maybe<Scalars['Boolean']['output']>
  lastName: Scalars['String']['output']
  postcode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  streetName?: Maybe<Scalars['String']['output']>
  /** @deprecated No longer in use */
  title?: Maybe<Scalars['String']['output']>
}

export type Order = Node &
  Versioned & {
    __typename: 'Order'
    appliedDiscount?: Maybe<AppliedDiscount>
    client?: Maybe<Scalars['String']['output']>
    /** Payments: Total of all customerCredit payments on the order */
    creditsUsed: Money
    customerEmail?: Maybe<Scalars['String']['output']>
    customerId: Scalars['ID']['output']
    id: Scalars['ID']['output']
    membershipOrderItem?: Maybe<MembershipOrderItem>
    orderDate: Scalars['Date']['output']
    orderDeliveries: Array<OrderDelivery>
    /** @deprecated Will be removed in upcoming release, use orderItems on orderDeliveries */
    orderItems: Array<OrderItem>
    orderNumber?: Maybe<Scalars['String']['output']>
    orderRegion: Scalars['String']['output']
    payments: Array<Maybe<Payment>>
    /** Total: Shipping price for the order */
    postagePrice: Money
    selfServe: SelfServe
    /** @deprecated Will be removed in upcoming release, use shippingAddress on orderDeliveries */
    shippingAddress: ShippingAddress
    showSupportButton: Scalars['Boolean']['output']
    /** @deprecated Use orderRegion instead */
    store: Store
    /** Total: total of order (itemprice + shipping + tax) w/o (discount) */
    subTotalIncTax: Money
    /** Total: total of order (itemprice + tax + discount) w/o (shipping) */
    subTotalPrice: Money
    /** Total: discount applied to the order */
    totalDiscount?: Maybe<Money>
    /** Total: price of all items in the order */
    totalItemPrice: Money
    totalItems: Scalars['Int']['output']
    /** Payments: Total of all non-customerCredit payments on the order */
    totalPaid: Money
    /** Total: total of order (itemprice + shipping + tax) */
    totalPrice: Money
    /** Total: total of order (itemprice + shipping) w/o (tax) */
    totalPriceGross: Money
    /** Total: Shipping price for the order */
    totalShippingPrice: Money
    /** Total: total of order w/o tax */
    totalTaxExclusive?: Maybe<Money>
    version: Scalars['Long']['output']
  }

export type AppliedDiscount = {
  __typename: 'AppliedDiscount'
  code: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type MembershipOrderItem = {
  __typename: 'MembershipOrderItem'
  membershipType: MembershipType
  price: Money
}

export type OrderDelivery = {
  __typename: 'OrderDelivery'
  actualDispatchDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated Use recipientAddress */
  address: ShippingAddress
  deliveryDate: Scalars['Date']['output']
  deliveryInformation?: Maybe<OrderDeliveryInformation>
  deliveryStatus: DeliveryStatus
  estimatedDispatchDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  instructions?: Maybe<Scalars['String']['output']>
  isShareable: Scalars['Boolean']['output']
  mobile?: Maybe<Scalars['String']['output']>
  orderItems: Array<OrderItem>
  recipientAddress: RecipientAddress
  shippingPrice: Money
  /** @deprecated Use deliveryStatus */
  status: OrderStatus
}

export type OrderDeliveryInformation = {
  __typename: 'OrderDeliveryInformation'
  courierDetails: CourierDetails
  deliveryMethodId?: Maybe<Scalars['String']['output']>
  deliveryMethodName?: Maybe<Scalars['String']['output']>
  fulfilmentCentre?: Maybe<FulfilmentCentre>
  fullTrackingUrl?: Maybe<Scalars['String']['output']>
  isTracked: Scalars['Boolean']['output']
  /** @deprecated Will be removed in upcoming release, for Id use deliveryMethodId, for human readable version use deliveryMethodName */
  postageType?: Maybe<PostageType>
  trackingNumber?: Maybe<Scalars['String']['output']>
  /** @deprecated Use `fullTrackingUrl`. */
  trackingUrl?: Maybe<Scalars['String']['output']>
}

export type OrderDeliveryInformationcourierDetailsArgs = {
  excludeFromDisplay?: InputMaybe<Array<Courier>>
}

export enum Courier {
  AN_POST = 'AN_POST',
  AUS_POST = 'AUS_POST',
  DPD = 'DPD',
  PACKS = 'PACKS',
  POST_NL = 'POST_NL',
  ROYAL_MAIL = 'ROYAL_MAIL',
  UNKNOWN = 'UNKNOWN',
  USPS = 'USPS',
}

export type CourierDetails = {
  __typename: 'CourierDetails'
  courier: Courier
  displayName: Scalars['String']['output']
  shortCode?: Maybe<Scalars['String']['output']>
}

export type FulfilmentCentre = {
  __typename: 'FulfilmentCentre'
  countryCode?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
}

export enum PostageType {
  AIR_MAIL_AUSTRAILIA_TO_NEW_ZEALAND = 'AIR_MAIL_AUSTRAILIA_TO_NEW_ZEALAND',
  AIR_MAIL_EUROPE = 'AIR_MAIL_EUROPE',
  AIR_MAIL_OUTSIDE_EUROPE = 'AIR_MAIL_OUTSIDE_EUROPE',
  AUSTRALIAN_EXPRESS_POST_NEXT_DAY_DELIVERY = 'AUSTRALIAN_EXPRESS_POST_NEXT_DAY_DELIVERY',
  AUSTRALIAN_POST = 'AUSTRALIAN_POST',
  DPD_COURIER = 'DPD_COURIER',
  DX_COURIER = 'DX_COURIER',
  EMAIL = 'EMAIL',
  EXPRESS_2ND_DAY = 'EXPRESS_2ND_DAY',
  EXPRESS_OVERNIGHT = 'EXPRESS_OVERNIGHT',
  EXPRESS_POST = 'EXPRESS_POST',
  FEDEX_SATURDAY = 'FEDEX_SATURDAY',
  FEDEX_STANDARD = 'FEDEX_STANDARD',
  FIRST_CLASS = 'FIRST_CLASS',
  FIRST_CLASS__ORDER_BY_7PM = 'FIRST_CLASS__ORDER_BY_7PM',
  FIRST_CLASS_VIA_USPS = 'FIRST_CLASS_VIA_USPS',
  FREE_DELIVERY = 'FREE_DELIVERY',
  INTERLINK = 'INTERLINK',
  MOTHERS_DAY_DELIVERY = 'MOTHERS_DAY_DELIVERY',
  NONE = 'NONE',
  PRINT_SITE_DELIVERY = 'PRINT_SITE_DELIVERY',
  PRINT_SITE_SATURDAY_DELIVERY = 'PRINT_SITE_SATURDAY_DELIVERY',
  RM_TRACKED = 'RM_TRACKED',
  SATURDAY_COURIER = 'SATURDAY_COURIER',
  SECOND_CLASS = 'SECOND_CLASS',
  SPECIAL_DELIVERY = 'SPECIAL_DELIVERY',
  SPECIAL_DELIVERY_SATURDAY = 'SPECIAL_DELIVERY_SATURDAY',
  STANDARD = 'STANDARD',
  STANDARD_COURIER = 'STANDARD_COURIER',
  STANDARD_COURIER_NEXT_BUSINESS_DAY = 'STANDARD_COURIER_NEXT_BUSINESS_DAY',
  STANDARD_PRIORITY = 'STANDARD_PRIORITY',
  TELEGRAM = 'TELEGRAM',
  UK_MAIL = 'UK_MAIL',
  US_POSTAL_SERVICE_STANDARD_DELIVERY = 'US_POSTAL_SERVICE_STANDARD_DELIVERY',
  VALENTINES_DAY_DELIVERY = 'VALENTINES_DAY_DELIVERY',
  YODEL_COURIER = 'YODEL_COURIER',
  YODEL_STANDARD_DELIVERY = 'YODEL_STANDARD_DELIVERY',
}

export type DeliveryStatus = {
  pipelineStatus: DeliveryPipelineStatus
  /** Delivery State: direct consumption is not recommended - Use pipelineStatus instead. */
  state: DeliveryState
}

export type DeliveryPipelineStatus = {
  __typename: 'DeliveryPipelineStatus'
  progress: DeliveryPipelineProgressState
  state: DeliveryPipelineState
}

/** Used to help determine how far a delivery is along a specific pipeline state */
export enum DeliveryPipelineProgressState {
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
}

/** Used to help determine how a specific delivery state aligns itself in its overall process */
export enum DeliveryPipelineState {
  /** CANCELLED - The Delivery has been successfully cancelled. */
  CANCELLED = 'CANCELLED',
  /** COMPLETE - The delivery has left the Fulfilment Centre and is being handled by the courier */
  COMPLETE = 'COMPLETE',
  /** FAILED - We currently cannot, or will not be processing the delivery. */
  FAILED = 'FAILED',
  /** IN_PROGRESS - A delivery is being processed in one of the fulfilment centres */
  IN_PROGRESS = 'IN_PROGRESS',
  /** ON_HOLD - The progress of the delivery is paused until further confirmation has been received. */
  ON_HOLD = 'ON_HOLD',
  /** PENDING - We've created the delivery but we are awaiting confirmation. */
  PENDING = 'PENDING',
}

export enum DeliveryState {
  CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  DISPATCHED = 'DISPATCHED',
  ON_HOLD = 'ON_HOLD',
  PACKED = 'PACKED',
  PROCESSING = 'PROCESSING',
  RECEIVED = 'RECEIVED',
}

export type OrderItem = Node & {
  __typename: 'OrderItem'
  addons: Array<Maybe<OrderAddon>>
  /** @deprecated Use 'previewImages' instead. */
  coverImage?: Maybe<Image>
  design?: Maybe<Design>
  digitalGift?: Maybe<OrderDigitalGift>
  id: Scalars['ID']['output']
  packaging?: Maybe<OrderPackaging>
  previewImages: Array<Maybe<Image>>
  productKey?: Maybe<Scalars['String']['output']>
  productSlug?: Maybe<Scalars['String']['output']>
  productType?: Maybe<Scalars['String']['output']>
  quantity: Scalars['Int']['output']
  size?: Maybe<Scalars['String']['output']>
  sku?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  totalDiscount?: Maybe<Money>
  totalPrice: Money
  unitPrice: Money
}

export type OrderAddon = {
  __typename: 'OrderAddon'
  coverImage?: Maybe<Image>
  id: Scalars['ID']['output']
  sku: Scalars['String']['output']
  title: Scalars['String']['output']
  totalPrice: Money
  unitPrice: Money
}

export type OrderDigitalGift = {
  __typename: 'OrderDigitalGift'
  coverImage?: Maybe<Image>
  id: Scalars['ID']['output']
  sku: Scalars['String']['output']
  title: Scalars['String']['output']
  totalPrice: Money
  unitPrice: Money
}

export type OrderPackaging = {
  __typename: 'OrderPackaging'
  coverImage?: Maybe<Image>
  id: Scalars['ID']['output']
  sku: Scalars['String']['output']
  title: Scalars['String']['output']
  totalPrice: Money
  unitPrice: Money
}

export type RecipientAddress = EmailAddress | ShippingAddress

export type EmailAddress = {
  __typename: 'EmailAddress'
  email: Scalars['String']['output']
  name: Scalars['String']['output']
}

export enum OrderStatus {
  CANCELLED = 'CANCELLED',
  ON_HOLD = 'ON_HOLD',
  PRINTING = 'PRINTING',
  RECEIVED = 'RECEIVED',
  SENT = 'SENT',
}

export type Payment = {
  __typename: 'Payment'
  amountPlanned: Money
  createdAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  paymentMethodInfo?: Maybe<PaymentMethodInfo>
  transactions: Array<Maybe<Transaction>>
  version: Scalars['Int']['output']
}

export type PaymentMethodInfo = {
  __typename: 'PaymentMethodInfo'
  method?: Maybe<Scalars['String']['output']>
  paymentInterface: Scalars['String']['output']
}

export type Transaction = {
  __typename: 'Transaction'
  amount: Money
  id: Scalars['ID']['output']
  interactionId: Scalars['String']['output']
  state: TransactionState
  type: TransactionType
}

export enum TransactionState {
  Failure = 'Failure',
  Initial = 'Initial',
  Pending = 'Pending',
  Success = 'Success',
}

export enum TransactionType {
  Authorization = 'Authorization',
  CancelAuthorization = 'CancelAuthorization',
  Charge = 'Charge',
  Chargeback = 'Chargeback',
  Refund = 'Refund',
}

export type SelfServe = {
  canSelfServe: Scalars['Boolean']['output']
}

export enum Store {
  AU = 'AU',
  IE = 'IE',
  UK = 'UK',
  US = 'US',
}

export type Orders = {
  __typename: 'Orders'
  items: Array<Order>
  totalCount: Scalars['Long']['output']
}

export type BaseRecipient = {
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type CustomerRecommendationsRequest = {
  algorithm?: InputMaybe<Scalars['String']['input']>
  legacyId: Scalars['String']['input']
}

export type CustomerRecommendationsResponse = {
  __typename: 'CustomerRecommendationsResponse'
  occasion: Scalars['String']['output']
  products: Array<Maybe<Product>>
  source: CustomerRecommendationSource
  type: Scalars['String']['output']
}

export type CustomerRecommendationSource = {
  __typename: 'CustomerRecommendationSource'
  orderId: Scalars['String']['output']
  orderItemId: Scalars['String']['output']
  reminderId: Scalars['String']['output']
}

export type SavedCard = {
  __typename: 'SavedCard'
  cardType: Scalars['String']['output']
  /** @deprecated Use `savedCards` on `basket`. */
  cvcRequired: Scalars['Boolean']['output']
  dateAdded: Scalars['String']['output']
  expired: Scalars['Boolean']['output']
  expiry: Scalars['String']['output']
  hasActiveMembership: Scalars['Boolean']['output']
  isNonWallet: Scalars['Boolean']['output']
  lastFourDigits: Scalars['String']['output']
  nameOnCard: Scalars['String']['output']
  paymentMethodId: Scalars['String']['output']
}

export type DesignScene = {
  __typename: 'DesignScene'
  availableCustomisations: Array<DesignCustomisationsType>
  customisations: DesignCustomisations
  groupMessages?: Maybe<DesignSceneGroupMessages>
  handwriting?: Maybe<DesignSceneHandwriting>
  height: Scalars['Float']['output']
  id: Scalars['ID']['output']
  imageUpload?: Maybe<DesignSceneImageUpload>
  isCustomised: Scalars['Boolean']['output']
  layoutOriginal?: Maybe<DesignCustomisationsLayout>
  placements: DesignPlacements
  template: DesignTemplate
  title: Scalars['String']['output']
  usedFeatures: Array<EditorFeature>
  width: Scalars['Float']['output']
}

export enum DesignCustomisationsType {
  AUDIO = 'AUDIO',
  GROUP_MESSAGES = 'GROUP_MESSAGES',
  HANDWRITING = 'HANDWRITING',
  IMAGE_UPLOAD = 'IMAGE_UPLOAD',
  LAYOUTS = 'LAYOUTS',
  OVERLAY_HANDWRITING = 'OVERLAY_HANDWRITING',
  OVERLAY_IMAGE = 'OVERLAY_IMAGE',
  OVERLAY_TEXT = 'OVERLAY_TEXT',
  STICKERS = 'STICKERS',
  TEMPLATE = 'TEMPLATE',
  VIDEO = 'VIDEO',
}

export type DesignCustomisations = {
  __typename: 'DesignCustomisations'
  groupMessages?: Maybe<DesignCustomisationsGroupMessages>
  handwriting?: Maybe<DesignCustomisationsHandwriting>
  imageUpload?: Maybe<DesignCustomisationsImageUpload>
  layout?: Maybe<DesignCustomisationsLayout>
  overlay?: Maybe<DesignCustomisationsOverlay>
  template?: Maybe<DesignCustomisationsTemplate>
}

export type DesignCustomisationsGroupMessages = {
  __typename: 'DesignCustomisationsGroupMessages'
  image: DesignImageUploaded
  sourceImage?: Maybe<DesignImageSource>
}

export type DesignImageUploaded = {
  __typename: 'DesignImageUploaded'
  id: Scalars['ID']['output']
  url?: Maybe<Scalars['String']['output']>
}

export type DesignImageSource = {
  __typename: 'DesignImageSource'
  id: Scalars['ID']['output']
  transform: DesignTransform
}

export type DesignTransform = {
  __typename: 'DesignTransform'
  position: DesignTransformPosition
  rotation: Scalars['Float']['output']
  scale: Scalars['Float']['output']
}

export type DesignTransformPosition = {
  __typename: 'DesignTransformPosition'
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignCustomisationsHandwriting = {
  __typename: 'DesignCustomisationsHandwriting'
  image: DesignImageUploaded
  sourceImage?: Maybe<DesignImageSource>
}

export type DesignCustomisationsImageUpload = {
  __typename: 'DesignCustomisationsImageUpload'
  image: DesignImageUploaded
  sourceImage?: Maybe<DesignImageSource>
}

export type DesignCustomisationsLayout = {
  __typename: 'DesignCustomisationsLayout'
  grid: DesignCollageGrid
}

export type DesignCollageGrid = {
  __typename: 'DesignCollageGrid'
  background?: Maybe<DesignCollageGridBackground>
  border?: Maybe<DesignCollageGridBorder>
  cells: Array<DesignCollageGridCell>
  gutters: Array<DesignCollageGridGutter>
  id: Scalars['ID']['output']
}

export type DesignCollageGridBackground = {
  __typename: 'DesignCollageGridBackground'
  color: Scalars['String']['output']
}

export type DesignCollageGridBorder = {
  __typename: 'DesignCollageGridBorder'
  bottom: Scalars['Float']['output']
  inner: Scalars['Float']['output']
  left: Scalars['Float']['output']
  right: Scalars['Float']['output']
  top: Scalars['Float']['output']
}

export type DesignCollageGridCell = {
  __typename: 'DesignCollageGridCell'
  anchors: DesignCollageGridCellAnchors
  content: DesignCollageGridCellContent
  id: Scalars['ID']['output']
}

export type DesignCollageGridCellAnchors = {
  __typename: 'DesignCollageGridCellAnchors'
  bottom?: Maybe<Scalars['ID']['output']>
  left?: Maybe<Scalars['ID']['output']>
  right?: Maybe<Scalars['ID']['output']>
  top?: Maybe<Scalars['ID']['output']>
}

export type DesignCollageGridCellContent = {
  __typename: 'DesignCollageGridCellContent'
  image?: Maybe<DesignCollageGridCellContentImage>
  placeholder?: Maybe<DesignCollageGridCellContentPlaceholder>
}

export type DesignCollageGridCellContentImage = {
  __typename: 'DesignCollageGridCellContentImage'
  sourceImage: DesignCollageGridCellSourceImage
}

export type DesignCollageGridCellSourceImage = {
  __typename: 'DesignCollageGridCellSourceImage'
  id: Scalars['ID']['output']
  offsetX: Scalars['Float']['output']
  offsetY: Scalars['Float']['output']
  scale: Scalars['Float']['output']
}

export type DesignCollageGridCellContentPlaceholder = {
  __typename: 'DesignCollageGridCellContentPlaceholder'
  kind: DesignCollageGridCellContentPlaceholderKind
  placedId?: Maybe<Scalars['ID']['output']>
}

export enum DesignCollageGridCellContentPlaceholderKind {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

export type DesignCollageGridGutter = {
  __typename: 'DesignCollageGridGutter'
  anchors: DesignCollageGridGutterAnchors
  id: Scalars['ID']['output']
  offset: Scalars['Float']['output']
  orientation: DesignCollageGridGutterOrientation
}

export type DesignCollageGridGutterAnchors = {
  __typename: 'DesignCollageGridGutterAnchors'
  end?: Maybe<Scalars['ID']['output']>
  start?: Maybe<Scalars['ID']['output']>
}

export enum DesignCollageGridGutterOrientation {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export type DesignCustomisationsOverlay = {
  __typename: 'DesignCustomisationsOverlay'
  overlays: Array<DesignOverlay>
}

export type DesignOverlay =
  | DesignOverlayImage
  | DesignOverlayInlay
  | DesignOverlayMedia
  | DesignOverlaySticker
  | DesignOverlayText

export type DesignOverlayImage = DesignOverlayCommon & {
  __typename: 'DesignOverlayImage'
  content: DesignOverlayImageContent
  height: Scalars['Float']['output']
  index: Scalars['Int']['output']
  rotation: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignOverlayCommon = {
  height: Scalars['Float']['output']
  index: Scalars['Int']['output']
  rotation: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignOverlayImageContent = {
  __typename: 'DesignOverlayImageContent'
  editedImage: DesignImageUploaded
  fixed?: Maybe<Scalars['Boolean']['output']>
  imageKind?: Maybe<DesignOverlayImageKind>
  sourceImage?: Maybe<DesignImageSource>
}

export enum DesignOverlayImageKind {
  HANDWRITING = 'HANDWRITING',
  PHOTO = 'PHOTO',
}

export type DesignOverlayInlay = DesignOverlayCommon & {
  __typename: 'DesignOverlayInlay'
  content: DesignOverlayInlayContent
  height: Scalars['Float']['output']
  index: Scalars['Int']['output']
  rotation: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignOverlayInlayContent = {
  __typename: 'DesignOverlayInlayContent'
  height: Scalars['Float']['output']
  image: RendererInlayImageResult
  inlayId: Scalars['ID']['output']
  scene: InlayScene
  sceneObjects: Array<InlaySceneObject>
  width: Scalars['Float']['output']
}

export type RendererInlayImageResult = RendererInlayImage | RendererServiceError

export type RendererInlayImage = {
  __typename: 'RendererInlayImage'
  height: Scalars['Int']['output']
  url: Scalars['String']['output']
  width: Scalars['Int']['output']
}

export type InlayScene = {
  __typename: 'InlayScene'
  height: Scalars['Float']['output']
  id: Scalars['ID']['output']
  image: RendererImageResult
  sceneObjects: Array<RendererSceneObject>
  width: Scalars['Float']['output']
}

export type RendererImageResult = RendererImage | RendererServiceError

export type RendererImage = {
  __typename: 'RendererImage'
  height: Scalars['Int']['output']
  url: Scalars['String']['output']
  width: Scalars['Int']['output']
}

export type RendererSceneObject =
  | RendererSceneObjectImage
  | RendererSceneObjectLink
  | RendererSceneObjectQRCode
  | RendererSceneObjectText

export type RendererSceneObjectImage = RendererSceneObjectCommon & {
  __typename: 'RendererSceneObjectImage'
  height: Scalars['Float']['output']
  urls: RendererImageUrls
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type RendererSceneObjectCommon = {
  height: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type RendererImageUrls = {
  __typename: 'RendererImageUrls'
  editor: Scalars['String']['output']
  preview: Scalars['String']['output']
  print: Scalars['String']['output']
}

/**
 * Link scene object which will be rendered on eCards for recipients to click on.
 * Generally the position & size should cover the whole of the media element so recipients don't have to click in a specific place.
 */
export type RendererSceneObjectLink = RendererSceneObjectCommon & {
  __typename: 'RendererSceneObjectLink'
  height: Scalars['Float']['output']
  /** Title or accessible name of the link. */
  label: Scalars['String']['output']
  /** URL to send the recipient to when they click on the link. */
  url: Scalars['String']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type RendererSceneObjectQRCode = RendererSceneObjectCommon & {
  __typename: 'RendererSceneObjectQRCode'
  height: Scalars['Float']['output']
  value: Scalars['String']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type RendererSceneObjectText = RendererSceneObjectCommon & {
  __typename: 'RendererSceneObjectText'
  height: Scalars['Float']['output']
  text: Scalars['String']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type InlaySceneObject = InlaySceneObjectImage | InlaySceneObjectText

export type InlaySceneObjectImage = InlaySceneObjectCommon & {
  __typename: 'InlaySceneObjectImage'
  height: Scalars['Float']['output']
  rotation: Scalars['Float']['output']
  urls: InlayImageUrls
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type InlaySceneObjectCommon = {
  height: Scalars['Float']['output']
  rotation: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type InlayImageUrls = {
  __typename: 'InlayImageUrls'
  editor: Scalars['String']['output']
  preview: Scalars['String']['output']
  print: Scalars['String']['output']
}

export type InlaySceneObjectText = InlaySceneObjectCommon & {
  __typename: 'InlaySceneObjectText'
  fontSize: Scalars['Float']['output']
  height: Scalars['Float']['output']
  rotation: Scalars['Float']['output']
  text: Scalars['String']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignOverlayMedia = DesignOverlayCommon & {
  __typename: 'DesignOverlayMedia'
  content: DesignOverlayMediaContent
  height: Scalars['Float']['output']
  index: Scalars['Int']['output']
  rotation: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignOverlayMediaContent = {
  __typename: 'DesignOverlayMediaContent'
  designId: Scalars['ID']['output']
  height: Scalars['Float']['output']
  kind?: Maybe<DesignMediaKind>
  media: MediaResult
  mediaId: Scalars['ID']['output']
  scene: MediaScene
  width: Scalars['Float']['output']
}

export enum DesignMediaKind {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
}

export type MediaResult = Media | MediaNotFoundError | MediaServiceError

export type Media = {
  __typename: 'Media'
  activated: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  interactive: Scalars['Boolean']['output']
  kind: MediaKind
  scene: MediaScene
  token: Scalars['String']['output']
}

export type MediasceneArgs = {
  height: Scalars['Float']['input']
  width: Scalars['Float']['input']
}

export enum MediaKind {
  Audio = 'Audio',
  Video = 'Video',
}

export type MediaScene = {
  __typename: 'MediaScene'
  height: Scalars['Float']['output']
  id: Scalars['ID']['output']
  image: RendererImageResult
  sceneObjects: Array<RendererSceneObject>
  width: Scalars['Float']['output']
}

export type MediaNotFoundError = Error & {
  __typename: 'MediaNotFoundError'
  message: Scalars['String']['output']
}

export type MediaServiceError = Error & {
  __typename: 'MediaServiceError'
  message: Scalars['String']['output']
}

export type DesignOverlaySticker = DesignOverlayCommon & {
  __typename: 'DesignOverlaySticker'
  content: DesignOverlayStickerContent
  height: Scalars['Float']['output']
  index: Scalars['Int']['output']
  rotation: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignOverlayStickerContent = {
  __typename: 'DesignOverlayStickerContent'
  stickerId: Scalars['ID']['output']
  urls: StickerUrls
}

export type StickerUrls = {
  __typename: 'StickerUrls'
  editor: Scalars['String']['output']
  editorThumbnail: Scalars['String']['output']
  preview: Scalars['String']['output']
  print: Scalars['String']['output']
}

export type DesignOverlayText = DesignOverlayCommon & {
  __typename: 'DesignOverlayText'
  content: DesignOverlayTextContent
  height: Scalars['Float']['output']
  index: Scalars['Int']['output']
  rotation: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignOverlayTextContent = {
  __typename: 'DesignOverlayTextContent'
  /**
   * Author of the customisation as determined by the client.
   *
   * Currently optional until client consistency is reached.
   */
  author?: Maybe<DesignTextAuthor>
  colorId: Scalars['ID']['output']
  /** The text box is not intended to be moved, rotated, or resized by the user. */
  fixed?: Maybe<Scalars['Boolean']['output']>
  fontFaceId: Scalars['ID']['output']
  fontSize: Scalars['Float']['output']
  fragments?: Maybe<Array<DesignTextFragment>>
  horizontalAlignment: DesignHorizontalAlignment
  substitutions?: Maybe<Array<Scalars['String']['output']>>
  text: Scalars['String']['output']
  verticalAlignment: DesignVerticalAlignment
}

/**
 * The author of the overlay text customisation.
 *
 * Clients will determine this and supply to setCustomisations mutations inputs.
 */
export enum DesignTextAuthor {
  /** Customer generated text, no editor tooling used. */
  CUSTOMER = 'CUSTOMER',
  /** Message generated by our new AI message feature without any modification. */
  SMART_TEXT = 'SMART_TEXT',
  /** Message generated by our new AI message but then modified by the customer. */
  SMART_TEXT_MODIFIED = 'SMART_TEXT_MODIFIED',
  /** Starter message used without any modification. */
  STARTER = 'STARTER',
  /** Starter message used but then modified by the customer. */
  STARTER_MODIFIED = 'STARTER_MODIFIED',
}

export type DesignTextFragment = {
  __typename: 'DesignTextFragment'
  fontSize: Scalars['Float']['output']
  text: Scalars['String']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export enum DesignHorizontalAlignment {
  CENTER = 'CENTER',
  JUSTIFY = 'JUSTIFY',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum DesignVerticalAlignment {
  BOTTOM = 'BOTTOM',
  MIDDLE = 'MIDDLE',
  TOP = 'TOP',
}

export type DesignCustomisationsTemplate = {
  __typename: 'DesignCustomisationsTemplate'
  imageCollage: Array<DesignCustomisationElementImageCollage>
  imageDynamic: Array<DesignCustomisationElementImageDynamic>
  imageUpload: Array<DesignCustomisationElementImageUpload>
  textPlaceholder: Array<DesignCustomisationElementTextPlaceholder>
  textPlain: Array<DesignCustomisationElementTextPlain>
  textStyled: Array<DesignCustomisationElementTextStyled>
}

export type DesignCustomisationElementImageCollage = {
  __typename: 'DesignCustomisationElementImageCollage'
  editedImage: DesignImageUploaded
  elementId: Scalars['ID']['output']
  grid?: Maybe<DesignCollageGrid>
}

export type DesignCustomisationElementImageDynamic = {
  __typename: 'DesignCustomisationElementImageDynamic'
  elementId: Scalars['ID']['output']
  textParts: Array<Scalars['String']['output']>
}

export type DesignCustomisationElementImageUpload = {
  __typename: 'DesignCustomisationElementImageUpload'
  editedImage: DesignImageUploaded
  elementId: Scalars['ID']['output']
  sourceImage?: Maybe<DesignImageSource>
}

export type DesignCustomisationElementTextPlaceholder = {
  __typename: 'DesignCustomisationElementTextPlaceholder'
  elementId: Scalars['ID']['output']
  textParts: Array<Scalars['String']['output']>
}

export type DesignCustomisationElementTextPlain = {
  __typename: 'DesignCustomisationElementTextPlain'
  elementId: Scalars['ID']['output']
  text: Scalars['String']['output']
}

export type DesignCustomisationElementTextStyled = {
  __typename: 'DesignCustomisationElementTextStyled'
  colorId: Scalars['String']['output']
  elementId: Scalars['ID']['output']
  fontFaceId: Scalars['String']['output']
  fontSize: Scalars['Float']['output']
  horizontalAlignment: DesignHorizontalAlignment
  text: Scalars['String']['output']
  verticalAlignment: DesignVerticalAlignment
}

export type DesignSceneGroupMessages = {
  __typename: 'DesignSceneGroupMessages'
  height: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignSceneHandwriting = {
  __typename: 'DesignSceneHandwriting'
  height: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignSceneImageUpload = {
  __typename: 'DesignSceneImageUpload'
  height: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignPlacements = {
  __typename: 'DesignPlacements'
  handwriting: DesignPlacement
  media: DesignPlacement
}

export type DesignPlacement = {
  __typename: 'DesignPlacement'
  height: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignTemplate = {
  __typename: 'DesignTemplate'
  elements: Array<DesignElement>
  id: Scalars['ID']['output']
  orientation: DesignOrientation
  renderMethod?: Maybe<DesignRenderMethod>
}

export type DesignElement =
  | DesignElementImageCollage
  | DesignElementImageDynamic
  | DesignElementImageStatic
  | DesignElementImageUpload
  | DesignElementShapeRectangle
  | DesignElementTextPlaceholder
  | DesignElementTextPlain
  | DesignElementTextStatic
  | DesignElementTextStyled

export type DesignElementImageCollage = {
  __typename: 'DesignElementImageCollage'
  defaultPreviewImageUrlVariants: DesignImageUrlVariants
  height: Scalars['Float']['output']
  id: Scalars['ID']['output']
  /** @deprecated Required for legacy only */
  num?: Maybe<Scalars['Int']['output']>
  placeholderImageUrlVariants: DesignImageUrlVariants
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignImageUrlVariants = {
  __typename: 'DesignImageUrlVariants'
  editor: Scalars['String']['output']
  preview: Scalars['String']['output']
  preview2: Scalars['String']['output']
  print: Scalars['String']['output']
  print2: Scalars['String']['output']
}

export type DesignElementImageDynamic = {
  __typename: 'DesignElementImageDynamic'
  dynamic: DesignImageDynamic
  height: Scalars['Float']['output']
  id: Scalars['ID']['output']
  /** @deprecated Required for legacy only */
  num?: Maybe<Scalars['Int']['output']>
  /** @deprecated Not used */
  opacity: Scalars['Float']['output']
  rotation: Scalars['Float']['output']
  textParts: Array<DesignTextPartEditable>
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignImageDynamic = {
  __typename: 'DesignImageDynamic'
  format: DesignImageDynamicFormat
  id: Scalars['ID']['output']
}

export enum DesignImageDynamicFormat {
  JPEG = 'JPEG',
  PNG = 'PNG',
}

export type DesignTextPartEditable = {
  __typename: 'DesignTextPartEditable'
  allowBlankText: Scalars['Boolean']['output']
  allowDefaultText: Scalars['Boolean']['output']
  /** @deprecated Required for legacy only */
  customNum?: Maybe<Scalars['Int']['output']>
  defaultText: Scalars['String']['output']
  /** @deprecated Required for legacy only */
  editableNum?: Maybe<Scalars['Int']['output']>
  maxCharacters?: Maybe<Scalars['Int']['output']>
  textTransform?: Maybe<DesignTextTransform>
}

export enum DesignTextTransform {
  UPPERCASE = 'UPPERCASE',
}

export type DesignElementImageStatic = DesignElementImageCommon & {
  __typename: 'DesignElementImageStatic'
  height: Scalars['Float']['output']
  id: Scalars['ID']['output']
  /** Returns same as maskUrlVariants.editor */
  maskUrl?: Maybe<Scalars['String']['output']>
  maskUrlVariants?: Maybe<DesignImageUrlVariants>
  /** @deprecated Required for legacy only */
  num?: Maybe<Scalars['Int']['output']>
  /** @deprecated Not used */
  opacity: Scalars['Float']['output']
  rotation: Scalars['Float']['output']
  /** Returns same as urlVariants.editor */
  url: Scalars['String']['output']
  urlVariants?: Maybe<DesignImageUrlVariants>
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignElementImageCommon = {
  height: Scalars['Float']['output']
  id: Scalars['ID']['output']
  /** Returns same as maskUrlVariants.editor */
  maskUrl?: Maybe<Scalars['String']['output']>
  maskUrlVariants?: Maybe<DesignImageUrlVariants>
  /** @deprecated Required for legacy only */
  num?: Maybe<Scalars['Int']['output']>
  /** @deprecated Not used */
  opacity: Scalars['Float']['output']
  rotation: Scalars['Float']['output']
  /** Returns same as urlVariants.editor */
  url: Scalars['String']['output']
  urlVariants?: Maybe<DesignImageUrlVariants>
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignElementImageUpload = DesignElementImageCommon & {
  __typename: 'DesignElementImageUpload'
  height: Scalars['Float']['output']
  id: Scalars['ID']['output']
  /** Returns same as maskUrlVariants.editor */
  maskUrl?: Maybe<Scalars['String']['output']>
  maskUrlVariants?: Maybe<DesignImageUrlVariants>
  /** @deprecated Required for legacy only */
  num?: Maybe<Scalars['Int']['output']>
  /** @deprecated Not used */
  opacity: Scalars['Float']['output']
  required: Scalars['Boolean']['output']
  rotation: Scalars['Float']['output']
  /** Returns same as urlVariants.editor */
  url: Scalars['String']['output']
  urlVariants?: Maybe<DesignImageUrlVariants>
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignElementShapeRectangle = {
  __typename: 'DesignElementShapeRectangle'
  fillColor: Scalars['String']['output']
  height: Scalars['Float']['output']
  id: Scalars['ID']['output']
  /** @deprecated Required for legacy only */
  num?: Maybe<Scalars['Int']['output']>
  /** @deprecated Not used */
  opacity: Scalars['Float']['output']
  outline?: Maybe<DesignOutline>
  rotation: Scalars['Float']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignOutline = {
  __typename: 'DesignOutline'
  color: Scalars['String']['output']
  width: Scalars['Float']['output']
}

export type DesignElementTextPlaceholder = DesignElementTextCommon & {
  __typename: 'DesignElementTextPlaceholder'
  color: DesignColor
  fontFace: DesignFontFace
  fontSize: Scalars['Float']['output']
  height: Scalars['Float']['output']
  horizontalAlignment: DesignHorizontalAlignment
  id: Scalars['ID']['output']
  /** @deprecated Required for legacy only */
  legacyLineSpacing?: Maybe<Scalars['Float']['output']>
  lineSpacing: Scalars['Float']['output']
  /** @deprecated Required for legacy only */
  num?: Maybe<Scalars['Int']['output']>
  /** @deprecated Not used */
  opacity: Scalars['Float']['output']
  rotation: Scalars['Float']['output']
  shadow?: Maybe<DesignTextShadow>
  textParts: Array<DesignTextPart>
  verticalAlignment: DesignVerticalAlignment
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignElementTextCommon = {
  color: DesignColor
  fontFace: DesignFontFace
  fontSize: Scalars['Float']['output']
  height: Scalars['Float']['output']
  horizontalAlignment: DesignHorizontalAlignment
  id: Scalars['ID']['output']
  /** @deprecated Required for legacy only */
  legacyLineSpacing?: Maybe<Scalars['Float']['output']>
  lineSpacing: Scalars['Float']['output']
  /** @deprecated Required for legacy only */
  num?: Maybe<Scalars['Int']['output']>
  /** @deprecated Not used */
  opacity: Scalars['Float']['output']
  rotation: Scalars['Float']['output']
  shadow?: Maybe<DesignTextShadow>
  verticalAlignment: DesignVerticalAlignment
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignTextShadow = {
  __typename: 'DesignTextShadow'
  color: Scalars['String']['output']
  offsetX: Scalars['Float']['output']
  offsetY: Scalars['Float']['output']
}

export type DesignTextPart = DesignTextPartEditable | DesignTextPartStatic

export type DesignTextPartStatic = {
  __typename: 'DesignTextPartStatic'
  text: Scalars['String']['output']
}

export type DesignElementTextPlain = DesignElementTextCommon & {
  __typename: 'DesignElementTextPlain'
  color: DesignColor
  editableText: DesignTextEditable
  fontFace: DesignFontFace
  fontSize: Scalars['Float']['output']
  height: Scalars['Float']['output']
  horizontalAlignment: DesignHorizontalAlignment
  id: Scalars['ID']['output']
  /** @deprecated Required for legacy only */
  legacyLineSpacing?: Maybe<Scalars['Float']['output']>
  lineSpacing: Scalars['Float']['output']
  /** @deprecated Required for legacy only */
  num?: Maybe<Scalars['Int']['output']>
  /** @deprecated Not used */
  opacity: Scalars['Float']['output']
  rotation: Scalars['Float']['output']
  shadow?: Maybe<DesignTextShadow>
  verticalAlignment: DesignVerticalAlignment
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignTextEditable = {
  __typename: 'DesignTextEditable'
  allowBlankText: Scalars['Boolean']['output']
  allowDefaultText: Scalars['Boolean']['output']
  /** @deprecated Required for legacy only */
  customNum?: Maybe<Scalars['Int']['output']>
  defaultPreviewText?: Maybe<Scalars['String']['output']>
  defaultText: Scalars['String']['output']
  /** @deprecated Required for legacy only */
  editableNum?: Maybe<Scalars['Int']['output']>
  maxCharacters?: Maybe<Scalars['Int']['output']>
  textTransform?: Maybe<DesignTextTransform>
}

export type DesignElementTextStatic = DesignElementTextCommon & {
  __typename: 'DesignElementTextStatic'
  color: DesignColor
  fontFace: DesignFontFace
  fontSize: Scalars['Float']['output']
  height: Scalars['Float']['output']
  horizontalAlignment: DesignHorizontalAlignment
  id: Scalars['ID']['output']
  /** @deprecated Required for legacy only */
  legacyLineSpacing?: Maybe<Scalars['Float']['output']>
  lineSpacing: Scalars['Float']['output']
  /** @deprecated Required for legacy only */
  num?: Maybe<Scalars['Int']['output']>
  /** @deprecated Not used */
  opacity: Scalars['Float']['output']
  rotation: Scalars['Float']['output']
  shadow?: Maybe<DesignTextShadow>
  text: Scalars['String']['output']
  verticalAlignment: DesignVerticalAlignment
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type DesignElementTextStyled = DesignElementTextCommon & {
  __typename: 'DesignElementTextStyled'
  availableColors: Array<DesignColor>
  availableFontFaces: Array<DesignFontFace>
  availableFontSizes: Array<Scalars['Float']['output']>
  color: DesignColor
  editableText: DesignTextEditable
  fontFace: DesignFontFace
  fontSize: Scalars['Float']['output']
  height: Scalars['Float']['output']
  horizontalAlignment: DesignHorizontalAlignment
  id: Scalars['ID']['output']
  /** @deprecated Required for legacy only */
  legacyLineSpacing?: Maybe<Scalars['Float']['output']>
  lineSpacing: Scalars['Float']['output']
  /** @deprecated Required for legacy only */
  num?: Maybe<Scalars['Int']['output']>
  /** @deprecated Not used */
  opacity: Scalars['Float']['output']
  rotation: Scalars['Float']['output']
  shadow?: Maybe<DesignTextShadow>
  verticalAlignment: DesignVerticalAlignment
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export enum DesignOrientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export enum DesignRenderMethod {
  LEGACY_1 = 'LEGACY_1',
  LEGACY_2 = 'LEGACY_2',
}

export type EditorFeature =
  | EditorFeatureAllCharacters
  | EditorFeatureAudio
  | EditorFeatureLayout
  | EditorFeatureOverlayImage
  | EditorFeatureOverlayInlay
  | EditorFeatureOverlaySticker
  | EditorFeatureOverlayText
  | EditorFeatureSceneGroupMessages
  | EditorFeatureSceneHandwriting
  | EditorFeatureSceneImageUpload
  | EditorFeatureTemplateBaseline
  | EditorFeatureTemplateImageCollage
  | EditorFeatureTemplateImageDynamic
  | EditorFeatureVideo

export type EditorFeatureAllCharacters = EditorFeatureCommon & {
  __typename: 'EditorFeatureAllCharacters'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureCommon = {
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureAudio = EditorFeatureCommon & {
  __typename: 'EditorFeatureAudio'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureLayout = EditorFeatureCommon & {
  __typename: 'EditorFeatureLayout'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureOverlayImage = EditorFeatureCommon & {
  __typename: 'EditorFeatureOverlayImage'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureOverlayInlay = EditorFeatureCommon & {
  __typename: 'EditorFeatureOverlayInlay'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureOverlaySticker = EditorFeatureCommon & {
  __typename: 'EditorFeatureOverlaySticker'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureOverlayText = EditorFeatureCommon & {
  __typename: 'EditorFeatureOverlayText'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureSceneGroupMessages = EditorFeatureCommon & {
  __typename: 'EditorFeatureSceneGroupMessages'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureSceneHandwriting = EditorFeatureCommon & {
  __typename: 'EditorFeatureSceneHandwriting'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureSceneImageUpload = EditorFeatureCommon & {
  __typename: 'EditorFeatureSceneImageUpload'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureTemplateBaseline = EditorFeatureCommon & {
  __typename: 'EditorFeatureTemplateBaseline'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureTemplateImageCollage = EditorFeatureCommon & {
  __typename: 'EditorFeatureTemplateImageCollage'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureTemplateImageDynamic = EditorFeatureCommon & {
  __typename: 'EditorFeatureTemplateImageDynamic'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export type EditorFeatureVideo = EditorFeatureCommon & {
  __typename: 'EditorFeatureVideo'
  required: Scalars['Boolean']['output']
  /** @deprecated No longer used */
  title: Scalars['String']['output']
}

export enum DesignStampTheme {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export enum DesignState {
  DRAFT = 'DRAFT',
  IN_BASKET = 'IN_BASKET',
  IN_PROJECT = 'IN_PROJECT',
  ORDERED = 'ORDERED',
}

export enum DesignStore {
  AU = 'AU',
  IE = 'IE',
  NL = 'NL',
  UK = 'UK',
  US = 'US',
}

export type DesignAuthorizationError = Error & {
  __typename: 'DesignAuthorizationError'
  message: Scalars['String']['output']
  requiresLogin: Scalars['Boolean']['output']
}

export type DesignNotFoundError = Error & {
  __typename: 'DesignNotFoundError'
  message: Scalars['String']['output']
}

export type DesignTemplatesServiceError = Error & {
  __typename: 'DesignTemplatesServiceError'
  message: Scalars['String']['output']
}

export type DesignCollageGridsResult = DesignCollageGrids | DesignServiceError

export type DesignCollageGrids = {
  __typename: 'DesignCollageGrids'
  grids: Array<DesignCollageGrid>
}

export type DesignImageConfig = {
  __typename: 'DesignImageConfig'
  maxSizeBytes: Scalars['Int']['output']
  minSizeBytes: Scalars['Int']['output']
}

export type DesignsFilterInput = {
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type DesignsResult =
  | Designs
  | DesignServiceError
  | DesignTemplatesServiceError

export type Designs = {
  __typename: 'Designs'
  items: Array<Design>
}

export type DesignSceneLayoutsResult = DesignSceneLayouts | DesignServiceError

export type DesignSceneLayouts = {
  __typename: 'DesignSceneLayouts'
  grids: Array<DesignCollageGrid>
}

export type DesignSmartTextInput = {
  /** Customer provided prompt for the AI engine to use. */
  prompt: Scalars['String']['input']
}

/** SmartText response. */
export type DesignSmartTextResult =
  | DesignServiceError
  | DesignSmartText
  | DesignSmartTextInputError
  | DesignSmartTextLimitReachedError
  | DesignSmartTextModerationError
  | DesignSmartTextUnavailableError

/** Successful SmartText response. */
export type DesignSmartText = {
  __typename: 'DesignSmartText'
  /** Message generated by SmartText engine. */
  text: Scalars['String']['output']
}

/**
 * Error response returned when the prompt given exceeds the maximum length allowed.
 *
 * Maximum prompt length is defined in DesignSmartTextConfig.maxPromptLength.
 */
export type DesignSmartTextInputError = Error & {
  __typename: 'DesignSmartTextInputError'
  message: Scalars['String']['output']
}

/**
 * Error response returned when client makes too many requests. Requests are limited per user (auth token).
 *
 * Retries may be allowed but are likely to return the same error.
 *
 * Users are restricted to 25 requests in the space of 24 hours as a rolling period.
 */
export type DesignSmartTextLimitReachedError = Error & {
  __typename: 'DesignSmartTextLimitReachedError'
  message: Scalars['String']['output']
}

/**
 * Error response returned when the prompt given for the query fails moderation checking.
 *
 * Subsequent requests with the same prompt should not be made again.
 */
export type DesignSmartTextModerationError = Error & {
  __typename: 'DesignSmartTextModerationError'
  message: Scalars['String']['output']
}

/** This error is returned when the feature has been turned off through terraform variables. */
export type DesignSmartTextUnavailableError = Error & {
  __typename: 'DesignSmartTextUnavailableError'
  message: Scalars['String']['output']
}

export type DesignSmartTextConfigResult =
  | DesignServiceError
  | DesignSmartTextConfig

/**
 * Smart text config includes information about whether or not the feature is enabled and
 * how many more requests the user can make for the day.
 */
export type DesignSmartTextConfig = {
  __typename: 'DesignSmartTextConfig'
  enabled: Scalars['Boolean']['output']
  /** Maximum length of the user prompt. */
  maxPromptLength: Scalars['Int']['output']
  /** Number of requests remaining at this time. */
  remainingRequests: Scalars['Int']['output']
}

export type CriteriaInput = {
  excludeDirectSmile?: InputMaybe<Scalars['Boolean']['input']>
  excludeRude?: InputMaybe<Scalars['Boolean']['input']>
  facets?: InputMaybe<Array<FacetInput>>
  groupedFacets?: InputMaybe<Array<GroupedFacetInput>>
  hasAugmentedReality?: InputMaybe<Scalars['Boolean']['input']>
  hasCollageMaker?: InputMaybe<Scalars['Boolean']['input']>
  occasion?: InputMaybe<Scalars['String']['input']>
  onlyPPCResults?: InputMaybe<Scalars['Boolean']['input']>
  promotionId?: InputMaybe<Scalars['String']['input']>
  recipient?: InputMaybe<Scalars['String']['input']>
  variants?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type FacetInput = {
  facetKey?: InputMaybe<Scalars['String']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  path?: InputMaybe<Scalars['String']['input']>
  value: Scalars['String']['input']
}

export type GroupedFacetInput = {
  group: Scalars['String']['input']
  keys: Array<Scalars['String']['input']>
  userApplied?: InputMaybe<Scalars['Boolean']['input']>
}

export type FilterResults = {
  __typename: 'FilterResults'
  count: Scalars['Int']['output']
  facets?: Maybe<Array<Maybe<FilterFacet>>>
  facetsWithToggles?: Maybe<Array<Maybe<ApplicableFilter>>>
}

export type FilterFacet = {
  __typename: 'FilterFacet'
  all?: Maybe<Scalars['Boolean']['output']>
  children?: Maybe<Array<FilterItem>>
  count: Scalars['Int']['output']
  facetKey: Scalars['String']['output']
  group: Scalars['String']['output']
  id: Scalars['String']['output']
  isQuickFilter?: Maybe<Scalars['Boolean']['output']>
  isSelected: Scalars['Boolean']['output']
  label: Scalars['String']['output']
  /** @deprecated Unused field */
  layoutKey?: Maybe<Scalars['String']['output']>
  /** @deprecated Use label instead */
  name: Scalars['String']['output']
  nbaScore?: Maybe<Scalars['Float']['output']>
  /** @deprecated Use facetKey instead */
  path?: Maybe<Scalars['String']['output']>
  /** @deprecated Unused field */
  promoted?: Maybe<Scalars['Boolean']['output']>
  /** @deprecated Unused field */
  sorting?: Maybe<FilterSorting>
  /** @deprecated Use facetKey instead */
  value: Scalars['String']['output']
}

export type FilterItem =
  | FilterFacet
  | FilterHeader
  | InvertedToggleFilter
  | ToggleFilter

export type FilterHeader = {
  __typename: 'FilterHeader'
  name: Scalars['String']['output']
}

export type InvertedToggleFilter = {
  __typename: 'InvertedToggleFilter'
  facetKey: Scalars['String']['output']
  group: Scalars['String']['output']
  icon?: Maybe<Scalars['String']['output']>
  isSelected: Scalars['Boolean']['output']
  label: Scalars['String']['output']
}

export type ToggleFilter = {
  __typename: 'ToggleFilter'
  facetKey: Scalars['String']['output']
  group: Scalars['String']['output']
  icon?: Maybe<Scalars['String']['output']>
  isSelected: Scalars['Boolean']['output']
  label: Scalars['String']['output']
}

export enum FilterSorting {
  ALPHABETICAL = 'ALPHABETICAL',
  COUNT = 'COUNT',
  GROUP = 'GROUP',
  NONE = 'NONE',
}

export type ApplicableFilter = FilterFacet | InvertedToggleFilter | ToggleFilter

export type FontsFilterInput = {
  fontSetIds: Array<Scalars['ID']['input']>
}

export type FontsResult =
  | DesignInvalidCursorError
  | DesignServiceError
  | FontEdges

export type FontEdges = {
  __typename: 'FontEdges'
  edges: Array<FontEdge>
  pageInfo: DesignCustomisationPageInfo
  totalCount: Scalars['Int']['output']
}

export type FontEdge = {
  __typename: 'FontEdge'
  cursor: Scalars['ID']['output']
  node: Font
}

export type Font = {
  __typename: 'Font'
  id: Scalars['ID']['output']
  metrics: DesignFontFaceMetrics
  name: Scalars['String']['output']
  urls: FontUrls
}

export type FontUrls = {
  __typename: 'FontUrls'
  ttf: Scalars['String']['output']
  woff: Scalars['String']['output']
  woff2: Scalars['String']['output']
}

export type Basket = Node &
  Versioned & {
    __typename: 'Basket'
    addressActionType: AddressActionType
    autoPromos?: Maybe<Array<BasketAutoPromo>>
    basketSummary: Array<BasketSummaryItem>
    /** @deprecated Use addressActionType */
    canHaveMultipleAddresses: Scalars['Boolean']['output']
    credits: CreditsBreakdown
    /** @deprecated Use `credits{used}`. */
    creditsUsed: Money
    customerId?: Maybe<Scalars['String']['output']>
    deliveryOptions?: Maybe<DeliveryOptions>
    id: Scalars['ID']['output']
    /** @deprecated For internal use only */
    isReadyForCheckout: Scalars['Boolean']['output']
    lineItems: Array<LineItem>
    membership: BasketMembership
    /** @deprecated Use membership.selected field on basket */
    membershipLineItem?: Maybe<MembershipLineItem>
    paymentProviders: Array<PaymentProvider>
    /** @deprecated Field was extended and has union type, use personalisedPromotion */
    personalisedPromo?: Maybe<BasketPersonalisedPromo>
    personalisedPromotion?: Maybe<BasketPersonalisedPromotion>
    priceSummary: BasketPriceSummary
    recipientOptions?: Maybe<BasketRecipients>
    recommendations: Recommendations
    requiredAge?: Maybe<RequiredAge>
    savedCards: SavedCards
    /** @deprecated Use recipientAddress field on lineItem */
    shippingAddress?: Maybe<ShippingAddress>
    /** @deprecated For internal use only */
    state: BasketState
    /** @deprecated for internal use */
    store: Store
    subTotalIncTax: Money
    totalDiscount?: Maybe<Money>
    totalGrossTaxedPrice?: Maybe<Money>
    totalItemPrice: Money
    totalItems: Scalars['Int']['output']
    totalPrice: Money
    totalShippingPrice: Money
    totalTaxExclusive?: Maybe<Money>
    totalToPay: Money
    version: Scalars['Long']['output']
    voucherCode?: Maybe<VoucherCode>
  }

export type BasketpersonalisedPromoArgs = {
  audience?: InputMaybe<Audience>
}

export type BasketpersonalisedPromotionArgs = {
  audience?: InputMaybe<Audience>
}

export type BasketrecommendationsArgs = {
  algorithmId?: InputMaybe<Scalars['String']['input']>
  facets?: InputMaybe<RecommendationFacets>
}

export enum AddressActionType {
  CAN_HAVE_MULTIPLE = 'CAN_HAVE_MULTIPLE',
  COMPLETE = 'COMPLETE',
  REQUIRES_MULTIPLE = 'REQUIRES_MULTIPLE',
  REQUIRES_SINGLE = 'REQUIRES_SINGLE',
}

export type BasketAutoPromo = AutoPromo & {
  __typename: 'BasketAutoPromo'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  isApplied: Scalars['Boolean']['output']
  name: Scalars['String']['output']
}

export type AutoPromo = {
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type BasketSummaryItem = {
  __typename: 'BasketSummaryItem'
  title: Scalars['String']['output']
  total: Money
}

export type CreditsBreakdown = {
  __typename: 'CreditsBreakdown'
  available: Money
  remaining: Money
  used: Money
}

export type DeliveryOptions = {
  __typename: 'DeliveryOptions'
  /** @deprecated Beta feature. Do not use yet */
  availableDeliveryDates: Array<Scalars['Date']['output']>
  deliveries: Array<Delivery>
  deliveryInfoValid: Scalars['Boolean']['output']
}

export type DeliveryOptionsavailableDeliveryDatesArgs = {
  deliveryId: Scalars['ID']['input']
  from: Scalars['Date']['input']
}

export type Delivery = {
  __typename: 'Delivery'
  /** @deprecated Use recipientAddress */
  address: ShippingAddress
  availableDeliveryDates: Array<Scalars['Date']['output']>
  availableMethods: Array<DeliveryMethod>
  canHaveGiftNote: Scalars['Boolean']['output']
  deliveryDate: Scalars['Date']['output']
  dispatchDate: Scalars['Date']['output']
  /** @deprecated For internal use only */
  fulfilmentCentre?: Maybe<FulfilmentCentre>
  giftNote?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  instructions?: Maybe<Scalars['String']['output']>
  lineItems: Array<LineItem>
  method: DeliveryMethod
  mobile?: Maybe<Scalars['String']['output']>
  options?: Maybe<AvailableDeliveryOptions>
  recipientAddress: RecipientAddress
}

export type DeliveryavailableDeliveryDatesArgs = {
  from?: InputMaybe<Scalars['Date']['input']>
  to?: InputMaybe<Scalars['Date']['input']>
}

export type DeliveryMethod = {
  __typename: 'DeliveryMethod'
  acceptsDeliveryInstructions: Scalars['Boolean']['output']
  description: Scalars['String']['output']
  isGuaranteed: Scalars['Boolean']['output']
  isTracked: Scalars['Boolean']['output']
  methodId: Scalars['String']['output']
  name: Scalars['String']['output']
  peakService?: Maybe<PeakService>
  price: Money
}

export type PeakService = {
  __typename: 'PeakService'
  deliveryDate: Scalars['Date']['output']
}

export type LineItem = Node & {
  __typename: 'LineItem'
  addons: Array<LineItemAddon>
  availability: VariantAvailability
  availableVariants?: Maybe<Array<LineItemVariantOption>>
  canBeDuplicated: Scalars['Boolean']['output']
  /** @deprecated Use the more specific productCoverImage field */
  coverImage?: Maybe<Image>
  customisationId?: Maybe<Scalars['String']['output']>
  /** @deprecated Private field for child resolver */
  deliveryId?: Maybe<Scalars['String']['output']>
  /** @deprecated Private field for child resolver */
  deliveryMethodId?: Maybe<Scalars['String']['output']>
  deliveryType: DeliveryType
  description?: Maybe<Scalars['String']['output']>
  design?: Maybe<Design>
  /** @deprecated Beta feature. Do not use yet. A design is a combination of a template and it's customizations. A designId refers to one of these instances of a design. */
  designId?: Maybe<Scalars['ID']['output']>
  digitalGift?: Maybe<LineItemDigitalGift>
  displayName?: Maybe<Scalars['String']['output']>
  eCardSku?: Maybe<Scalars['String']['output']>
  /** @deprecated Please do not use this field. It should be constructed by clients */
  editUrl?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  packaging?: Maybe<LineItemPackaging>
  price: LineItemPrice
  productCoverImage?: Maybe<Image>
  productId: Scalars['ID']['output']
  productSlug?: Maybe<Scalars['String']['output']>
  productType?: Maybe<Scalars['String']['output']>
  productTypeName: Scalars['String']['output']
  quantity: Scalars['Int']['output']
  quantityOption?: Maybe<QuantityOption>
  recipientAddress?: Maybe<RecipientAddress>
  /** @deprecated Use recipientAddress */
  shippingAddress?: Maybe<ShippingAddress>
  size?: Maybe<Scalars['String']['output']>
  sku?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  totalGrossTaxedPrice?: Maybe<Money>
  totalPrice: Money
  unitPrice: Money
  /** @deprecated Will be removed in upcoming release, use sku instead */
  variantId: Scalars['Int']['output']
  /** @deprecated Use availableVariants */
  variants: Array<LineItemVariant>
}

export type LineItemAddon = {
  __typename: 'LineItemAddon'
  addon: Addon
  id: Scalars['ID']['output']
}

export type VariantAvailability = {
  __typename: 'VariantAvailability'
  isInStock: Scalars['Boolean']['output']
}

export type LineItemVariantOption = {
  __typename: 'LineItemVariantOption'
  isLandscape: Scalars['Boolean']['output']
  priceLabel: Scalars['String']['output']
  productVariant: ProductVariant
}

export enum DeliveryType {
  EMAIL = 'EMAIL',
  POSTAL = 'POSTAL',
}

export type LineItemDigitalGift = {
  __typename: 'LineItemDigitalGift'
  id: Scalars['ID']['output']
  image?: Maybe<Image>
  inStock: Scalars['Boolean']['output']
  price: Money
  productId: Scalars['String']['output']
  sku: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type LineItemPackaging = {
  __typename: 'LineItemPackaging'
  availablePackaging: Packaging
  id: Scalars['ID']['output']
  selectedPackaging: VariantPackaging
  sku: Scalars['String']['output']
}

export type LineItemPrice = DiscountedLineItemPrice | StandardLineItemPrice

export type DiscountedLineItemPrice = {
  __typename: 'DiscountedLineItemPrice'
  discounted: Money
  discountType?: Maybe<LineItemDiscountType>
  original: Money
}

export enum LineItemDiscountType {
  PLUS = 'PLUS',
}

export type StandardLineItemPrice = {
  __typename: 'StandardLineItemPrice'
  value: Money
}

export type LineItemVariant = {
  __typename: 'LineItemVariant'
  additionalInfo?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  price: Money
  priceLabel: Scalars['String']['output']
  sku: Scalars['String']['output']
  /** @deprecated Will be removed in upcoming release, use sku instead */
  variantId: Scalars['Int']['output']
}

export type AvailableDeliveryOptions = {
  __typename: 'AvailableDeliveryOptions'
  availableOptions?: Maybe<Array<DeliveryOption>>
  hasEarlierDates: Scalars['Boolean']['output']
}

export type DeliveryOption = {
  __typename: 'DeliveryOption'
  availableMethods: Array<DeliveryMethod>
  deliveryDate: Scalars['Date']['output']
  fromPrice: Money
}

export type BasketMembership = {
  __typename: 'BasketMembership'
  recommended?: Maybe<MembershipRecommendation>
  selected?: Maybe<MembershipLineItem>
  totalDiscount?: Maybe<Money>
}

export type MembershipRecommendation = {
  __typename: 'MembershipRecommendation'
  price: Money
  title: Scalars['String']['output']
  type: MembershipType
}

export type MembershipLineItem = {
  __typename: 'MembershipLineItem'
  membershipType: MembershipType
  price: Money
  renewalFrequency?: Maybe<RenewalFrequency>
  slug: Scalars['String']['output']
  subtext: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type PaymentProvider = {
  __typename: 'PaymentProvider'
  name: Scalars['String']['output']
}

export enum Audience {
  MOBILE_WEB = 'MOBILE_WEB',
  WEB = 'WEB',
}

export type BasketPersonalisedPromo = {
  __typename: 'BasketPersonalisedPromo'
  description: Scalars['String']['output']
  exclusionsMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isApplied: Scalars['Boolean']['output']
  message?: Maybe<Scalars['String']['output']>
  metadata?: Maybe<BasketPersonalisedPromoMetadata>
  name: Scalars['String']['output']
  stateErrorMessage?: Maybe<Scalars['String']['output']>
}

export type BasketPersonalisedPromoMetadata = {
  __typename: 'BasketPersonalisedPromoMetadata'
  seeding?: Maybe<Scalars['Boolean']['output']>
}

export type BasketPersonalisedPromotion =
  | BasketPersonalisedPromo
  | BasketPersonalisedPromoNoOffer

export type BasketPersonalisedPromoNoOffer = {
  __typename: 'BasketPersonalisedPromoNoOffer'
  metadata?: Maybe<BasketPersonalisedPromoMetadata>
  reason?: Maybe<Scalars['String']['output']>
}

export type BasketPriceSummary = {
  __typename: 'BasketPriceSummary'
  all: Array<BasketSummaryPrices>
  membership?: Maybe<Money>
  postage?: Maybe<Money>
  salesTax?: Maybe<Money>
  totalDiscount?: Maybe<Money>
  totalItemsCost?: Maybe<Money>
}

export type BasketSummaryPrices =
  | BasketSummaryBenefitItem
  | BasketSummaryCostItem

export type BasketSummaryBenefitItem = BasketSummaryPriceItem & {
  __typename: 'BasketSummaryBenefitItem'
  priceDisplay?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type BasketSummaryPriceItem = {
  priceDisplay?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type BasketSummaryCostItem = BasketSummaryPriceItem & {
  __typename: 'BasketSummaryCostItem'
  priceDisplay?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type BasketRecipients = {
  __typename: 'BasketRecipients'
  recipients: Array<BasketRecipient>
}

export type BasketRecipient = {
  __typename: 'BasketRecipient'
  isMe?: Maybe<Scalars['Boolean']['output']>
  lineItems: Array<LineItem>
  name?: Maybe<Scalars['String']['output']>
}

export type RequiredAge = {
  __typename: 'RequiredAge'
  description: Scalars['String']['output']
  minAge: Scalars['Int']['output']
  title: Scalars['String']['output']
}

export type SavedCards = {
  __typename: 'SavedCards'
  cvcRequired: Scalars['Boolean']['output']
}

export enum BasketState {
  ACTIVE = 'ACTIVE',
  ORDERED = 'ORDERED',
}

export type VoucherCode = {
  __typename: 'VoucherCode'
  code: Scalars['String']['output']
  isActive: Scalars['Boolean']['output']
  name?: Maybe<Scalars['String']['output']>
  stateErrorMessage?: Maybe<Scalars['String']['output']>
}

export type BusinessDeliveryOptions = {
  __typename: 'BusinessDeliveryOptions'
  nextAvailableBusinessDate: Scalars['Date']['output']
  unavailableBusinessDates: Array<Scalars['Date']['output']>
}

export type AddMessageStateResult =
  | GroupCardProjectClosedState
  | GroupCardProjectFullState
  | GroupCardProjectOpenAuthenticatedState
  | GroupCardProjectOpenState
  | GroupCardServiceError

export type GroupCardProjectClosedState = {
  __typename: 'GroupCardProjectClosedState'
  message: Scalars['String']['output']
}

export type GroupCardProjectFullState = {
  __typename: 'GroupCardProjectFullState'
  projectName: Scalars['String']['output']
}

export type GroupCardProjectOpenAuthenticatedState = {
  __typename: 'GroupCardProjectOpenAuthenticatedState'
  imageUrl: Scalars['String']['output']
  productId?: Maybe<Scalars['String']['output']>
  productVariantSku?: Maybe<Scalars['String']['output']>
  projectId: Scalars['ID']['output']
  projectName: Scalars['String']['output']
}

export type GroupCardProjectOpenAuthenticatedStateimageUrlArgs = {
  heightPx: Scalars['Int']['input']
  textColor?: InputMaybe<Scalars['String']['input']>
  widthPx: Scalars['Int']['input']
}

export type GroupCardProjectOpenState = {
  __typename: 'GroupCardProjectOpenState'
  productId?: Maybe<Scalars['String']['output']>
  productVariantSku?: Maybe<Scalars['String']['output']>
  projectName: Scalars['String']['output']
}

export type AddMessageStateResultV2 =
  | GroupCardProjectArchivedState
  | GroupCardProjectClosedStateV2
  | GroupCardProjectFullStateV2
  | GroupCardProjectOpenAuthenticatedStateV2
  | GroupCardProjectOpenStateV2
  | GroupCardServiceError

export type GroupCardProjectArchivedState = {
  __typename: 'GroupCardProjectArchivedState'
  projectId: Scalars['ID']['output']
}

export type GroupCardProjectClosedStateV2 = {
  __typename: 'GroupCardProjectClosedStateV2'
  message: Scalars['String']['output']
}

export type GroupCardProjectFullStateV2 = {
  __typename: 'GroupCardProjectFullStateV2'
  projectName: Scalars['String']['output']
}

export type GroupCardProjectOpenAuthenticatedStateV2 = {
  __typename: 'GroupCardProjectOpenAuthenticatedStateV2'
  deadlineTime?: Maybe<Scalars['String']['output']>
  isOwner: Scalars['Boolean']['output']
  myMessage?: Maybe<GroupCardMessageV2>
  occasion?: Maybe<Occasion>
  productId: Scalars['String']['output']
  productVariantSku: Scalars['String']['output']
  projectId: Scalars['ID']['output']
  projectName: Scalars['String']['output']
  recipient: Scalars['String']['output']
  scenes: Array<GroupCardsScene>
}

export type GroupCardProjectOpenAuthenticatedStateV2scenesArgs = {
  pages: Scalars['Int']['input']
}

export type GroupCardProjectOpenStateV2 = {
  __typename: 'GroupCardProjectOpenStateV2'
  deadlineTime?: Maybe<Scalars['String']['output']>
  myMessage?: Maybe<GroupCardMessageV2>
  occasion?: Maybe<Occasion>
  productId: Scalars['String']['output']
  productVariantSku: Scalars['String']['output']
  projectId: Scalars['ID']['output']
  projectName: Scalars['String']['output']
  recipient: Scalars['String']['output']
}

export type GroupCardConfig = {
  __typename: 'GroupCardConfig'
  authorNameCharacterLimit: Scalars['Int']['output']
  messageCharacterLimit: Scalars['Int']['output']
  projectMessageLimit: Scalars['Int']['output']
  projectNameCharacterLimit: Scalars['Int']['output']
  projectsLimit: Scalars['Int']['output']
}

export type GroupCardConfigV2 = {
  __typename: 'GroupCardConfigV2'
  authorNameCharacterLimit: Scalars['Int']['output']
  messageCharacterLimit: Scalars['Int']['output']
  projectMessageLimit: Scalars['Int']['output']
}

export type GroupCardOccasionsResult =
  | GroupCardOccasions
  | GroupCardServiceError

export type GroupCardOccasions = {
  __typename: 'GroupCardOccasions'
  occasions: Array<GroupCardOccasion>
}

export type GroupCardOccasion = {
  __typename: 'GroupCardOccasion'
  occasion: Occasion
}

export type InfoBanner = {
  __typename: 'InfoBanner'
  text: Scalars['String']['output']
}

export type InlayResult =
  | DemoInlay
  | InlayNotFoundError
  | InlayServiceError
  | VoucherInlay

export type DemoInlay = Inlay & {
  __typename: 'DemoInlay'
  id: Scalars['ID']['output']
  scene: InlayScene
  sceneObjects: Array<InlaySceneObject>
}

export type Inlay = {
  id: Scalars['ID']['output']
  scene: InlayScene
  sceneObjects: Array<InlaySceneObject>
}

export type InlayNotFoundError = Error & {
  __typename: 'InlayNotFoundError'
  message: Scalars['String']['output']
}

export type InlayServiceError = Error & {
  __typename: 'InlayServiceError'
  message: Scalars['String']['output']
}

export type VoucherInlay = Inlay & {
  __typename: 'VoucherInlay'
  id: Scalars['ID']['output']
  scene: InlayScene
  sceneObjects: Array<InlaySceneObject>
}

export type Me = {
  __typename: 'Me'
  addressBook?: Maybe<AddressBook>
  anonymousId?: Maybe<Scalars['ID']['output']>
  basket?: Maybe<Basket>
  customer?: Maybe<Customer>
  /** @deprecated Please do not use this field. Please use Me.Customer.order instead. */
  order?: Maybe<Order>
}

export type MebasketArgs = {
  store?: InputMaybe<Store>
}

export type MeorderArgs = {
  id: Scalars['ID']['input']
}

export type AddressBook = {
  __typename: 'AddressBook'
  addresses: Array<ShippingAddress>
}

export type MediaConfig = {
  __typename: 'MediaConfig'
  audio: MediaConfigKind
  video: MediaConfigKind
}

export type MediaConfigKind = {
  __typename: 'MediaConfigKind'
  maxDurationSeconds?: Maybe<Scalars['Int']['output']>
  maxSizeBytes: Scalars['Int']['output']
  sceneAspectRatio: Scalars['Float']['output']
  /** List of supported mime types ordered by preference. If available, clients should use the first mime type from this list. */
  supportedMimeTypes: Array<Scalars['String']['output']>
}

export type MediaRecipientResult =
  | MediaNotFoundError
  | MediaNotReadyError
  | MediaRecipient
  | MediaServiceError

export type MediaNotReadyError = Error & {
  __typename: 'MediaNotReadyError'
  message: Scalars['String']['output']
}

export type MediaRecipient = {
  __typename: 'MediaRecipient'
  downloadUrl: Scalars['String']['output']
  expiresAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  kind: MediaKind
  url: Scalars['String']['output']
}

export type NationalOccasionDateResult = {
  __typename: 'NationalOccasionDateResult'
  day: Scalars['Int']['output']
  month: Scalars['Int']['output']
  nextDate: Scalars['String']['output']
  occasion: Occasion
}

export enum NavigationCapability {
  CARD_FINDER = 'CARD_FINDER',
  COLLAGE_CARD = 'COLLAGE_CARD',
  DIGITAL_GIFTS = 'DIGITAL_GIFTS',
  MOONPIG_PLUS = 'MOONPIG_PLUS',
}

export type Navigation = {
  __typename: 'Navigation'
  items: Array<NavigationItem>
}

export type NavigationItem = {
  __typename: 'NavigationItem'
  icon: Scalars['String']['output']
  image?: Maybe<ContentImage>
  items: Array<NavigationItem>
  label?: Maybe<Scalars['String']['output']>
  requiredCapabilities?: Maybe<Array<NavigationCapability>>
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type Reminder = Node & {
  __typename: 'Reminder'
  addressIds: Array<Scalars['String']['output']>
  created: Scalars['String']['output']
  daysBeforeOccasionToRemind?: Maybe<Scalars['Int']['output']>
  fulfilledThisCalendarYear: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  lastOrder?: Maybe<Order>
  name: Scalars['String']['output']
  nextDate: Scalars['String']['output']
  occasion: Occasion
  recipientId: Scalars['ID']['output']
  recommendations: Array<RecommendationsCategory>
  relationship?: Maybe<Relationship>
  source: Source
}

export type RecommendationsCategory = {
  __typename: 'RecommendationsCategory'
  /** @deprecated Moving to recommendation type and category enums */
  name: Scalars['String']['output']
  products: Array<Product>
  recommendationCategory?: Maybe<ReminderRecommendationCategory>
  recommendationType: RecommendationType
  searchCriteria: RecommendationSearchCriteria
}

export enum ReminderRecommendationCategory {
  CARDS = 'CARDS',
  DIGITAL_GIFTS = 'DIGITAL_GIFTS',
  FLOWERS = 'FLOWERS',
  GIFTS = 'GIFTS',
  UNKNOWN = 'UNKNOWN',
}

export enum RecommendationType {
  FALLBACK = 'FALLBACK',
  PERSONALISED = 'PERSONALISED',
  TRENDING = 'TRENDING',
  USER_AFFINITY = 'USER_AFFINITY',
}

export type RecommendationSearchCriteria = {
  __typename: 'RecommendationSearchCriteria'
  department: DepartmentsEnum
  facetDetails: Array<RecommendationFacetDetails>
  /** @deprecated use facet details for new IA */
  facets: Array<RecommendationFacet>
}

export type RecommendationFacetDetails = {
  __typename: 'RecommendationFacetDetails'
  facetKey?: Maybe<Scalars['String']['output']>
  group: Scalars['String']['output']
  name: Scalars['String']['output']
  path: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type RecommendationFacet = {
  __typename: 'RecommendationFacet'
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

export enum Relationship {
  AUNTIE = 'AUNTIE',
  AUNTIEUNCLE = 'AUNTIEUNCLE',
  BOYFRIEND = 'BOYFRIEND',
  BROTHER = 'BROTHER',
  BROTHER_IN_LAW = 'BROTHER_IN_LAW',
  BROTHER_SISTERINLAW = 'BROTHER_SISTERINLAW',
  DAD = 'DAD',
  DATING_COUPLE = 'DATING_COUPLE',
  DAUGHTER = 'DAUGHTER',
  DAUGHTER_SONINLAW = 'DAUGHTER_SONINLAW',
  FEMALE_COLLEAGUE = 'FEMALE_COLLEAGUE',
  FEMALE_COUSIN = 'FEMALE_COUSIN',
  FEMALE_FRIEND = 'FEMALE_FRIEND',
  FEMALE_TEACHER = 'FEMALE_TEACHER',
  FIANCE = 'FIANCE',
  FIANCEE = 'FIANCEE',
  GIRLFRIEND = 'GIRLFRIEND',
  GODDAUGHTER = 'GODDAUGHTER',
  GODSON = 'GODSON',
  GRANDAD = 'GRANDAD',
  GRANDDAUGHTER = 'GRANDDAUGHTER',
  GRANDSON = 'GRANDSON',
  GRANNY = 'GRANNY',
  GRANNYGRANDAD = 'GRANNYGRANDAD',
  HUSBAND = 'HUSBAND',
  MALE_COLLEAGUE = 'MALE_COLLEAGUE',
  MALE_COUSIN = 'MALE_COUSIN',
  MALE_FRIEND = 'MALE_FRIEND',
  MALE_TEACHER = 'MALE_TEACHER',
  MARRIED_COUPLE = 'MARRIED_COUPLE',
  MUM = 'MUM',
  MUMDAD = 'MUMDAD',
  NEWLY_WEDS = 'NEWLY_WEDS',
  NIECE = 'NIECE',
  SISTER = 'SISTER',
  SISTER_IN_LAW = 'SISTER_IN_LAW',
  SON = 'SON',
  SON_DAUGHTERINLAW = 'SON_DAUGHTERINLAW',
  TWINS = 'TWINS',
  UNCLE = 'UNCLE',
  UNCLE_NEPHEW = 'UNCLE_NEPHEW',
  WIFE = 'WIFE',
}

export enum Source {
  ANDROID_BACK_TO_ME = 'ANDROID_BACK_TO_ME',
  ANDROID_CREATE_ADDRESS = 'ANDROID_CREATE_ADDRESS',
  ANDROID_INFLOW_QUICK_ADD = 'ANDROID_INFLOW_QUICK_ADD',
  ANDROID_REMINDERS_TAB = 'ANDROID_REMINDERS_TAB',
  ANDROID_SEND_DIRECT = 'ANDROID_SEND_DIRECT',
  ANDROID_SOFT_OPT_IN_QUICK_ADD = 'ANDROID_SOFT_OPT_IN_QUICK_ADD',
  ANDROID_SOFT_OPT_IN_REMINDERS = 'ANDROID_SOFT_OPT_IN_REMINDERS',
  EMAIL_UNKNOWN = 'EMAIL_UNKNOWN',
  FACEBOOK_UNKNOWN = 'FACEBOOK_UNKNOWN',
  IOS_BACK_TO_ME_INFLOW_REMINDER = 'IOS_BACK_TO_ME_INFLOW_REMINDER',
  IOS_BULK_IMPORT = 'IOS_BULK_IMPORT',
  IOS_CREATE_ADDRESS = 'IOS_CREATE_ADDRESS',
  IOS_INCENTIVISED_REMINDER_MODAL = 'IOS_INCENTIVISED_REMINDER_MODAL',
  IOS_INFLOW_QUICK_ADD = 'IOS_INFLOW_QUICK_ADD',
  IOS_ORDER_CONFIRMATION = 'IOS_ORDER_CONFIRMATION',
  IOS_REMINDERS_TAB = 'IOS_REMINDERS_TAB',
  IOS_SEND_DIRECT = 'IOS_SEND_DIRECT',
  IOS_SOFT_OPT_IN_QUICK_ADD = 'IOS_SOFT_OPT_IN_QUICK_ADD',
  IOS_SOFT_OPT_IN_REMINDERS = 'IOS_SOFT_OPT_IN_REMINDERS',
  UNKNOWN = 'UNKNOWN',
  WEB_INCENTIVISED_REMINDER_MODAL = 'WEB_INCENTIVISED_REMINDER_MODAL',
  WEB_INCENTIVISED_REMINDER_MODAL_QUICK_ADD = 'WEB_INCENTIVISED_REMINDER_MODAL_QUICK_ADD',
  WEB_INFLOW_MODAL = 'WEB_INFLOW_MODAL',
  WEB_INFLOW_QUICK_ADD = 'WEB_INFLOW_QUICK_ADD',
  WEB_LOAD_TEST = 'WEB_LOAD_TEST',
  WEB_MIGRATED_REMINDER_FLOW = 'WEB_MIGRATED_REMINDER_FLOW',
  WEB_MULTI_INFLOW_MODAL = 'WEB_MULTI_INFLOW_MODAL',
  WEB_ORDER_CONFIRMATION = 'WEB_ORDER_CONFIRMATION',
  WEB_REMINDER_MODAL = 'WEB_REMINDER_MODAL',
  WEB_REMINDER_MODAL_QUICK_ADD = 'WEB_REMINDER_MODAL_QUICK_ADD',
  WEB_REMINDER_PAGE = 'WEB_REMINDER_PAGE',
  WEB_SHARED_REMINDER = 'WEB_SHARED_REMINDER',
  WEB_SOFT_OPT_IN_QUICK_ADD = 'WEB_SOFT_OPT_IN_QUICK_ADD',
  WEB_SOFT_OPT_IN_REMINDERS = 'WEB_SOFT_OPT_IN_REMINDERS',
  WEB_UNKNOWN = 'WEB_UNKNOWN',
}

export type OrdersByDeliveryPostcodeInput = {
  deliveryPostcode: Scalars['String']['input']
  limit?: InputMaybe<Scalars['Long']['input']>
  offset?: InputMaybe<Scalars['Long']['input']>
}

export type OrdersByOrderIdInput = {
  limit?: InputMaybe<Scalars['Long']['input']>
  offset?: InputMaybe<Scalars['Long']['input']>
  orderId: Scalars['ID']['input']
}

export type OrdersByOrderReferenceInput = {
  limit?: InputMaybe<Scalars['Long']['input']>
  offset?: InputMaybe<Scalars['Long']['input']>
  orderReference: Scalars['String']['input']
}

export type ContentVariationInput = {
  name: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type PageResult = Page | Redirect

export type Page = {
  __typename: 'Page'
  canonicalSubPath: Scalars['String']['output']
  /** @deprecated canonicalUrl is deprecated. Use canonicalSubPath instead */
  canonicalUrl: Scalars['String']['output']
  description: Scalars['String']['output']
  layout: PageLayout
  modules: Array<Module>
  noIndex: Scalars['Boolean']['output']
  openGraph: OpenGraph
  path: Scalars['String']['output']
  structuredData: Array<StructuredData>
  title: Scalars['String']['output']
}

export enum PageLayout {
  AppHomepage = 'AppHomepage',
  BlogCategoryPage = 'BlogCategoryPage',
  BlogHomepage = 'BlogHomepage',
  BlogPost = 'BlogPost',
  Gallery = 'Gallery',
  Homepage = 'Homepage',
  Info = 'Info',
  Landing = 'Landing',
}

export type Module =
  | ModuleAlert
  | ModuleBanner
  | ModuleBlogHomepageImage
  | ModuleBlogPostAuthor
  | ModuleBlogPostCategory
  | ModuleBlogPostDate
  | ModuleBlogPostRelatedArticles
  | ModuleBlogPostsCategories
  | ModuleBlogPostsListing
  | ModuleBreadcrumbs
  | ModuleButtons
  | ModuleCard
  | ModuleCTAStrip
  | ModuleDeliveryStrip
  | ModuleDescription
  | ModuleDynamicHero
  | ModuleDynamicMissionButtons
  | ModuleEmailCapture
  | ModuleHeading
  | ModuleHero
  | ModuleIncentivisedReminders
  | ModuleInfo
  | ModuleMissionButtons
  | ModuleModal
  | ModuleOfferStrip
  | ModulePanel
  | ModulePlacards
  | ModulePlaceholder
  | ModulePromoTile
  | ModuleRecommendationsForCustomer
  | ModuleRichText
  | ModuleRichTextImage
  | ModuleSearchResults
  | ModuleSEO
  | ModuleTabbedProductLists
  | ModuleTiles
  | ModuleTwoColumnPlacard
  | ModuleUspCarousel
  | ModuleVideo

export type ModuleAlert = {
  __typename: 'ModuleAlert'
  dismissible: Scalars['Boolean']['output']
  message: Scalars['String']['output']
  severity: ModuleAlertSeverity
  title: Scalars['String']['output']
}

export enum ModuleAlertSeverity {
  Critical = 'Critical',
  Info = 'Info',
  Warning = 'Warning',
}

export type ModuleBanner = {
  __typename: 'ModuleBanner'
  action?: Maybe<Action>
  text: Scalars['String']['output']
  theme: Scalars['String']['output']
}

export type Action = CustomAction | LinkAction

export type CustomAction = {
  __typename: 'CustomAction'
  configJson: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
}

export type ModuleBlogHomepageImage = {
  __typename: 'ModuleBlogHomepageImage'
  image: ContentImage
}

export type ModuleBlogPostAuthor = {
  __typename: 'ModuleBlogPostAuthor'
  image: ContentImage
  jobTitle: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type ModuleBlogPostCategory = {
  __typename: 'ModuleBlogPostCategory'
  category: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ModuleBlogPostDate = {
  __typename: 'ModuleBlogPostDate'
  date: Scalars['String']['output']
}

export type ModuleBlogPostRelatedArticles = {
  __typename: 'ModuleBlogPostRelatedArticles'
  items: Array<BlogPostRelatedArticle>
  title: Scalars['String']['output']
}

export type BlogPostRelatedArticle = {
  __typename: 'BlogPostRelatedArticle'
  cta: CTA
  image: ContentImage
  title: Scalars['String']['output']
}

export type ModuleBlogPostsCategories = {
  __typename: 'ModuleBlogPostsCategories'
  items: Array<ModuleBlogPostCategory>
}

export type ModuleBlogPostsListing = {
  __typename: 'ModuleBlogPostsListing'
  items: Array<BlogPostListItem>
  title: Scalars['String']['output']
}

export type BlogPostListItem = {
  __typename: 'BlogPostListItem'
  image: ContentImage
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ModuleBreadcrumbs = {
  __typename: 'ModuleBreadcrumbs'
  breadcrumbs: Array<Breadcrumb>
}

export type Breadcrumb = {
  __typename: 'Breadcrumb'
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ModuleButtons = {
  __typename: 'ModuleButtons'
  items: Array<CTA>
}

export type ModuleCard = {
  __typename: 'ModuleCard'
  ctas?: Maybe<Array<CTA>>
  image: ContentImage
  pills?: Maybe<Array<Scalars['String']['output']>>
  subtitle: Scalars['String']['output']
  text: Scalars['String']['output']
  title: Scalars['String']['output']
  video?: Maybe<ContentVideo>
}

export type ContentVideo = {
  __typename: 'ContentVideo'
  contentType: Scalars['String']['output']
  description: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ModuleCTAStrip = ContentModule & {
  __typename: 'ModuleCTAStrip'
  backgroundImage?: Maybe<ContentImage>
  banner: Scalars['Boolean']['output']
  cta: CTA
  meta: ContentMetadata
  text: Scalars['String']['output']
}

export type ContentModule = {
  meta: ContentMetadata
}

export type ContentMetadata = {
  __typename: 'ContentMetadata'
  experiment?: Maybe<ContentMetadataExperiment>
  variation?: Maybe<ContentMetadataVariation>
}

export type ContentMetadataExperiment = {
  __typename: 'ContentMetadataExperiment'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type ContentMetadataVariation = {
  __typename: 'ContentMetadataVariation'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type ModuleDeliveryStrip = {
  __typename: 'ModuleDeliveryStrip'
  cta?: Maybe<CTA>
  text: Scalars['String']['output']
}

export type ModuleDescription = {
  __typename: 'ModuleDescription'
  description: Scalars['String']['output']
}

export type ModuleDynamicHero = {
  __typename: 'ModuleDynamicHero'
  ctas?: Maybe<Array<CTA>>
  image: ContentImage
  offer: Scalars['Boolean']['output']
  promotionId?: Maybe<Scalars['String']['output']>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
  variant: ModuleHeroVariant
}

export enum ModuleHeroVariant {
  FullWidth = 'FullWidth',
  LimitedWidth = 'LimitedWidth',
}

export type ModuleDynamicMissionButtons = ContentModule & {
  __typename: 'ModuleDynamicMissionButtons'
  meta: ContentMetadata
  missionButtons: Array<MissionButton>
  promotionId?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type MissionButton = {
  __typename: 'MissionButton'
  image?: Maybe<ContentImage>
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ModuleEmailCapture = {
  __typename: 'ModuleEmailCapture'
  display: Scalars['String']['output']
  heading: Scalars['String']['output']
  text: Scalars['String']['output']
  thankYouHeading: Scalars['String']['output']
  thankYouText: Scalars['String']['output']
}

export type ModuleHeading = {
  __typename: 'ModuleHeading'
  heading: Scalars['String']['output']
  hidden: Scalars['Boolean']['output']
}

export type ModuleHero = {
  __typename: 'ModuleHero'
  ctas?: Maybe<Array<CTA>>
  image: ContentImage
  offer: Scalars['Boolean']['output']
  promotionId?: Maybe<Scalars['String']['output']>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
  variant: ModuleHeroVariant
}

export type ModuleIncentivisedReminders = {
  __typename: 'ModuleIncentivisedReminders'
  endImage: ContentImage
  endText: Scalars['String']['output']
  endTitle: Scalars['String']['output']
  introImage: ContentImage
  introText: Scalars['String']['output']
  introTitle: Scalars['String']['output']
  notificationTitle: Scalars['String']['output']
  numberOfRemindersInOffer: Scalars['Int']['output']
  occasions?: Maybe<Array<ContentReminderOccasion>>
  relations?: Maybe<Array<ContentReminderRelation>>
  title: Scalars['String']['output']
  voucherCode?: Maybe<Scalars['String']['output']>
  voucherDescription: Scalars['String']['output']
}

export type ContentReminderOccasion = {
  __typename: 'ContentReminderOccasion'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ContentReminderRelation = {
  __typename: 'ContentReminderRelation'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ModuleInfo = {
  __typename: 'ModuleInfo'
  items: Array<InfoBlock>
  title: Scalars['String']['output']
}

export type InfoBlock = {
  __typename: 'InfoBlock'
  image: ContentImage
  text: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ModuleMissionButtons = ContentModule & {
  __typename: 'ModuleMissionButtons'
  meta: ContentMetadata
  missionButtons: Array<MissionButton>
  promotionId?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type ModuleModal = {
  __typename: 'ModuleModal'
  /** @deprecated this is legacy and will be removed in the future */
  buttons: Array<CTA>
  /** @deprecated this is legacy and will be removed in the future */
  cookieName: Scalars['String']['output']
  /** @deprecated this is legacy and will be removed in the future */
  dismissalMessage: Scalars['String']['output']
  /** @deprecated this is legacy and will be removed in the future */
  heading: Scalars['String']['output']
  /** @deprecated this is legacy and will be removed in the future */
  platforms: Array<BrowserPlatform>
  /** @deprecated this is legacy and will be removed in the future */
  text: Scalars['String']['output']
}

export enum BrowserPlatform {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
}

export type ModuleOfferStrip = {
  __typename: 'ModuleOfferStrip'
  enabled: Scalars['Boolean']['output']
  text: Scalars['String']['output']
}

export type ModulePanel = {
  __typename: 'ModulePanel'
  cta: CTA
  image: ContentImage
  name: Scalars['String']['output']
}

export type ModulePlacards = {
  __typename: 'ModulePlacards'
  items: Array<Placard>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type Placard = {
  __typename: 'Placard'
  cta: CTA
  image: ContentImage
  title: Scalars['String']['output']
}

export type ModulePlaceholder = {
  __typename: 'ModulePlaceholder'
  variant: ModulePlaceholderVariant
}

export enum ModulePlaceholderVariant {
  Bundles = 'Bundles',
  ContinueJourney = 'ContinueJourney',
  CustomerSatisfaction = 'CustomerSatisfaction',
  Drafts = 'Drafts',
  GroupCardsStarter = 'GroupCardsStarter',
  MostRecentOrder = 'MostRecentOrder',
  RecentlyViewed = 'RecentlyViewed',
  ReminderImport = 'ReminderImport',
  Reminders = 'Reminders',
  Voucher = 'Voucher',
}

export type ModulePromoTile = {
  __typename: 'ModulePromoTile'
  cta: CTA
  description: Scalars['String']['output']
  image: ContentImage
  internallyPromoted: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ModuleRecommendationsForCustomer = {
  __typename: 'ModuleRecommendationsForCustomer'
  algorithm: Scalars['String']['output']
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ModuleRichText = {
  __typename: 'ModuleRichText'
  blocks: Array<RichTextBlock>
}

export type RichTextBlock = RichTextBlockHTML | RichTextBlockModule

export type RichTextBlockHTML = {
  __typename: 'RichTextBlockHTML'
  html: Scalars['String']['output']
}

export type RichTextBlockModule = {
  __typename: 'RichTextBlockModule'
  module: Module
}

export type ModuleRichTextImage = {
  __typename: 'ModuleRichTextImage'
  altText: Scalars['String']['output']
  image: ContentImage
}

export type ModuleSearchResults = {
  __typename: 'ModuleSearchResults'
  /** @deprecated no longer supported */
  availableFilters: Array<Scalars['String']['output']>
  departments: Array<Scalars['String']['output']>
  facetDetails: Array<ContentSearchFacetDetails>
  keywords: Scalars['String']['output']
  promotionId?: Maybe<Scalars['String']['output']>
  /** @deprecated use facetDetails and promotionId instead */
  searchFiltersJson: Scalars['String']['output']
  /** @deprecated use keywords instead */
  searchTerm: Scalars['String']['output']
  sponsoredProducts: Array<SponsoredProduct>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ContentSearchFacetDetails = {
  __typename: 'ContentSearchFacetDetails'
  group?: Maybe<Scalars['String']['output']>
  key?: Maybe<Scalars['String']['output']>
  /** @deprecated use key instead */
  name: Scalars['String']['output']
  /** @deprecated use key instead */
  path: Scalars['String']['output']
  /** @deprecated use key instead */
  value: Scalars['String']['output']
}

export type SponsoredProduct = {
  __typename: 'SponsoredProduct'
  internallyPromoted: Scalars['Boolean']['output']
  productKey: Scalars['String']['output']
}

export type ModuleSEO = {
  __typename: 'ModuleSEO'
  items: Array<SEOBlock>
}

export type SEOBlock = {
  __typename: 'SEOBlock'
  richText?: Maybe<Scalars['String']['output']>
  text: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ModuleTabbedProductLists = {
  __typename: 'ModuleTabbedProductLists'
  productLists: Array<ModuleSearchResults>
  title: Scalars['String']['output']
}

export type ModuleTiles = {
  __typename: 'ModuleTiles'
  items: Array<Tile>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
  variant: ModuleTilesVariant
}

export type Tile = {
  __typename: 'Tile'
  image: ContentImage
  label?: Maybe<TileLabel>
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type TileLabel = LabelPromo | LabelRoundel

export type LabelPromo = {
  __typename: 'LabelPromo'
  text: Scalars['String']['output']
}

export type LabelRoundel = {
  __typename: 'LabelRoundel'
  bottom: Scalars['String']['output']
  prefix: Scalars['String']['output']
  top: Scalars['String']['output']
}

export enum ModuleTilesVariant {
  Default = 'Default',
  Round = 'Round',
}

export type ModuleTwoColumnPlacard = {
  __typename: 'ModuleTwoColumnPlacard'
  body: Scalars['String']['output']
  cta?: Maybe<CTA>
  image: ContentImage
  imagePlacement: ImagePlacement
  title: Scalars['String']['output']
  variant: ColumnWidthVariant
}

export enum ImagePlacement {
  ImageLeft = 'ImageLeft',
  ImageRight = 'ImageRight',
}

export enum ColumnWidthVariant {
  EqualWidth = 'EqualWidth',
  LeftWider = 'LeftWider',
  RightWider = 'RightWider',
}

export type ModuleUspCarousel = {
  __typename: 'ModuleUspCarousel'
  items: Array<USP>
}

export type USP = {
  __typename: 'USP'
  description: Scalars['String']['output']
  icon?: Maybe<ContentImage>
  title: Scalars['String']['output']
}

export type ModuleVideo = {
  __typename: 'ModuleVideo'
  id: Scalars['ID']['output']
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
  titleHidden: Scalars['Boolean']['output']
}

export type OpenGraph = {
  __typename: 'OpenGraph'
  description: Scalars['String']['output']
  image: ContentImage
  locale: Scalars['String']['output']
  siteName: Scalars['String']['output']
  title: Scalars['String']['output']
  type?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type StructuredData = {
  __typename: 'StructuredData'
  json: Scalars['String']['output']
}

export type Redirect = {
  __typename: 'Redirect'
  url: Scalars['String']['output']
}

export type PostcodeLookupResult = LookupAddressesUkResult | PostcodeLookupError

export type LookupAddressesUkResult = {
  __typename: 'LookupAddressesUkResult'
  addresses: Array<LookupAddressUk>
}

export type LookupAddressUk = {
  __typename: 'LookupAddressUk'
  addressLines: Scalars['String']['output']
  county?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  postcode: Scalars['String']['output']
  postTown?: Maybe<Scalars['String']['output']>
}

export type PostcodeLookupError = Error & {
  __typename: 'PostcodeLookupError'
  message: Scalars['String']['output']
}

export enum StoresEnumType {
  AU = 'AU',
  IE = 'IE',
  UK = 'UK',
  US = 'US',
}

export type ProductLookupResults = {
  __typename: 'ProductLookupResults'
  count: Scalars['Int']['output']
  products: Array<Product>
}

export enum Platform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  MOBILE_WEB = 'MOBILE_WEB',
  UNSPECIFIED = 'UNSPECIFIED',
  WEB = 'WEB',
}

export enum SortOrder {
  NEWNESS = 'NEWNESS',
  POPULARITY = 'POPULARITY',
  PRICE_ASCENDING = 'PRICE_ASCENDING',
  PRICE_DESCENDING = 'PRICE_DESCENDING',
  RATING_DESCENDING = 'RATING_DESCENDING',
}

export type SponsoredProductInput = {
  internallyPromoted: Scalars['Boolean']['input']
  productKey: Scalars['String']['input']
}

export type ProductSearchResults = {
  __typename: 'ProductSearchResults'
  count: Scalars['Int']['output']
  facets?: Maybe<Facets>
  products: Array<Product>
  spellingCorrection?: Maybe<SpellingCorrection>
  suggestions: Array<Product>
  supportedSortOrders: Array<SortOrderDescriptor>
  totalClickRankDocumentCount: Scalars['Int']['output']
}

export type Facets = {
  __typename: 'Facets'
  all?: Maybe<Array<Maybe<Facet>>>
  hierarchical?: Maybe<Array<Maybe<Facet>>>
  /** @deprecated Use 'all' collection instead. */
  occasion?: Maybe<Array<Maybe<Facet>>>
  /** @deprecated Use 'all' collection instead. */
  recipient?: Maybe<Array<Maybe<Facet>>>
}

export type Facet = {
  __typename: 'Facet'
  children?: Maybe<Array<Facet>>
  count: Scalars['Int']['output']
  facetKey?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  label?: Maybe<Scalars['String']['output']>
  /** @deprecated Use 'group' instead. */
  name: Scalars['String']['output']
  nbaScore?: Maybe<Scalars['Float']['output']>
  /** @deprecated No longer supported */
  path?: Maybe<Scalars['String']['output']>
  /** @deprecated Use 'facetKey' instead as it is language agnostic */
  value: Scalars['String']['output']
}

export type SpellingCorrection = {
  __typename: 'SpellingCorrection'
  correctedSearchTerm: Scalars['String']['output']
  initialSearchTerm: Scalars['String']['output']
}

export type SortOrderDescriptor = {
  __typename: 'SortOrderDescriptor'
  displayText: Scalars['String']['output']
  isDefault: Scalars['Boolean']['output']
  value: SortOrder
}

export type Recommendation = {
  __typename: 'Recommendation'
  category: ProductCategory
  customisable: Scalars['Boolean']['output']
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  isLandscape: Scalars['Boolean']['output']
  masterVariant: RecommendedProductVariant
  publishDate?: Maybe<Scalars['DateTime']['output']>
  rating?: Maybe<ProductRating>
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  variants: Array<RecommendedProductVariant>
}

export type RecommendedProductVariant = {
  __typename: 'RecommendedProductVariant'
  images: Array<Image>
  inStock: Scalars['Boolean']['output']
  key: Scalars['String']['output']
  masterImage: Image
  minimumQuantity: Scalars['Int']['output']
  price: Money
  sizeId: Scalars['Int']['output']
  sku?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type RecommendationCategory = {
  __typename: 'RecommendationCategory'
  categoryName: Scalars['String']['output']
  recommendations: Array<Recommendation>
}

export type RedemptionInput = {
  id: Scalars['ID']['input']
}

export type RedemptionResult =
  | Redemption
  | RedemptionActivationFailedError
  | RedemptionExpiredError
  | RedemptionNotFoundError

export type Redemption = {
  __typename: 'Redemption'
  items: Array<RedemptionItem>
}

export type RedemptionItem = RedeemableDigitalGift | UnredeemableGift

export type RedeemableDigitalGift = {
  __typename: 'RedeemableDigitalGift'
  action: RedemptionAction
  customerFacingReference?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  sku: Scalars['String']['output']
}

export type RedemptionAction =
  | ActivateDigitalGift
  | RedirectToSupplier
  | ViewDigitalGift

export type ActivateDigitalGift = {
  __typename: 'ActivateDigitalGift'
  _?: Maybe<Scalars['Boolean']['output']>
}

export type RedirectToSupplier = {
  __typename: 'RedirectToSupplier'
  url: Scalars['String']['output']
}

export type ViewDigitalGift = {
  __typename: 'ViewDigitalGift'
  url: Scalars['String']['output']
}

export type UnredeemableGift = {
  __typename: 'UnredeemableGift'
  customerFacingReference?: Maybe<Scalars['String']['output']>
  giftSku?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
}

export type RedemptionActivationFailedError = Error & {
  __typename: 'RedemptionActivationFailedError'
  message: Scalars['String']['output']
}

export type RedemptionExpiredError = Error & {
  __typename: 'RedemptionExpiredError'
  message: Scalars['String']['output']
}

export type RedemptionNotFoundError = Error & {
  __typename: 'RedemptionNotFoundError'
  message: Scalars['String']['output']
}

export type GetReminderInput = {
  id: Scalars['String']['input']
  store?: InputMaybe<Store>
}

export type GetReminderResult = Reminder | ReminderNotFound

export type ReminderNotFound = Error & {
  __typename: 'ReminderNotFound'
  id: Scalars['ID']['output']
  message: Scalars['String']['output']
}

export type ReminderOccasion = {
  __typename: 'ReminderOccasion'
  defaultDate?: Maybe<OccasionDate>
  defaultRelationship?: Maybe<Relationship>
  facetKeys: Array<Scalars['String']['output']>
  label: Scalars['String']['output']
  occasion: Occasion
}

export type OccasionDate = {
  __typename: 'OccasionDate'
  day: Scalars['Int']['output']
  month: Scalars['Int']['output']
}

export type ReminderRelationship = {
  __typename: 'ReminderRelationship'
  label: Scalars['String']['output']
  relationship: Relationship
}

export type GetRemindersInput = {
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  store?: InputMaybe<Store>
  take?: InputMaybe<Scalars['Int']['input']>
}

export enum RendererDynamicImageFormat {
  JPEG = 'JPEG',
  PNG = 'PNG',
}

export type RendererDynamicImageResult =
  | RendererDynamicImage
  | RendererServiceError

export type RendererDynamicImage = {
  __typename: 'RendererDynamicImage'
  height: Scalars['Int']['output']
  url: Scalars['String']['output']
  width: Scalars['Int']['output']
}

export type RendererShareableImagesResult =
  | RendererNotFoundError
  | RendererServiceError
  | RendererShareableImages

export type RendererNotFoundError = Error & {
  __typename: 'RendererNotFoundError'
  message: Scalars['String']['output']
}

export type RendererShareableImages = {
  __typename: 'RendererShareableImages'
  layout: RendererShareableImagesLayout
  scenes: Array<RendererShareableImageScene>
}

export enum RendererShareableImagesLayout {
  CARD_LANDSCAPE = 'CARD_LANDSCAPE',
  CARD_PORTRAIT = 'CARD_PORTRAIT',
  GENERIC = 'GENERIC',
}

export type RendererShareableImageScene = {
  __typename: 'RendererShareableImageScene'
  height: Scalars['Float']['output']
  image: RendererShareableImage
  interactiveElements: Array<RendererInteractiveElement>
  title: Scalars['String']['output']
  width: Scalars['Float']['output']
}

export type RendererShareableImage = {
  __typename: 'RendererShareableImage'
  height: Scalars['Int']['output']
  url: Scalars['String']['output']
  width: Scalars['Int']['output']
}

export type RendererInteractiveElement = RendererInteractiveElementLink

export type RendererInteractiveElementLink = {
  __typename: 'RendererInteractiveElementLink'
  height: Scalars['Float']['output']
  label: Scalars['String']['output']
  url: Scalars['String']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type SearchInspirationsResults = {
  __typename: 'SearchInspirationsResults'
  inspirations: Array<InspirationItem>
}

export type InspirationItem = ProductInspiration | SearchTermInspiration

export type ProductInspiration = {
  __typename: 'ProductInspiration'
  department: DepartmentsEnum
  productSku: Scalars['String']['output']
}

export type SearchTermInspiration = {
  __typename: 'SearchTermInspiration'
  department: DepartmentsEnum
  searchTerm: Scalars['String']['output']
}

export enum SuggesterType {
  DYNAMIC = 'DYNAMIC',
  FACETS = 'FACETS',
  STATIC = 'STATIC',
}

export type SearchSuggestResults = {
  __typename: 'SearchSuggestResults'
  suggestions: Array<SearchSuggestion>
}

export type SearchSuggestion = {
  __typename: 'SearchSuggestion'
  arguments: SearchSuggestionArguments
  departmentCounts: Array<DepartmentCount>
  departments: Array<DepartmentsEnum>
  score: Scalars['Decimal']['output']
  searchTerm: Scalars['String']['output']
}

export type SearchSuggestionArguments = {
  __typename: 'SearchSuggestionArguments'
  filters?: Maybe<SearchSuggestionFilters>
  searchTerm?: Maybe<Scalars['String']['output']>
}

export type SearchSuggestionFilters = {
  __typename: 'SearchSuggestionFilters'
  facets?: Maybe<Array<SearchSuggestionFacet>>
}

export type SearchSuggestionFacet = {
  __typename: 'SearchSuggestionFacet'
  facetKey: Scalars['String']['output']
  groups: Array<FacetGroup>
  label?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  path?: Maybe<Scalars['String']['output']>
  value: Scalars['String']['output']
}

export type FacetGroup = {
  __typename: 'FacetGroup'
  department: DepartmentsEnum
  group: Scalars['String']['output']
}

export type DepartmentCount = {
  __typename: 'DepartmentCount'
  count?: Maybe<Scalars['Int']['output']>
  department: DepartmentsEnum
}

export type SharedReminderResult =
  | BadRequest
  | ReminderNotFound
  | SharedReminder

export type BadRequest = Error & {
  __typename: 'BadRequest'
  message: Scalars['String']['output']
}

export type SharedReminder = {
  __typename: 'SharedReminder'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  nextDate: Scalars['String']['output']
  occasion: Occasion
}

export type StickersFilterInput = {
  stickerSetIds: Array<Scalars['ID']['input']>
}

export type StickersResult =
  | DesignInvalidCursorError
  | DesignServiceError
  | StickerEdges

export type StickerEdges = {
  __typename: 'StickerEdges'
  edges: Array<StickerEdge>
  pageInfo: StickerPageInfo
  totalCount: Scalars['Int']['output']
}

export type StickerEdge = {
  __typename: 'StickerEdge'
  cursor: Scalars['ID']['output']
  node: Sticker
}

export type Sticker = {
  __typename: 'Sticker'
  id: Scalars['ID']['output']
  urls: StickerUrls
}

export type StickerPageInfo = {
  __typename: 'StickerPageInfo'
  endCursor?: Maybe<Scalars['ID']['output']>
  hasNextPage: Scalars['Boolean']['output']
}

export type SuggestedMessage = {
  __typename: 'SuggestedMessage'
  category: Scalars['String']['output']
  messages: Array<Scalars['String']['output']>
}

export type Suggestions = {
  __typename: 'Suggestions'
  messages?: Maybe<SuggestedMessagesResult>
  messagesOptions: SuggestedMessagesOptionsResult
}

export type SuggestionsmessagesArgs = {
  input: SuggestedMessagesInput
}

export type SuggestedMessagesInput = {
  details?: InputMaybe<Scalars['String']['input']>
  length?: InputMaybe<SuggestedMessageLength>
  occasion: Scalars['String']['input']
  relationship?: InputMaybe<Scalars['String']['input']>
  tone?: InputMaybe<Scalars['String']['input']>
}

export enum SuggestedMessageLength {
  LONG = 'LONG',
  MEDIUM = 'MEDIUM',
  SHORT = 'SHORT',
}

export type SuggestedMessagesResult =
  | SuggestedMessages
  | SuggestedMessagesError
  | SuggestedMessagesModeratedError

export type SuggestedMessages = {
  __typename: 'SuggestedMessages'
  values: Array<Scalars['String']['output']>
}

export type SuggestedMessagesError = Error & {
  __typename: 'SuggestedMessagesError'
  message: Scalars['String']['output']
}

export type SuggestedMessagesModeratedError = Error & {
  __typename: 'SuggestedMessagesModeratedError'
  message: Scalars['String']['output']
}

export type SuggestedMessagesOptionsResult =
  | SuggestedMessagesError
  | SuggestedMessagesOptions

export type SuggestedMessagesOptions = {
  __typename: 'SuggestedMessagesOptions'
  occasions: Array<SuggestedMessagesOption>
  relationships: Array<SuggestedMessagesOption>
  tones: Array<SuggestedMessagesOption>
}

export type SuggestedMessagesOption = {
  __typename: 'SuggestedMessagesOption'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type SuggestedReminder = {
  __typename: 'SuggestedReminder'
  date: OccasionDate
  occasion: Occasion
  relationship?: Maybe<Relationship>
}

export type TrackingInformationInput = {
  trackingId: Scalars['ID']['input']
}

export type TrackingInformationResult =
  | TrackingInformation
  | TrackingInformationInvalidTrackingId

export type TrackingInformation = {
  __typename: 'TrackingInformation'
  deliveryDate?: Maybe<Scalars['String']['output']>
  fullTrackingUrl?: Maybe<Scalars['String']['output']>
}

export type TrackingInformationInvalidTrackingId = Error & {
  __typename: 'TrackingInformationInvalidTrackingId'
  message: Scalars['String']['output']
}

export type Mutation = {
  __typename: 'Mutation'
  activateRedemption: RedemptionResult
  addCardAndDigitalGiftToBasket?: Maybe<AddCardAndDigitalGiftResult>
  addCredits?: Maybe<AddCreditsResult>
  addCustomerAddress?: Maybe<AddCustomerAddressResult>
  addCustomerAddresses?: Maybe<AddCustomerAddressesResult>
  addCustomerSelfService?: Maybe<CustomerSelfServiceResponse>
  addDesignToBasket: AddDesignToBasketResult
  addDigitalGiftToBasket?: Maybe<AddDigitalGiftToBasketResult>
  addMembershipToBasket: AddMembershipToBasketResult
  addToBasket?: Maybe<AddToBasketResult>
  addToBasketAndSplit?: Maybe<AddToBasketAndSplitResult>
  addToFavouritesList: AddToFavouritesList
  applyAutoPromo?: Maybe<ApplyAutoPromoResult>
  applyPromo?: Maybe<ApplyPromoResult>
  assignABTestVariant?: Maybe<AssignABTestVariantResult>
  assignBasketAddress?: Maybe<AssignBasketAddressResult>
  assignBasketVoucher?: Maybe<AssignBasketVoucherResult>
  assignBillingAddress?: Maybe<AssignBillingAddressResult>
  assignDeliveryAddress?: Maybe<AssignDeliveryAddressResult>
  assignDeliveryDate?: Maybe<AssignDeliveryDateResult>
  assignDeliveryGiftNote?: Maybe<AssignDeliveryGiftNoteResult>
  assignDeliveryMethod?: Maybe<AssignDeliveryMethodResult>
  assignLineItemAddress?: Maybe<AssignLineItemAddressResult>
  assignLineItemEmailAddress?: Maybe<AssignLineItemEmailAddressResult>
  cancelMembership: Membership
  /** @deprecated Use requestOrderCancellation */
  cancelOrder: CancelOrderResult
  checkoutBusinessProject: CheckoutBusinessProjectResponse
  cloneSharedReminder: CloneSharedReminderResult
  createAudioMedia: CreateAudioMediaResult
  createCustomerCreationsFont: CreateCustomerCreationsFontResponse
  createDemoMedia: CreateDemoMediaResult
  createDesign: CreateDesignResult
  createDesignImageUpload: CreateDesignImageUploadResult
  createDraftFromDesign: CreateDraftFromDesignResult
  createIncentivisedReminders: CreateIncentivisedRemindersResult
  /** @deprecated Not ready for use */
  createRecipient: BaseRecipient
  createReminder: CreateReminderResult
  createRemindersAsync: CreateRemindersAsyncResult
  createRemindersFromProducts: CreateRemindersFromProductsResult
  createVideoMedia: CreateVideoMediaResult
  crmDeleteReminders: CrmDeleteReminderResult
  deleteBasket?: Maybe<DeleteBasketResult>
  deleteBusinessProject: DeleteBusinessProjectResponse
  deleteCustomerCreationsFont: DeleteCustomerCreationsFontResponse
  deleteDraftDesign: DeleteDraftDesignResult
  deleteLineItem?: Maybe<DeleteLineItemResult>
  /** @deprecated Not ready for use */
  deleteRecipient: Scalars['ID']['output']
  deleteReminder: DeleteReminderResult
  duplicateLineItem?: Maybe<DuplicateLineItemResult>
  expireCredits?: Maybe<ExpireCreditsResult>
  forgottenPassword?: Maybe<ForgottenPasswordResult>
  generateBillingPortalUrl: Scalars['String']['output']
  generateTrackingLink?: Maybe<GenerateTrackingLinkResponse>
  getOrCreateDraftDesign: GetOrCreateDraftDesignResult
  groupCardAddMessage: GroupAddMessageResult
  groupCardAddMessageV2: GroupAddMessageResultV2
  groupCardArchiveProject: GroupCardArchiveProjectResult
  groupCardCreateDesign: GroupCardCreateDesignResult
  groupCardCreateProject: GroupCardCreateProjectResult
  groupCardCreateProjectV2: GroupCardCreateProjectResultV2
  groupCardDeleteMessage: GroupDeleteMessageResult
  groupCardDeleteMessageV2: GroupDeleteMessageResultV2
  groupCardDeleteProject: GroupCardDeleteProjectResult
  groupCardTrackCheckout: GroupCardTrackCheckoutResult
  groupCardTrackSignupEvent: GroupCardTrackSignupEventResult
  groupCardUpdateProject: GroupCardUpdateProjectResult
  groupCardUpdateProjectV2: GroupCardUpdateProjectResultV2
  groupCardUpdateProjectWithDesign: GroupCardUpdateProjectWithDesignResult
  reactivateMembership: Membership
  registerEmail: RegisterEmailResult
  removeBasketVoucher?: Maybe<RemoveBasketVoucherResult>
  removeCustomerAddress?: Maybe<RemoveCustomerAddressResult>
  removeFromFavouritesList: RemoveFromFavouritesList
  removeMembershipFromBasket: RemoveMembershipFromBasketResult
  removeSavedCard?: Maybe<RemoveSavedCardResult>
  requestOrderCancellation: RequestOrderCancellationResult
  requestResend: RequestResendResult
  revokeMembership: RevokeMembershipResponse
  selfValidateAddress: SelfValidateAddressResult
  setBusinessCardCustomisation: BusinessProjectResponse
  setBusinessCardProductAndDesign: BusinessProjectResponse
  setBusinessRecipients: BusinessProjectResponse
  setDesignCustomisations: SetDesignCustomisationsResult
  setDesignInlay: SetDesignInlayResult
  shareReminder: ShareReminderResult
  splitLineItemQuantity?: Maybe<SplitLineItemQuantityResult>
  splitMultiQuantityCards?: Maybe<SplitMultiCardsResult>
  trackRecipient?: Maybe<TrackRecipientResult>
  unlinkReminderFromOrder: UnlinkReminderFromOrderResult
  updateAddressPackaging?: Maybe<UpdateAddressPackagingResult>
  updateContactPreferences?: Maybe<ContactPreferences>
  updateCustomerAddress?: Maybe<UpdateCustomerAddressResult>
  updateCustomerDetails?: Maybe<UpdateCustomerDetailsResult>
  /** @deprecated Exists only for migration from commerce-api. Use updateContactPreferences instead */
  updateCustomerMarketingConsentPosition?: Maybe<UpdateCustomerMarketingConsentPositionResult>
  updateEmail?: Maybe<UpdateEmailResult>
  updateLineItem?: Maybe<UpdateLineItemResult>
  /** @deprecated Replaced by updateLineItem */
  updateLineItemQuantity?: Maybe<UpdateLineItemQuantityResult>
  /** @deprecated Replaced by updateLineItem */
  updateLineItemVariant?: Maybe<UpdateLineItemVariantResult>
  /** @deprecated Not ready for use */
  updateRecipient: BaseRecipient
  updateReminder: UpdateReminderResult
  validateBasket?: Maybe<ValidateBasketResult>
  validateCustomerAddress: ValidateCustomerAddressResult
}

export type MutationactivateRedemptionArgs = {
  input: ActivateRedemptionInput
}

export type MutationaddCardAndDigitalGiftToBasketArgs = {
  input: AddCardAndDigitalGiftToBasketInput
}

export type MutationaddCreditsArgs = {
  input: AddCreditsInput
}

export type MutationaddCustomerAddressArgs = {
  input: CustomerAddressInput
}

export type MutationaddCustomerAddressesArgs = {
  input: CustomerAddressesInput
}

export type MutationaddCustomerSelfServiceArgs = {
  input: CustomerSelfServiceInput
}

export type MutationaddDesignToBasketArgs = {
  input: AddDesignToBasketInput
}

export type MutationaddDigitalGiftToBasketArgs = {
  input: AddDigitalGiftToBasketInput
}

export type MutationaddMembershipToBasketArgs = {
  input: AddMembershipToBasketInput
}

export type MutationaddToBasketArgs = {
  input: AddToBasketInput
}

export type MutationaddToBasketAndSplitArgs = {
  input: AddToBasketAndSplitInput
}

export type MutationaddToFavouritesListArgs = {
  listId?: Scalars['ID']['input']
  productIds: Array<Scalars['String']['input']>
}

export type MutationapplyAutoPromoArgs = {
  input: ApplyAutoPromoInput
}

export type MutationapplyPromoArgs = {
  input: ApplyPromoInput
}

export type MutationassignABTestVariantArgs = {
  input: AssignABTestVariantInput
}

export type MutationassignBasketAddressArgs = {
  input: AssignBasketAddressInput
}

export type MutationassignBasketVoucherArgs = {
  input: AssignBasketVoucherInput
}

export type MutationassignBillingAddressArgs = {
  addressId: Scalars['String']['input']
}

export type MutationassignDeliveryAddressArgs = {
  input: AssignDeliveryAddressInput
}

export type MutationassignDeliveryDateArgs = {
  input: AssignDeliveryDateInput
}

export type MutationassignDeliveryGiftNoteArgs = {
  input: AssignDeliveryGiftNoteInput
}

export type MutationassignDeliveryMethodArgs = {
  input: AssignDeliveryMethodInput
}

export type MutationassignLineItemAddressArgs = {
  input: AssignLineItemAddressInput
}

export type MutationassignLineItemEmailAddressArgs = {
  input: AssignLineItemEmailAddressInput
}

export type MutationcancelMembershipArgs = {
  feedback?: InputMaybe<MembershipFeedback>
}

export type MutationcancelOrderArgs = {
  input: CancelOrderInput
}

export type MutationcloneSharedReminderArgs = {
  sharedReminder: Scalars['String']['input']
}

export type MutationcreateAudioMediaArgs = {
  input: CreateAudioMediaInput
}

export type MutationcreateDemoMediaArgs = {
  input: CreateDemoMediaInput
}

export type MutationcreateDesignArgs = {
  input: CreateDesignInput
}

export type MutationcreateDesignImageUploadArgs = {
  input: CreateDesignImageUploadInput
}

export type MutationcreateDraftFromDesignArgs = {
  input: CreateDraftFromDesignInput
}

export type MutationcreateIncentivisedRemindersArgs = {
  input: Array<ReminderInput>
}

export type MutationcreateRecipientArgs = {
  input: CreateRecipientInput
}

export type MutationcreateReminderArgs = {
  input: ReminderInput
}

export type MutationcreateRemindersAsyncArgs = {
  input: Array<ReminderInput>
}

export type MutationcreateRemindersFromProductsArgs = {
  input: RemindersFromProductsInput
}

export type MutationcreateVideoMediaArgs = {
  input: CreateVideoMediaInput
}

export type MutationcrmDeleteRemindersArgs = {
  input: CrmDeleteRemindersInput
}

export type MutationdeleteBasketArgs = {
  input?: InputMaybe<DeleteBasketInput>
}

export type MutationdeleteCustomerCreationsFontArgs = {
  assetId: Scalars['String']['input']
}

export type MutationdeleteDraftDesignArgs = {
  input: DeleteDraftDesignInput
}

export type MutationdeleteLineItemArgs = {
  input: DeleteLineItemInput
}

export type MutationdeleteRecipientArgs = {
  id: Scalars['ID']['input']
}

export type MutationdeleteReminderArgs = {
  id: Scalars['ID']['input']
  store?: InputMaybe<Store>
}

export type MutationduplicateLineItemArgs = {
  input: DuplicateLineItemInput
}

export type MutationexpireCreditsArgs = {
  input: ExpireCreditsInput
}

export type MutationforgottenPasswordArgs = {
  input: ForgottenPasswordInput
}

export type MutationgenerateTrackingLinkArgs = {
  input: GenerateTrackingLinkInput
}

export type MutationgetOrCreateDraftDesignArgs = {
  input: GetOrCreateDraftDesignInput
}

export type MutationgroupCardAddMessageArgs = {
  input: GroupCardAddMessageInput
}

export type MutationgroupCardAddMessageV2Args = {
  input: GroupCardAddMessageInputV2
}

export type MutationgroupCardArchiveProjectArgs = {
  input: GroupCardArchiveProjectInput
}

export type MutationgroupCardCreateDesignArgs = {
  input: GroupCardCreateDesignInput
}

export type MutationgroupCardCreateProjectArgs = {
  input: GroupCardCreateProjectInput
}

export type MutationgroupCardCreateProjectV2Args = {
  input: GroupCardCreateProjectInputV2
}

export type MutationgroupCardDeleteMessageArgs = {
  input: GroupCardDeleteMessageInput
}

export type MutationgroupCardDeleteMessageV2Args = {
  input: GroupCardDeleteMessageInput
}

export type MutationgroupCardDeleteProjectArgs = {
  input: GroupCardDeleteProjectInput
}

export type MutationgroupCardTrackCheckoutArgs = {
  input: GroupCardTrackCheckoutInput
}

export type MutationgroupCardUpdateProjectArgs = {
  input: GroupCardUpdateProjectInput
}

export type MutationgroupCardUpdateProjectV2Args = {
  input: GroupCardUpdateProjectInputV2
}

export type MutationgroupCardUpdateProjectWithDesignArgs = {
  input: GroupCardUpdateProjectWithDesignInput
}

export type MutationregisterEmailArgs = {
  input: RegisterEmailInput
}

export type MutationremoveBasketVoucherArgs = {
  input: RemoveBasketVoucherInput
}

export type MutationremoveCustomerAddressArgs = {
  input: CustomerDeleteAddressInput
}

export type MutationremoveFromFavouritesListArgs = {
  listId?: Scalars['ID']['input']
  productIds: Array<Scalars['String']['input']>
}

export type MutationremoveMembershipFromBasketArgs = {
  input: RemoveMembershipFromBasketInput
}

export type MutationremoveSavedCardArgs = {
  input: RemoveSavedCardInput
}

export type MutationrequestOrderCancellationArgs = {
  input: RequestOrderCancellationInput
}

export type MutationrequestResendArgs = {
  input: RequestResendInput
}

export type MutationrevokeMembershipArgs = {
  email: Scalars['String']['input']
}

export type MutationselfValidateAddressArgs = {
  input: SelfValidateAddressInput
}

export type MutationsetBusinessCardCustomisationArgs = {
  input: BusinessCardCustomisationInput
}

export type MutationsetBusinessCardProductAndDesignArgs = {
  input: BusinessCardProductAndDesignInput
}

export type MutationsetBusinessRecipientsArgs = {
  input: SetBusinessRecipientsInput
}

export type MutationsetDesignCustomisationsArgs = {
  input: SetDesignCustomisationsInput
}

export type MutationsetDesignInlayArgs = {
  input: SetDesignInlayInput
}

export type MutationshareReminderArgs = {
  input: ShareReminderInput
}

export type MutationsplitLineItemQuantityArgs = {
  input: SplitLineItemQuantityInput
}

export type MutationsplitMultiQuantityCardsArgs = {
  input: SplitMultiCardsInput
}

export type MutationtrackRecipientArgs = {
  input: TrackRecipientInput
}

export type MutationunlinkReminderFromOrderArgs = {
  orderId: Scalars['ID']['input']
  reminderId: Scalars['ID']['input']
}

export type MutationupdateAddressPackagingArgs = {
  input: UpdateAddressPackagingInput
}

export type MutationupdateContactPreferencesArgs = {
  input: UpdateContactPreferencesInput
}

export type MutationupdateCustomerAddressArgs = {
  input: CustomerUpdateAddressInput
}

export type MutationupdateCustomerDetailsArgs = {
  input: UpdateCustomerDetailsInput
}

export type MutationupdateCustomerMarketingConsentPositionArgs = {
  input: UpdateCustomerMarketingConsentPositionInput
}

export type MutationupdateEmailArgs = {
  newEmail: Scalars['String']['input']
}

export type MutationupdateLineItemArgs = {
  input: UpdateLineItemInput
}

export type MutationupdateLineItemQuantityArgs = {
  input: UpdateLineItemQuantityInput
}

export type MutationupdateLineItemVariantArgs = {
  input: UpdateLineItemVariantInput
}

export type MutationupdateRecipientArgs = {
  input: UpdateRecipientInput
}

export type MutationupdateReminderArgs = {
  id: Scalars['ID']['input']
  input: UpdateReminderInput
}

export type MutationvalidateBasketArgs = {
  input: ValidateBasketInput
}

export type MutationvalidateCustomerAddressArgs = {
  input: ValidateCustomerAddressInput
}

export type ActivateRedemptionInput = {
  id: Scalars['ID']['input']
}

export type AddCardAndDigitalGiftToBasketInput = {
  cardSku: Scalars['String']['input']
  designId: Scalars['ID']['input']
  digitalGiftSku: Scalars['String']['input']
}

export type AddCardAndDigitalGiftResult =
  | Basket
  | ConcurrentModificationError
  | ProductNotFoundError

export type ConcurrentModificationError = Error & {
  __typename: 'ConcurrentModificationError'
  message: Scalars['String']['output']
}

export type ProductNotFoundError = Error & {
  __typename: 'ProductNotFoundError'
  message: Scalars['String']['output']
}

export type AddCreditsInput = {
  authCode: Scalars['String']['input']
  expiryDate?: InputMaybe<Scalars['String']['input']>
  initialBalance: Scalars['Int']['input']
  reason: Scalars['String']['input']
}

export type AddCreditsResult = {
  __typename: 'AddCreditsResult'
  accounts?: Maybe<Array<Maybe<CreditAccount>>>
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type CustomerAddressInput = {
  addressFirstLine: Scalars['String']['input']
  city?: InputMaybe<Scalars['String']['input']>
  country: Scalars['String']['input']
  extraAddressLine?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  houseNumber?: InputMaybe<Scalars['String']['input']>
  houseNumberExtension?: InputMaybe<Scalars['String']['input']>
  isMyAddress: Scalars['Boolean']['input']
  lastName: Scalars['String']['input']
  postcode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  version: Scalars['Long']['input']
}

export type AddCustomerAddressResult =
  | AddCustomerAddressInvalidCountry
  | AddCustomerAddressInvalidError
  | ConcurrentModificationError
  | Customer
  | UserInputError

export type AddCustomerAddressInvalidCountry = Error & {
  __typename: 'AddCustomerAddressInvalidCountry'
  message: Scalars['String']['output']
}

export type AddCustomerAddressInvalidError = Error & {
  __typename: 'AddCustomerAddressInvalidError'
  message: Scalars['String']['output']
}

export type UserInputError = Error & {
  __typename: 'UserInputError'
  message: Scalars['String']['output']
}

export type CustomerAddressesInput = {
  addresses: Array<CustomerMultiAddressInput>
  version: Scalars['Long']['input']
}

export type CustomerMultiAddressInput = {
  addressFirstLine: Scalars['String']['input']
  addressKey: Scalars['String']['input']
  city?: InputMaybe<Scalars['String']['input']>
  country: Scalars['String']['input']
  extraAddressLine?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  houseNumber?: InputMaybe<Scalars['String']['input']>
  houseNumberExtension?: InputMaybe<Scalars['String']['input']>
  isMyAddress: Scalars['Boolean']['input']
  lastName: Scalars['String']['input']
  postcode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export type AddCustomerAddressesResult =
  | AddCustomerAddressesInvalidError
  | AddCustomerAddressInvalidError
  | ConcurrentModificationError
  | Customer
  | UserInputError

export type AddCustomerAddressesInvalidError = Error & {
  __typename: 'AddCustomerAddressesInvalidError'
  addressErrors: Array<KeyedCustomerAddressErrorMessage>
  message: Scalars['String']['output']
}

export type KeyedCustomerAddressErrorMessage = {
  __typename: 'KeyedCustomerAddressErrorMessage'
  key: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type CustomerSelfServiceInput = {
  orderId: Scalars['ID']['input']
  orderLineItemIds: Array<Scalars['ID']['input']>
  reason: Scalars['String']['input']
  resolution: Scalars['String']['input']
}

export type CustomerSelfServiceResponse = {
  message: Scalars['String']['output']
}

export type AddDesignToBasketInput = {
  /** Defaults to [] */
  addons?: InputMaybe<Array<DesignAddonInput>>
  designId: Scalars['ID']['input']
  /** Defaults to 1 */
  quantity?: InputMaybe<Scalars['Int']['input']>
  sku: Scalars['String']['input']
}

export type DesignAddonInput = {
  sku: Scalars['String']['input']
}

export type AddDesignToBasketResult =
  | DesignAddedToBasket
  | DesignAuthorizationError
  | DesignNotFoundError
  | DesignProductNotFoundError
  | DesignServiceError
  | DesignTemplatesServiceError
  | DesignValidationError

export type DesignAddedToBasket = {
  __typename: 'DesignAddedToBasket'
  basket: Basket
  basketId: Scalars['ID']['output']
  basketTotalItems: Scalars['Int']['output']
  customerId?: Maybe<Scalars['String']['output']>
  lineItems: Array<DesignLineItem>
}

export type DesignLineItem = {
  __typename: 'DesignLineItem'
  productType: Scalars['String']['output']
  title: Scalars['String']['output']
  totalPrice: Money
}

export type DesignProductNotFoundError = Error & {
  __typename: 'DesignProductNotFoundError'
  message: Scalars['String']['output']
}

export type DesignValidationError = Error & {
  __typename: 'DesignValidationError'
  errors: Array<DesignValidationErrorError>
  message: Scalars['String']['output']
}

export type DesignValidationErrorError = DesignElementMissingRequiredError

export type DesignElementMissingRequiredError = Error & {
  __typename: 'DesignElementMissingRequiredError'
  elementId: Scalars['String']['output']
  message: Scalars['String']['output']
  sceneId: Scalars['String']['output']
  templateId: Scalars['String']['output']
}

export type AddDigitalGiftToBasketInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  parentLineItemId: Scalars['ID']['input']
  sku: Scalars['String']['input']
}

export type AddDigitalGiftToBasketResult =
  | Basket
  | BasketNotFoundError
  | LineItemNotFoundError
  | ProductNotFoundError

export type BasketNotFoundError = Error & {
  __typename: 'BasketNotFoundError'
  message: Scalars['String']['output']
}

export type LineItemNotFoundError = Error & {
  __typename: 'LineItemNotFoundError'
  message: Scalars['String']['output']
}

export type AddMembershipToBasketInput = {
  membershipType: MembershipType
}

export type AddMembershipToBasketResult =
  | Basket
  | ConcurrentModificationError
  | MembershipUnavailableError

export type MembershipUnavailableError = Error & {
  __typename: 'MembershipUnavailableError'
  message: Scalars['String']['output']
}

export type AddToBasketInput = {
  addons?: InputMaybe<Array<AddonInput>>
  designId?: InputMaybe<Scalars['ID']['input']>
  quantity: Scalars['Int']['input']
  sku: Scalars['String']['input']
  store?: InputMaybe<Store>
}

export type AddonInput = {
  sku: Scalars['String']['input']
}

export type AddToBasketResult =
  | Basket
  | ConcurrentModificationError
  | ProductNotFoundError

export type AddToBasketAndSplitInput = {
  addons?: InputMaybe<Array<AddonInput>>
  quantity: Scalars['Int']['input']
  sku: Scalars['String']['input']
}

export type AddToBasketAndSplitResult =
  | Basket
  | ConcurrentModificationError
  | ProductNotFoundError

export type AddToFavouritesList = {
  __typename: 'AddToFavouritesList'
  products: Array<Maybe<Product>>
}

export type ApplyAutoPromoInput = {
  autoPromoId: Scalars['ID']['input']
  id: Scalars['ID']['input']
}

export type ApplyAutoPromoResult =
  | ApplyAutoPromoError
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError

export type ApplyAutoPromoError = Error & {
  __typename: 'ApplyAutoPromoError'
  message: Scalars['String']['output']
}

export type ApplyPromoInput = {
  id: Scalars['ID']['input']
  promoId: Scalars['ID']['input']
}

export type ApplyPromoResult =
  | ApplyPromoError
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError

export type ApplyPromoError = Error & {
  __typename: 'ApplyPromoError'
  message: Scalars['String']['output']
}

export type AssignABTestVariantInput = {
  id: Scalars['ID']['input']
  variant: Scalars['String']['input']
  version: Scalars['Long']['input']
}

export type AssignABTestVariantResult =
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError

export type AssignBasketAddressInput = {
  addressId: Scalars['String']['input']
  id: Scalars['ID']['input']
}

export type AssignBasketAddressResult =
  | AddressNotFoundError
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError
  | DeliveryAddressInvalidError
  | DeliveryCountryInvalidError
  | DeliveryRestrictedError

export type AddressNotFoundError = Error & {
  __typename: 'AddressNotFoundError'
  message: Scalars['String']['output']
}

export type DeliveryAddressInvalidError = Error & {
  __typename: 'DeliveryAddressInvalidError'
  message: Scalars['String']['output']
}

export type DeliveryCountryInvalidError = Error & {
  __typename: 'DeliveryCountryInvalidError'
  /** @deprecated Will be removed in upcoming release, use DeliveryRestrictedError */
  message: Scalars['String']['output']
}

export type DeliveryRestrictedError = Error & {
  __typename: 'DeliveryRestrictedError'
  message: Scalars['String']['output']
  restrictions: Array<Scalars['String']['output']>
}

export type AssignBasketVoucherInput = {
  code: Scalars['String']['input']
  id: Scalars['ID']['input']
}

export type AssignBasketVoucherResult =
  | AssignBasketVoucherError
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError

export type AssignBasketVoucherError = Error & {
  __typename: 'AssignBasketVoucherError'
  message: Scalars['String']['output']
}

export type AssignBillingAddressResult = AssignBillingAddressFailed | Customer

export type AssignBillingAddressFailed = Error & {
  __typename: 'AssignBillingAddressFailed'
  message: Scalars['String']['output']
}

export type AssignDeliveryAddressInput = {
  addressId: Scalars['String']['input']
  basketId: Scalars['ID']['input']
  deliveryId: Scalars['ID']['input']
}

export type AssignDeliveryAddressResult =
  | AddressNotFoundError
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError
  | DeliveryAddressInvalidError
  | DeliveryCountryInvalidError
  | DeliveryNotFoundError
  | DeliveryRestrictedError

export type DeliveryNotFoundError = Error & {
  __typename: 'DeliveryNotFoundError'
  deliveryId: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type AssignDeliveryDateInput = {
  date: Scalars['Date']['input']
  deliveryId: Scalars['String']['input']
  id: Scalars['ID']['input']
}

export type AssignDeliveryDateResult =
  | Basket
  | BasketEmptyError
  | BasketNotFoundError
  | ConcurrentModificationError
  | DeliveryNotFoundError
  | NoLineItemsExistForDeliveryIdError

export type BasketEmptyError = Error & {
  __typename: 'BasketEmptyError'
  message: Scalars['String']['output']
}

export type NoLineItemsExistForDeliveryIdError = Error & {
  __typename: 'NoLineItemsExistForDeliveryIdError'
  message: Scalars['String']['output']
}

export type AssignDeliveryGiftNoteInput = {
  deliveryId: Scalars['String']['input']
  giftNote: Scalars['String']['input']
  id: Scalars['ID']['input']
  version: Scalars['Long']['input']
}

export type AssignDeliveryGiftNoteResult =
  | AssignDeliveryGiftNoteError
  | Basket
  | BasketEmptyError
  | BasketNotFoundError
  | ConcurrentModificationError
  | DeliveryNotFoundError
  | NoLineItemsExistForDeliveryIdError
  | UserInputError

export type AssignDeliveryGiftNoteError = Error & {
  __typename: 'AssignDeliveryGiftNoteError'
  message: Scalars['String']['output']
}

export type AssignDeliveryMethodInput = {
  deliveryId: Scalars['String']['input']
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>
  deliveryMethodId: Scalars['String']['input']
  deliveryMobile?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type AssignDeliveryMethodResult =
  | Basket
  | BasketEmptyError
  | BasketNotFoundError
  | ConcurrentModificationError
  | DeliveryNotFoundError
  | NoLineItemsExistForDeliveryIdError
  | UserInputError

export type AssignLineItemAddressInput = {
  addressId: Scalars['String']['input']
  id: Scalars['ID']['input']
  lineItemId: Scalars['ID']['input']
}

export type AssignLineItemAddressResult =
  | AddressNotFoundError
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError
  | DeliveryAddressInvalidError
  | DeliveryCountryInvalidError
  | DeliveryRestrictedError
  | LineItemNotFoundError

export type AssignLineItemEmailAddressInput = {
  email: Scalars['String']['input']
  id: Scalars['ID']['input']
  lineItemId: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type AssignLineItemEmailAddressResult =
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError
  | DeliveryAddressInvalidError
  | LineItemNotFoundError

export type MembershipFeedback = {
  canContact?: InputMaybe<Scalars['Boolean']['input']>
  questions?: InputMaybe<Array<MembershipFeedbackQuestion>>
}

export type MembershipFeedbackQuestion = {
  answer: Scalars['String']['input']
  question: Scalars['String']['input']
}

export type CancelOrderInput = {
  orderId: Scalars['String']['input']
}

export type CancelOrderResult = {
  __typename: 'CancelOrderResult'
  orderId: Scalars['ID']['output']
}

export type CheckoutBusinessProjectResponse =
  | CheckoutBusinessProjectResult
  | InputValidationError

export type CheckoutBusinessProjectResult = {
  __typename: 'CheckoutBusinessProjectResult'
  paymentSessionId: Scalars['String']['output']
}

export type CloneSharedReminderResult =
  | BadRequest
  | CreatedReminder
  | ReminderNotFound

export type CreatedReminder = Node & {
  __typename: 'CreatedReminder'
  id: Scalars['ID']['output']
}

export type CreateAudioMediaInput = {
  designToken: Scalars['String']['input']
}

export type CreateAudioMediaResult =
  | MediaInvalidTokenError
  | MediaServiceError
  | MediaUpload

export type MediaInvalidTokenError = Error & {
  __typename: 'MediaInvalidTokenError'
  message: Scalars['String']['output']
}

export type MediaUpload = {
  __typename: 'MediaUpload'
  fields: Array<MediaUploadField>
  media: Media
  url: Scalars['String']['output']
}

export type MediaUploadField = {
  __typename: 'MediaUploadField'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type CreateCustomerCreationsFontResponse =
  | CreationsCustomFont
  | CustomerCreationsServiceError

export type CustomerCreationsServiceError = Error & {
  __typename: 'CustomerCreationsServiceError'
  message: Scalars['String']['output']
}

export type CreateDemoMediaInput = {
  designToken: Scalars['String']['input']
}

export type CreateDemoMediaResult =
  | Media
  | MediaInvalidTokenError
  | MediaServiceError

export type CreateDesignInput = {
  /**
   * A set of flags to determine what the client can support.
   * Should be used in conjunction with feature flags on each client.
   */
  capabilities?: InputMaybe<Array<DesignCapability>>
  preferFlexibleLayout?: InputMaybe<Scalars['Boolean']['input']>
  /** Should use overlay text on this design. By using overlay text, templated text elements on the inside scenes of a card will be removed. */
  preferOverlayText?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Similar to `preferOverlayText` but enables overlay elements on the front of the card, removing any existing text elements.
   * There are only a select number of cards which support this. Enabling this for cards which do not support flexible content on the front will have no effect.
   */
  preferOverlayTextFront?: InputMaybe<Scalars['Boolean']['input']>
  productKey: Scalars['String']['input']
  sku?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<DesignState>
}

export type CreateDesignResult =
  | Design
  | DesignServiceError
  | DesignTemplatesServiceError
  | ProductNotPersonalisableError

export type CreateDesignImageUploadInput = {
  designId: Scalars['ID']['input']
}

export type CreateDesignImageUploadResult =
  | DesignAuthorizationError
  | DesignImageUpload
  | DesignImageUploadLimitReachedError
  | DesignNotFoundError
  | DesignServiceError

export type DesignImageUpload = {
  __typename: 'DesignImageUpload'
  fields: Array<DesignImageUploadField>
  imageId: Scalars['ID']['output']
  url: Scalars['String']['output']
}

export type DesignImageUploadField = {
  __typename: 'DesignImageUploadField'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type DesignImageUploadLimitReachedError = Error & {
  __typename: 'DesignImageUploadLimitReachedError'
  message: Scalars['String']['output']
}

export type CreateDraftFromDesignInput = {
  sourceId: Scalars['String']['input']
}

export type CreateDraftFromDesignResult =
  | Design
  | DesignAuthorizationError
  | DesignNotFoundError
  | DesignServiceError
  | DesignTemplatesServiceError

export type ReminderInput = {
  checkIfReminderExists?: InputMaybe<Scalars['Boolean']['input']>
  daysBeforeOccasionToRemind?: InputMaybe<Scalars['Int']['input']>
  name: Scalars['String']['input']
  occasion: Occasion
  occasionDate: OccasionDateInput
  orderId?: InputMaybe<Scalars['ID']['input']>
  relationship?: InputMaybe<Relationship>
  source: Source
  store?: InputMaybe<Store>
}

export type OccasionDateInput = {
  day: Scalars['Int']['input']
  month: Scalars['Int']['input']
}

export type CreateIncentivisedRemindersResult =
  | BadRequest
  | CreatedIncentivisedReminders

export type CreatedIncentivisedReminders = {
  __typename: 'CreatedIncentivisedReminders'
  reminders: Array<Reminder>
}

export type CreateRecipientInput = {
  name: Scalars['String']['input']
  nickname?: InputMaybe<Scalars['String']['input']>
  relationship?: InputMaybe<Relationship>
}

export type CreateReminderResult =
  | BadRequest
  | CreatedReminder
  | ReminderAlreadyExists

export type ReminderAlreadyExists = Error & {
  __typename: 'ReminderAlreadyExists'
  message: Scalars['String']['output']
}

export type CreateRemindersAsyncResult = {
  __typename: 'CreateRemindersAsyncResult'
  message: Scalars['String']['output']
}

export type RemindersFromProductsInput = {
  orderDate: OccasionDateInput
  orderRecipientData: Array<OrderRecipientData>
  source?: InputMaybe<Source>
}

export type OrderRecipientData = {
  productIds: Array<Scalars['String']['input']>
  recipientName: Scalars['String']['input']
}

export type CreateRemindersFromProductsResult =
  | BadRequest
  | CreatedRemindersFromProducts

export type CreatedRemindersFromProducts = {
  __typename: 'CreatedRemindersFromProducts'
  reminders: Array<CreatedReminderFromProduct>
}

export type CreatedReminderFromProduct = Node & {
  __typename: 'CreatedReminderFromProduct'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  occasion: Occasion
  occasionDate: OccasionDate
  relationship?: Maybe<Relationship>
}

export type CreateVideoMediaInput = {
  designToken: Scalars['String']['input']
  termsAndConditionsAccepted: Scalars['Boolean']['input']
}

export type CreateVideoMediaResult =
  | MediaInvalidTokenError
  | MediaServiceError
  | MediaTermsAndConditionsNotAcceptedError
  | MediaUpload

export type MediaTermsAndConditionsNotAcceptedError = Error & {
  __typename: 'MediaTermsAndConditionsNotAcceptedError'
  message: Scalars['String']['output']
}

export type CrmDeleteRemindersInput = {
  encryptedPayload: Scalars['String']['input']
}

export type CrmDeleteReminderResult = BadRequest | DeletedRemindersSuccess

export type DeletedRemindersSuccess = {
  __typename: 'DeletedRemindersSuccess'
  success: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type DeleteBasketInput = {
  id: Scalars['ID']['input']
}

export type DeleteBasketResult =
  | BasketNotFoundError
  | ConcurrentModificationError
  | DeleteBasketSuccessResult

export type DeleteBasketSuccessResult = {
  __typename: 'DeleteBasketSuccessResult'
  id: Scalars['ID']['output']
}

export type DeleteBusinessProjectResponse =
  | DeleteBusinessProjectOutput
  | InputValidationError

export type DeleteBusinessProjectOutput = {
  __typename: 'DeleteBusinessProjectOutput'
  success: Scalars['Boolean']['output']
}

export type DeleteCustomerCreationsFontResponse =
  | CustomerCreationsServiceError
  | DeleteCustomerCreationsFont

export type DeleteCustomerCreationsFont = {
  __typename: 'DeleteCustomerCreationsFont'
  id: Scalars['ID']['output']
}

export type DeleteDraftDesignInput = {
  id: Scalars['String']['input']
}

export type DeleteDraftDesignResult =
  | DesignAuthorizationError
  | DesignDeleted
  | DesignNotDraftError
  | DesignNotFoundError
  | DesignServiceError

export type DesignDeleted = {
  __typename: 'DesignDeleted'
  id: Scalars['ID']['output']
}

export type DesignNotDraftError = Error & {
  __typename: 'DesignNotDraftError'
  message: Scalars['String']['output']
}

export type DeleteLineItemInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  lineItemId: Scalars['ID']['input']
}

export type DeleteLineItemResult =
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError

export type DeleteReminderResult = DeletedReminder | ReminderNotFound

export type DeletedReminder = Node & {
  __typename: 'DeletedReminder'
  id: Scalars['ID']['output']
}

export type DuplicateLineItemInput = {
  id: Scalars['ID']['input']
  lineItemId: Scalars['ID']['input']
}

export type DuplicateLineItemResult =
  | BasketNotFoundError
  | ConcurrentModificationError
  | LineItemDuplicatedBasketResult
  | LineItemDuplicateRestrictedError
  | LineItemNotFoundError
  | ProductNotFoundError

export type LineItemDuplicatedBasketResult = {
  __typename: 'LineItemDuplicatedBasketResult'
  basket: Basket
  lineItem: LineItem
}

export type LineItemDuplicateRestrictedError = Error & {
  __typename: 'LineItemDuplicateRestrictedError'
  message: Scalars['String']['output']
}

export type ExpireCreditsInput = {
  authCode: Scalars['String']['input']
  creditId: Scalars['String']['input']
}

export type ExpireCreditsResult = {
  __typename: 'ExpireCreditsResult'
  accounts?: Maybe<Array<Maybe<CreditAccount>>>
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type ForgottenPasswordInput = {
  email: Scalars['String']['input']
  region?: InputMaybe<Region>
}

export type ForgottenPasswordResult =
  | ForgottenPasswordInvalidEmail
  | ForgottenPasswordInvalidStore
  | ForgottenPasswordSent

export type ForgottenPasswordInvalidEmail = Error & {
  __typename: 'ForgottenPasswordInvalidEmail'
  message: Scalars['String']['output']
}

export type ForgottenPasswordInvalidStore = Error & {
  __typename: 'ForgottenPasswordInvalidStore'
  message: Scalars['String']['output']
}

export type ForgottenPasswordSent = {
  __typename: 'ForgottenPasswordSent'
  message: Scalars['String']['output']
}

export type GenerateTrackingLinkInput = {
  deliveryId: Scalars['String']['input']
  orderId: Scalars['ID']['input']
}

export type GenerateTrackingLinkResponse =
  | GenerateTrackingLinkFailed
  | GenerateTrackingLinkSuccess

export type GenerateTrackingLinkFailed = Error & {
  __typename: 'GenerateTrackingLinkFailed'
  message: Scalars['String']['output']
}

export type GenerateTrackingLinkSuccess = {
  __typename: 'GenerateTrackingLinkSuccess'
  trackingLink: Scalars['String']['output']
}

export type GetOrCreateDraftDesignInput = {
  /**
   * A set of flags to determine what the client can support.
   * Should be used in conjunction with feature flags on each client.
   */
  capabilities?: InputMaybe<Array<DesignCapability>>
  preferFlexibleLayout?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Should use overlay text on this design when creating a new design.
   * If an existing design is found, the overlay preference is not changed, i.e. if it is false, it will remain false.
   * By using overlay text, templated text elements on the inside scenes of a card will be removed.
   */
  preferOverlayText?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Similar to `preferOverlayText` but enables overlay elements on the front of the card, removing any existing text elements.
   * There are only a select number of cards which support this. Enabling this for cards which do not support flexible content on the front will have no effect.
   */
  preferOverlayTextFront?: InputMaybe<Scalars['Boolean']['input']>
  productKey: Scalars['String']['input']
  sku?: InputMaybe<Scalars['String']['input']>
}

export type GetOrCreateDraftDesignResult =
  | Design
  | DesignServiceError
  | DesignTemplatesServiceError
  | ProductNotPersonalisableError

export type GroupCardAddMessageInput = {
  addMessageToken: Scalars['String']['input']
  authorName: Scalars['String']['input']
  message: Scalars['String']['input']
}

export type GroupAddMessageResult =
  | GroupCardAuthenticationError
  | GroupCardInputInvalidError
  | GroupCardMessage
  | GroupCardMessagesLimitReachedError
  | GroupCardServiceError

export type GroupCardAuthenticationError = GroupCardError & {
  __typename: 'GroupCardAuthenticationError'
  message: Scalars['String']['output']
}

export type GroupCardInputInvalidError = GroupCardError & {
  __typename: 'GroupCardInputInvalidError'
  message: Scalars['String']['output']
}

export type GroupCardMessagesLimitReachedError = GroupCardError & {
  __typename: 'GroupCardMessagesLimitReachedError'
  message: Scalars['String']['output']
}

export type GroupCardAddMessageInputV2 = {
  addMessageToken: Scalars['String']['input']
  authorName: Scalars['String']['input']
  font?: InputMaybe<GroupCardFontInput>
  message: Scalars['String']['input']
}

export type GroupCardFontInput = {
  colorId: Scalars['String']['input']
  fontFaceId: Scalars['String']['input']
}

export type GroupAddMessageResultV2 =
  | GroupCardAuthenticatedMessageV2
  | GroupCardAuthenticationError
  | GroupCardInputInvalidError
  | GroupCardMessagesExceedCapacityError
  | GroupCardMessagesLimitReachedError
  | GroupCardMessageV2
  | GroupCardServiceError
  | GroupCardUnavailableError

export type GroupCardAuthenticatedMessageV2 = {
  __typename: 'GroupCardAuthenticatedMessageV2'
  authorName: Scalars['String']['output']
  colorId: Scalars['Int']['output']
  font: GroupCardFont
  fontFaceId: Scalars['Int']['output']
  message: Scalars['String']['output']
  messageId: Scalars['ID']['output']
  productId: Scalars['String']['output']
  projectId: Scalars['ID']['output']
  scenes: Array<GroupCardsScene>
}

export type GroupCardAuthenticatedMessageV2scenesArgs = {
  pages: Scalars['Int']['input']
}

export type GroupCardMessagesExceedCapacityError = GroupCardError & {
  __typename: 'GroupCardMessagesExceedCapacityError'
  message: Scalars['String']['output']
}

export type GroupCardUnavailableError = GroupCardError & {
  __typename: 'GroupCardUnavailableError'
  message: Scalars['String']['output']
}

export type GroupCardArchiveProjectInput = {
  projectId: Scalars['ID']['input']
}

export type GroupCardArchiveProjectResult =
  | GroupCardNotFoundError
  | GroupCardServiceError
  | GroupCardSuccess

export type GroupCardSuccess = {
  __typename: 'GroupCardSuccess'
  id: Scalars['ID']['output']
}

export type GroupCardCreateDesignInput = {
  projectId: Scalars['ID']['input']
}

export type GroupCardCreateDesignResult =
  | GroupCardNotFoundError
  | GroupCardServiceError
  | GroupCardSuccess

export type GroupCardCreateProjectInput = {
  deadlineTime?: InputMaybe<Scalars['String']['input']>
  occasion?: InputMaybe<Occasion>
  productId?: InputMaybe<Scalars['String']['input']>
  productVariantSku?: InputMaybe<Scalars['String']['input']>
  projectName: Scalars['String']['input']
  recipient?: InputMaybe<Scalars['String']['input']>
}

export type GroupCardCreateProjectResult =
  | GroupCardInputInvalidError
  | GroupCardProject
  | GroupCardProjectsLimitReachedError
  | GroupCardServiceError

export type GroupCardProjectsLimitReachedError = GroupCardError & {
  __typename: 'GroupCardProjectsLimitReachedError'
  message: Scalars['String']['output']
}

export type GroupCardCreateProjectInputV2 = {
  deadlineTime?: InputMaybe<Scalars['String']['input']>
  occasion?: InputMaybe<Occasion>
  productId: Scalars['String']['input']
  productVariantSku: Scalars['String']['input']
  projectName: Scalars['String']['input']
  recipient: Scalars['String']['input']
}

export type GroupCardCreateProjectResultV2 =
  | GroupCardInputInvalidError
  | GroupCardProjectV2
  | GroupCardServiceError

export type GroupCardDeleteMessageInput = {
  messageId: Scalars['ID']['input']
  projectId: Scalars['ID']['input']
}

export type GroupDeleteMessageResult =
  | GroupCardAuthenticationError
  | GroupCardServiceError
  | GroupCardSuccess

export type GroupDeleteMessageResultV2 =
  | GroupCardAuthenticationError
  | GroupCardNotFoundError
  | GroupCardServiceError
  | GroupCardSuccess

export type GroupCardDeleteProjectInput = {
  projectId: Scalars['ID']['input']
}

export type GroupCardDeleteProjectResult =
  | GroupCardServiceError
  | GroupCardSuccess

export type GroupCardTrackCheckoutInput = {
  projectId: Scalars['ID']['input']
}

export type GroupCardTrackCheckoutResult =
  | GroupCardNotFoundError
  | GroupCardServiceError
  | GroupCardSuccess

export type GroupCardTrackSignupEventResult =
  | GroupCardServiceError
  | GroupCardSuccess

export type GroupCardUpdateProjectInput = {
  deadlineTime?: InputMaybe<Scalars['String']['input']>
  occasion?: InputMaybe<Occasion>
  productId?: InputMaybe<Scalars['String']['input']>
  productVariantSku?: InputMaybe<Scalars['String']['input']>
  projectId: Scalars['ID']['input']
  projectName: Scalars['String']['input']
  recipient?: InputMaybe<Scalars['String']['input']>
}

export type GroupCardUpdateProjectResult =
  | GroupCardInputInvalidError
  | GroupCardNotFoundError
  | GroupCardServiceError
  | GroupCardUpdatedProject

export type GroupCardUpdatedProject = {
  __typename: 'GroupCardUpdatedProject'
  addMessageToken: Scalars['String']['output']
  createdTime: Scalars['String']['output']
  deadlineTime?: Maybe<Scalars['String']['output']>
  occasion?: Maybe<Occasion>
  productId?: Maybe<Scalars['String']['output']>
  productVariantSku?: Maybe<Scalars['String']['output']>
  projectId: Scalars['ID']['output']
  projectName: Scalars['String']['output']
  recipient?: Maybe<Scalars['String']['output']>
}

export type GroupCardUpdateProjectInputV2 = {
  deadlineTime?: InputMaybe<Scalars['String']['input']>
  occasion?: InputMaybe<Occasion>
  productId: Scalars['String']['input']
  productVariantSku: Scalars['String']['input']
  projectId: Scalars['ID']['input']
  projectName: Scalars['String']['input']
  recipient: Scalars['String']['input']
}

export type GroupCardUpdateProjectResultV2 =
  | GroupCardInputInvalidError
  | GroupCardNotFoundError
  | GroupCardProjectV2
  | GroupCardServiceError
  | GroupCardUnavailableError

export type GroupCardUpdateProjectWithDesignInput = {
  designId: Scalars['String']['input']
  projectId: Scalars['ID']['input']
}

export type GroupCardUpdateProjectWithDesignResult =
  | GroupCardServiceError
  | GroupCardSuccess

export type RegisterEmailInput = {
  emailAddress: Scalars['String']['input']
}

export type RegisterEmailResult = InvalidEmailError | RegisterEmailSuccess

export type InvalidEmailError = {
  __typename: 'InvalidEmailError'
  emailAddress: Scalars['String']['output']
}

export type RegisterEmailSuccess = {
  __typename: 'RegisterEmailSuccess'
  emailAddress: Scalars['String']['output']
}

export type RemoveBasketVoucherInput = {
  id: Scalars['ID']['input']
}

export type RemoveBasketVoucherResult =
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError

export type CustomerDeleteAddressInput = {
  addressId: Scalars['String']['input']
  version: Scalars['Long']['input']
}

export type RemoveCustomerAddressResult =
  | AddressNotFoundError
  | ConcurrentModificationError
  | Customer

export type RemoveFromFavouritesList = {
  __typename: 'RemoveFromFavouritesList'
  products: Array<Maybe<Product>>
}

export type RemoveMembershipFromBasketInput = {
  id: Scalars['ID']['input']
  version: Scalars['Long']['input']
}

export type RemoveMembershipFromBasketResult =
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError
  | UserInputError

export type RemoveSavedCardInput = {
  paymentMethodId: Scalars['String']['input']
}

export type RemoveSavedCardResult =
  | RemoveSavedCardError
  | RemoveSavedCardSuccess

export type RemoveSavedCardError = {
  __typename: 'RemoveSavedCardError'
  message: Scalars['String']['output']
}

export type RemoveSavedCardSuccess = {
  __typename: 'RemoveSavedCardSuccess'
  messageId: Scalars['String']['output']
}

export type RequestOrderCancellationInput = {
  customerId?: InputMaybe<Scalars['String']['input']>
  deliveries?: InputMaybe<Array<Scalars['String']['input']>>
  orderId: Scalars['String']['input']
}

export type RequestOrderCancellationResult =
  | OrderCancellationError
  | OrderCancellationSummary

export type OrderCancellationError = {
  __typename: 'OrderCancellationError'
  errorDetails: Scalars['String']['output']
  orderId: Scalars['ID']['output']
}

export type OrderCancellationSummary = {
  __typename: 'OrderCancellationSummary'
  customerId: Scalars['ID']['output']
  deliveries?: Maybe<Array<Scalars['String']['output']>>
  orderId: Scalars['ID']['output']
}

export type RequestResendInput = {
  customerId: Scalars['String']['input']
  orderId: Scalars['String']['input']
}

export type RequestResendResult = {
  message: Scalars['String']['output']
}

export type RevokeMembershipResponse = {
  __typename: 'RevokeMembershipResponse'
  success: Scalars['Boolean']['output']
}

export type SelfValidateAddressInput = {
  id: Scalars['String']['input']
}

export type SelfValidateAddressResult =
  | ConcurrentModificationError
  | SelfValidatedAddressResult

export type SelfValidatedAddressResult = {
  __typename: 'SelfValidatedAddressResult'
  address: ShippingAddress
}

export type BusinessCardCustomisationInput = {
  greeting: Scalars['String']['input']
  message: Scalars['String']['input']
  nameFormat: BusinessNameFormat
}

export type BusinessCardProductAndDesignInput = {
  designId: Scalars['String']['input']
  productKey: Scalars['String']['input']
  sku: Scalars['String']['input']
}

export type SetBusinessRecipientsInput = {
  recipients: Array<BusinessRecipientInput>
}

export type BusinessRecipientInput = {
  addressLine1: Scalars['String']['input']
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  deliveryDate?: InputMaybe<Scalars['Date']['input']>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  postcode: Scalars['String']['input']
}

export type SetDesignCustomisationsInput = {
  designId: Scalars['ID']['input']
  scenes: Array<DesignCustomisationsSceneInput>
}

export type DesignCustomisationsSceneInput = {
  customisations: DesignCustomisationsInput
  id: Scalars['ID']['input']
}

export type DesignCustomisationsInput = {
  groupMessages?: InputMaybe<DesignCustomisationsGroupMessagesInput>
  handwriting?: InputMaybe<DesignCustomisationsHandwritingInput>
  imageUpload?: InputMaybe<DesignCustomisationsImageUploadInput>
  layout?: InputMaybe<DesignCustomisationsLayoutInput>
  overlay?: InputMaybe<DesignCustomisationsOverlayInput>
  template?: InputMaybe<DesignCustomisationsTemplateInput>
}

export type DesignCustomisationsGroupMessagesInput = {
  image: DesignImageUploadedInput
  sourceImage?: InputMaybe<DesignImageSourceInput>
}

export type DesignImageUploadedInput = {
  id: Scalars['ID']['input']
}

export type DesignImageSourceInput = {
  id: Scalars['ID']['input']
  transform: DesignTransformInput
}

export type DesignTransformInput = {
  position: DesignTransformPositionInput
  rotation: Scalars['Float']['input']
  scale: Scalars['Float']['input']
}

export type DesignTransformPositionInput = {
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type DesignCustomisationsHandwritingInput = {
  image: DesignImageUploadedInput
  sourceImage?: InputMaybe<DesignImageSourceInput>
}

export type DesignCustomisationsImageUploadInput = {
  image: DesignImageUploadedInput
  sourceImage?: InputMaybe<DesignImageSourceInput>
}

export type DesignCustomisationsLayoutInput = {
  grid: DesignCollageGridInput
}

export type DesignCollageGridInput = {
  background?: InputMaybe<DesignCollageGridBackgroundInput>
  border?: InputMaybe<DesignCollageGridBorderInput>
  cells: Array<DesignCollageGridCellInput>
  gutters: Array<DesignCollageGridGutterInput>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type DesignCollageGridBackgroundInput = {
  color: Scalars['String']['input']
}

export type DesignCollageGridBorderInput = {
  bottom: Scalars['Float']['input']
  inner: Scalars['Float']['input']
  left: Scalars['Float']['input']
  right: Scalars['Float']['input']
  top: Scalars['Float']['input']
}

export type DesignCollageGridCellInput = {
  anchors: DesignCollageGridCellAnchorsInput
  content: DesignCollageGridCellContentInput
  id?: InputMaybe<Scalars['ID']['input']>
}

export type DesignCollageGridCellAnchorsInput = {
  bottom?: InputMaybe<Scalars['ID']['input']>
  left?: InputMaybe<Scalars['ID']['input']>
  right?: InputMaybe<Scalars['ID']['input']>
  top?: InputMaybe<Scalars['ID']['input']>
}

export type DesignCollageGridCellContentInput = {
  image?: InputMaybe<DesignCollageGridCellContentImageInput>
  placeholder?: InputMaybe<DesignCollageGridCellContentPlaceholderInput>
}

export type DesignCollageGridCellContentImageInput = {
  sourceImage: DesignCollageGridCellSourceImageInput
}

export type DesignCollageGridCellSourceImageInput = {
  id: Scalars['ID']['input']
  offsetX: Scalars['Float']['input']
  offsetY: Scalars['Float']['input']
  scale: Scalars['Float']['input']
}

export type DesignCollageGridCellContentPlaceholderInput = {
  kind: DesignCollageGridCellContentPlaceholderKind
  placedId?: InputMaybe<Scalars['ID']['input']>
}

export type DesignCollageGridGutterInput = {
  anchors: DesignCollageGridGutterAnchorsInput
  id: Scalars['ID']['input']
  offset: Scalars['Float']['input']
  orientation: DesignCollageGridGutterOrientation
}

export type DesignCollageGridGutterAnchorsInput = {
  end?: InputMaybe<Scalars['ID']['input']>
  start?: InputMaybe<Scalars['ID']['input']>
}

export type DesignCustomisationsOverlayInput = {
  image?: InputMaybe<Array<DesignOverlayImageInput>>
  inlay?: InputMaybe<Array<DesignOverlayInlayInput>>
  media?: InputMaybe<Array<DesignOverlayMediaInput>>
  sticker?: InputMaybe<Array<DesignOverlayStickerInput>>
  text?: InputMaybe<Array<DesignOverlayTextInput>>
}

export type DesignOverlayImageInput = {
  content: DesignOverlayImageContentInput
  height: Scalars['Float']['input']
  index: Scalars['Int']['input']
  rotation: Scalars['Float']['input']
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type DesignOverlayImageContentInput = {
  editedImage: DesignImageUploadedInput
  fixed?: InputMaybe<Scalars['Boolean']['input']>
  imageKind?: InputMaybe<DesignOverlayImageKind>
  sourceImage?: InputMaybe<DesignImageSourceInput>
}

export type DesignOverlayInlayInput = {
  content: DesignOverlayInlayContentInput
  height: Scalars['Float']['input']
  index: Scalars['Int']['input']
  rotation: Scalars['Float']['input']
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type DesignOverlayInlayContentInput = {
  inlayId: Scalars['ID']['input']
}

export type DesignOverlayMediaInput = {
  content: DesignOverlayMediaContentInput
  height: Scalars['Float']['input']
  index: Scalars['Int']['input']
  rotation: Scalars['Float']['input']
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type DesignOverlayMediaContentInput = {
  kind?: InputMaybe<DesignMediaKind>
  mediaToken: Scalars['String']['input']
}

export type DesignOverlayStickerInput = {
  content: DesignOverlayStickerContentInput
  height: Scalars['Float']['input']
  index: Scalars['Int']['input']
  rotation: Scalars['Float']['input']
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type DesignOverlayStickerContentInput = {
  stickerId: Scalars['ID']['input']
}

export type DesignOverlayTextInput = {
  content: DesignOverlayTextContentInput
  height: Scalars['Float']['input']
  index: Scalars['Int']['input']
  rotation: Scalars['Float']['input']
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type DesignOverlayTextContentInput = {
  /**
   * Author of the customisation as determined by the client.
   *
   * Currently optional until client consistency is reached.
   */
  author?: InputMaybe<DesignTextAuthor>
  colorId: Scalars['ID']['input']
  /** The text box is not intended to be moved, rotated, or resized by the user. */
  fixed?: InputMaybe<Scalars['Boolean']['input']>
  fontFaceId: Scalars['ID']['input']
  fontSize: Scalars['Float']['input']
  fragments?: InputMaybe<Array<DesignTextFragmentInput>>
  horizontalAlignment: DesignHorizontalAlignment
  substitutions?: InputMaybe<Array<Scalars['String']['input']>>
  text: Scalars['String']['input']
  verticalAlignment: DesignVerticalAlignment
}

export type DesignTextFragmentInput = {
  fontSize: Scalars['Float']['input']
  text: Scalars['String']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type DesignCustomisationsTemplateInput = {
  imageCollage?: InputMaybe<Array<DesignCustomisationElementImageCollageInput>>
  imageDynamic?: InputMaybe<Array<DesignCustomisationElementImageDynamicInput>>
  imageUpload: Array<DesignCustomisationElementImageUploadInput>
  textPlaceholder: Array<DesignCustomisationElementTextPlaceholderInput>
  textPlain: Array<DesignCustomisationElementTextPlainInput>
  textStyled: Array<DesignCustomisationElementTextStyledInput>
}

export type DesignCustomisationElementImageCollageInput = {
  editedImage: DesignImageUploadedInput
  elementId: Scalars['ID']['input']
  grid?: InputMaybe<DesignCollageGridInput>
}

export type DesignCustomisationElementImageDynamicInput = {
  elementId: Scalars['ID']['input']
  textParts: Array<Scalars['String']['input']>
}

export type DesignCustomisationElementImageUploadInput = {
  editedImage: DesignImageUploadedInput
  elementId: Scalars['ID']['input']
  sourceImage?: InputMaybe<DesignImageSourceInput>
}

export type DesignCustomisationElementTextPlaceholderInput = {
  elementId: Scalars['ID']['input']
  textParts: Array<Scalars['String']['input']>
}

export type DesignCustomisationElementTextPlainInput = {
  elementId: Scalars['ID']['input']
  text: Scalars['String']['input']
}

export type DesignCustomisationElementTextStyledInput = {
  colorId: Scalars['String']['input']
  elementId: Scalars['ID']['input']
  fontFaceId: Scalars['String']['input']
  fontSize: Scalars['Float']['input']
  horizontalAlignment: DesignHorizontalAlignment
  text: Scalars['String']['input']
  verticalAlignment: DesignVerticalAlignment
}

export type SetDesignCustomisationsResult =
  | Design
  | DesignAuthorizationError
  | DesignCustomisationsInputError
  | DesignInvalidTokenError
  | DesignNotFoundError
  | DesignServiceError
  | DesignStateError
  | DesignTemplatesServiceError

export type DesignCustomisationsInputError = Error & {
  __typename: 'DesignCustomisationsInputError'
  errors: Array<DesignInputError>
  message: Scalars['String']['output']
}

export type DesignInputError =
  | DesignElementMissingRequiredError
  | DesignInputDesignError
  | DesignInputInvalidElementError
  | DesignInputProfanityError
  | DesignInputSceneError
  | DesignInputSceneNotFoundError
  | DesignInputTemplateElementNotFoundError

export type DesignInputDesignError = Error & {
  __typename: 'DesignInputDesignError'
  message: Scalars['String']['output']
}

export type DesignInputInvalidElementError = Error & {
  __typename: 'DesignInputInvalidElementError'
  inputElementId: Scalars['String']['output']
  message: Scalars['String']['output']
  sceneId: Scalars['String']['output']
  templateId: Scalars['String']['output']
  type?: Maybe<Scalars['String']['output']>
}

export type DesignInputProfanityError = Error & {
  __typename: 'DesignInputProfanityError'
  inputElementId: Scalars['String']['output']
  message: Scalars['String']['output']
  sceneId: Scalars['String']['output']
  templateId: Scalars['String']['output']
  type?: Maybe<Scalars['String']['output']>
}

export type DesignInputSceneError = Error & {
  __typename: 'DesignInputSceneError'
  inputSceneId: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type DesignInputSceneNotFoundError = Error & {
  __typename: 'DesignInputSceneNotFoundError'
  inputSceneId: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type DesignInputTemplateElementNotFoundError = Error & {
  __typename: 'DesignInputTemplateElementNotFoundError'
  inputElementId: Scalars['String']['output']
  message: Scalars['String']['output']
  sceneId: Scalars['String']['output']
  templateId: Scalars['String']['output']
}

export type DesignInvalidTokenError = Error & {
  __typename: 'DesignInvalidTokenError'
  message: Scalars['String']['output']
}

export type DesignStateError = Error & {
  __typename: 'DesignStateError'
  message: Scalars['String']['output']
}

export type SetDesignInlayInput = {
  designId: Scalars['String']['input']
  inlayId: Scalars['String']['input']
  metadata: DesignInlayMetadata
}

export type DesignInlayMetadata = {
  lineItemId: Scalars['String']['input']
  sku: Scalars['String']['input']
}

export type SetDesignInlayResult =
  | Design
  | DesignAuthorizationError
  | DesignNotFoundError
  | DesignServiceError
  | DesignSetInlayError
  | DesignStateError
  | DesignTemplatesServiceError

export type DesignSetInlayError = Error & {
  __typename: 'DesignSetInlayError'
  message: Scalars['String']['output']
}

export type ShareReminderInput = {
  id: Scalars['String']['input']
  store?: InputMaybe<Store>
}

export type ShareReminderResult = ReminderNotFound | ShareReminder

export type ShareReminder = {
  __typename: 'ShareReminder'
  url: Scalars['String']['output']
}

export type SplitLineItemQuantityInput = {
  lineItemId: Scalars['ID']['input']
}

export type SplitLineItemQuantityResult =
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError
  | LineItemNotFoundError

export type SplitMultiCardsInput = {
  id: Scalars['ID']['input']
}

export type SplitMultiCardsResult = Basket

export type TrackRecipientInput = {
  trackingId: Scalars['ID']['input']
}

export type TrackRecipientResult = {
  message: Scalars['String']['output']
}

export type UnlinkReminderFromOrderResult = Reminder | ReminderNotFound

export type UpdateAddressPackagingInput = {
  addressId: Scalars['String']['input']
  id: Scalars['ID']['input']
  requiresPackaging: Scalars['Boolean']['input']
}

export type UpdateAddressPackagingResult =
  | AddressNotFoundError
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError
  | DeliveryAddressInvalidError
  | DeliveryCountryInvalidError
  | DeliveryRestrictedError

export type UpdateContactPreferencesInput = {
  preferences: Array<ContactPreferenceInput>
  source: ContactSource
}

export type ContactPreferenceInput = {
  status: ContactStatus
  type: ContactPreferenceType
}

export type CustomerUpdateAddressInput = {
  addressFirstLine: Scalars['String']['input']
  addressId: Scalars['String']['input']
  city?: InputMaybe<Scalars['String']['input']>
  country: Scalars['String']['input']
  extraAddressLine?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  houseNumber?: InputMaybe<Scalars['String']['input']>
  houseNumberExtension?: InputMaybe<Scalars['String']['input']>
  isMyAddress: Scalars['Boolean']['input']
  lastName: Scalars['String']['input']
  postcode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCustomerAddressResult =
  | ConcurrentModificationError
  | Customer
  | CustomerAddressInvalidCountry
  | CustomerAddressInvalidError
  | UserInputError

export type CustomerAddressInvalidCountry = Error & {
  __typename: 'CustomerAddressInvalidCountry'
  message: Scalars['String']['output']
}

export type CustomerAddressInvalidError = Error & {
  __typename: 'CustomerAddressInvalidError'
  message: Scalars['String']['output']
}

export type UpdateCustomerDetailsInput = {
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  title?: InputMaybe<Scalars['String']['input']>
  version: Scalars['Long']['input']
}

export type UpdateCustomerDetailsResult =
  | ConcurrentModificationError
  | Customer
  | UserInputError

export type UpdateCustomerMarketingConsentPositionInput = {
  position: ConsentPosition
  source: MarketingConsentSource
}

export enum MarketingConsentSource {
  APP = 'APP',
  APPCHECKOUT = 'APPCHECKOUT',
  CUSTOMER_SERVICES = 'CUSTOMER_SERVICES',
  EMAIL_UNSUBSCRIBE = 'EMAIL_UNSUBSCRIBE',
  GROUP_CARDS_SIGN_UP = 'GROUP_CARDS_SIGN_UP',
  LANDING_PAGE = 'LANDING_PAGE',
  MIGRATION = 'MIGRATION',
  MY_ACCOUNT_PAGE = 'MY_ACCOUNT_PAGE',
  REMINDERS_INFLOW_PAGE = 'REMINDERS_INFLOW_PAGE',
  WEB_REGISTER = 'WEB_REGISTER',
  WEBCHECKOUT = 'WEBCHECKOUT',
}

export type UpdateCustomerMarketingConsentPositionResult = Customer

export type UpdateEmailResult =
  | Customer
  | UpdateEmailExistingEmail
  | UpdateEmailFailed
  | UpdateEmailInvalidEmail

export type UpdateEmailExistingEmail = Error & {
  __typename: 'UpdateEmailExistingEmail'
  message: Scalars['String']['output']
}

export type UpdateEmailFailed = Error & {
  __typename: 'UpdateEmailFailed'
  message: Scalars['String']['output']
}

export type UpdateEmailInvalidEmail = Error & {
  __typename: 'UpdateEmailInvalidEmail'
  message: Scalars['String']['output']
}

export type UpdateLineItemInput = {
  id: Scalars['ID']['input']
  lineItemId: Scalars['ID']['input']
  packagingSku?: InputMaybe<Scalars['String']['input']>
  quantity?: InputMaybe<Scalars['Int']['input']>
  variantSku?: InputMaybe<Scalars['String']['input']>
}

export type UpdateLineItemResult =
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError
  | DeliveryRestrictedError
  | ProductNotFoundError
  | UserInputError

export type UpdateLineItemQuantityInput = {
  id: Scalars['ID']['input']
  lineItemId: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
  version: Scalars['Long']['input']
}

export type UpdateLineItemQuantityResult =
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError

export type UpdateLineItemVariantInput = {
  id: Scalars['ID']['input']
  lineItemId: Scalars['ID']['input']
  sku: Scalars['String']['input']
  variantId?: InputMaybe<Scalars['Int']['input']>
  version: Scalars['Long']['input']
}

export type UpdateLineItemVariantResult =
  | Basket
  | BasketNotFoundError
  | ConcurrentModificationError
  | DeliveryRestrictedError
  | ProductNotFoundError
  | UserInputError

export type UpdateRecipientInput = {
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  nickname?: InputMaybe<Scalars['String']['input']>
  relationship?: InputMaybe<Relationship>
}

export type UpdateReminderInput = {
  name?: InputMaybe<Scalars['String']['input']>
  occasion?: InputMaybe<Occasion>
  occasionDate?: InputMaybe<OccasionDateInput>
  relationship?: InputMaybe<Relationship>
  store?: InputMaybe<Store>
}

export type UpdateReminderResult =
  | BadRequest
  | ReminderNotFound
  | UpdatedReminder

export type UpdatedReminder = Node & {
  __typename: 'UpdatedReminder'
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  occasion?: Maybe<Occasion>
  occasionDate?: Maybe<OccasionDate>
  relationship?: Maybe<Relationship>
  store?: Maybe<Store>
}

export type ValidateBasketInput = {
  id: Scalars['ID']['input']
}

export type ValidateBasketResult =
  | BasketNotFoundError
  | BasketOrderedError
  | BasketValidatedResult
  | ConcurrentModificationError

export type BasketOrderedError = Error & {
  __typename: 'BasketOrderedError'
  message: Scalars['String']['output']
}

export type BasketValidatedResult = {
  __typename: 'BasketValidatedResult'
  basket: Basket
  /** @deprecated Will be removed in an upcoming release */
  invalidDeliveryMethod?: Maybe<InvalidDeliveryMethod>
  /** @deprecated Will be removed in an upcoming release */
  invalidVoucherMessage?: Maybe<Scalars['String']['output']>
  validations: Array<BasketValidationResult>
}

export type InvalidDeliveryMethod = {
  __typename: 'InvalidDeliveryMethod'
  description: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type BasketValidationResult =
  | DeliveryValidation
  | PromoValidation
  | StockValidation
  | VoucherValidation

export type DeliveryValidation = BasketValidation & {
  __typename: 'DeliveryValidation'
  isBlocking: Scalars['Boolean']['output']
  message: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type BasketValidation = {
  isBlocking: Scalars['Boolean']['output']
  message: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type PromoValidation = BasketValidation & {
  __typename: 'PromoValidation'
  isBlocking: Scalars['Boolean']['output']
  message: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type StockValidation = BasketValidation & {
  __typename: 'StockValidation'
  isBlocking: Scalars['Boolean']['output']
  message: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type VoucherValidation = BasketValidation & {
  __typename: 'VoucherValidation'
  isBlocking: Scalars['Boolean']['output']
  message: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ValidateCustomerAddressInput = {
  id: Scalars['String']['input']
}

export type ValidateCustomerAddressResult =
  | ConcurrentModificationError
  | InvalidCustomerAddressResult
  | SuggestedCustomerAddressResult
  | ValidCustomerAddressResult

export type InvalidCustomerAddressResult = {
  __typename: 'InvalidCustomerAddressResult'
  address: ShippingAddress
}

export type SuggestedCustomerAddressResult = {
  __typename: 'SuggestedCustomerAddressResult'
  address: SuggestedAddress
  original: Array<SuggestedCustomerAddressLine>
  suggested: Array<SuggestedCustomerAddressLine>
}

export type SuggestedAddress = {
  __typename: 'SuggestedAddress'
  addressFirstLine: Scalars['String']['output']
  addressId: Scalars['String']['output']
  city?: Maybe<Scalars['String']['output']>
  country: Scalars['String']['output']
  extraAddressLine?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  houseNumber?: Maybe<Scalars['String']['output']>
  houseNumberExtension?: Maybe<Scalars['String']['output']>
  isMyAddress: Scalars['Boolean']['output']
  lastName: Scalars['String']['output']
  postcode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
}

export type SuggestedCustomerAddressLine = {
  __typename: 'SuggestedCustomerAddressLine'
  hasChanges: Scalars['Boolean']['output']
  value: Scalars['String']['output']
}

export type ValidCustomerAddressResult = {
  __typename: 'ValidCustomerAddressResult'
  address: ShippingAddress
}

export type AddressInvalidErrors = Errors & {
  __typename: 'AddressInvalidErrors'
  id: Scalars['String']['output']
  messages: Array<Scalars['String']['output']>
}

export type Errors = {
  messages: Array<Scalars['String']['output']>
}

export type BusinessAddress = {
  addressFirstLine: Scalars['String']['input']
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  postcode: Scalars['String']['input']
}

export type ContentSearchFacet = {
  __typename: 'ContentSearchFacet'
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type CustomerInformation = {
  __typename: 'CustomerInformation'
  email: Scalars['String']['output']
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
}

export type CustomerSelfServiceFailed = CustomerSelfServiceResponse & {
  __typename: 'CustomerSelfServiceFailed'
  message: Scalars['String']['output']
}

export type CustomerSelfServiceSuccess = CustomerSelfServiceResponse & {
  __typename: 'CustomerSelfServiceSuccess'
  message: Scalars['String']['output']
}

export type DeliveryCancellationRequested = DeliveryPipelineOnHold &
  DeliveryStatus & {
    __typename: 'DeliveryCancellationRequested'
    pipelineStatus: DeliveryPipelineStatus
    state: DeliveryState
  }

export type DeliveryPipelineOnHold = {
  pipelineStatus: DeliveryPipelineStatus
  state: DeliveryState
}

export type DeliveryCancelled = DeliveryPipelineCancelled &
  DeliveryStatus & {
    __typename: 'DeliveryCancelled'
    pipelineStatus: DeliveryPipelineStatus
    state: DeliveryState
  }

export type DeliveryPipelineCancelled = {
  pipelineStatus: DeliveryPipelineStatus
  state: DeliveryState
}

export type DeliveryDateNotAvailableError = Error & {
  __typename: 'DeliveryDateNotAvailableError'
  message: Scalars['String']['output']
}

export type DeliveryDelivered = DeliveryPipelineComplete &
  DeliveryStatus & {
    __typename: 'DeliveryDelivered'
    dispatchDate?: Maybe<Scalars['Date']['output']>
    pipelineStatus: DeliveryPipelineStatus
    state: DeliveryState
  }

export type DeliveryPipelineComplete = {
  dispatchDate?: Maybe<Scalars['Date']['output']>
  pipelineStatus: DeliveryPipelineStatus
  state: DeliveryState
}

export type DeliveryDispatched = DeliveryPipelineComplete &
  DeliveryStatus & {
    __typename: 'DeliveryDispatched'
    dispatchDate?: Maybe<Scalars['Date']['output']>
    pipelineStatus: DeliveryPipelineStatus
    state: DeliveryState
  }

export type DeliveryMethodNotAvailableError = Error & {
  __typename: 'DeliveryMethodNotAvailableError'
  message: Scalars['String']['output']
}

export type DeliveryOnHold = DeliveryPipelineOnHold &
  DeliveryStatus & {
    __typename: 'DeliveryOnHold'
    pipelineStatus: DeliveryPipelineStatus
    state: DeliveryState
  }

export type DeliveryPacked = DeliveryPipelineInProgress &
  DeliveryStatus & {
    __typename: 'DeliveryPacked'
    pipelineStatus: DeliveryPipelineStatus
    state: DeliveryState
  }

export type DeliveryPipelineInProgress = {
  pipelineStatus: DeliveryPipelineStatus
  state: DeliveryState
}

export type DeliveryPipelineFailed = {
  pipelineStatus: DeliveryPipelineStatus
  state: DeliveryState
}

export type DeliveryPipelinePending = {
  pipelineStatus: DeliveryPipelineStatus
  state: DeliveryState
}

export type DeliveryProcessing = DeliveryPipelineInProgress &
  DeliveryStatus & {
    __typename: 'DeliveryProcessing'
    pipelineStatus: DeliveryPipelineStatus
    state: DeliveryState
  }

export type DeliveryReceived = DeliveryPipelinePending &
  DeliveryStatus & {
    __typename: 'DeliveryReceived'
    pipelineStatus: DeliveryPipelineStatus
    state: DeliveryState
  }

export type DesignAvailableColorSet = {
  __typename: 'DesignAvailableColorSet'
  id: Scalars['ID']['output']
}

export type DesignAvailableFontSet = {
  __typename: 'DesignAvailableFontSet'
  id: Scalars['ID']['output']
}

export type LabelledOccasion = {
  __typename: 'LabelledOccasion'
  /** @deprecated Labels will be stored in the client */
  label: Scalars['String']['output']
  occasion: Occasion
}

export type LabelledRelationship = {
  __typename: 'LabelledRelationship'
  /** @deprecated Labels will be stored in the client */
  label: Scalars['String']['output']
  relationship: Relationship
}

export type LineItemDeliveryRestrictedError = {
  __typename: 'LineItemDeliveryRestrictedError'
  lineItemId: Scalars['String']['output']
  restrictions: Array<Scalars['String']['output']>
}

export type LineItemToAddress = {
  addressId: Scalars['String']['input']
  lineItemId: Scalars['ID']['input']
}

export enum ModalTarget {
  Gallery = 'Gallery',
  Homepage = 'Homepage',
  Landing = 'Landing',
  Search = 'Search',
}

export type ModulePromotionalCard = {
  __typename: 'ModulePromotionalCard'
  description: Scalars['String']['output']
  icon?: Maybe<ContentImage>
  link?: Maybe<CTA>
  title: Scalars['String']['output']
}

export type MoneyInput = {
  centAmount: Scalars['Long']['input']
  currencyCode: Scalars['Currency']['input']
  fractionDigits: Scalars['Int']['input']
}

export type PreviewImage = {
  __typename: 'PreviewImage'
  height: Scalars['Int']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
  width: Scalars['Int']['output']
}

export type PreviewImages = {
  __typename: 'PreviewImages'
  images: Array<PreviewImage>
}

export type PreviewImagesResult = PreviewImages | RendererServiceError

export type ProductPage = {
  __typename: 'ProductPage'
  department: Scalars['ID']['output']
  modules: Array<ProductPageModule>
}

export type ProductPageModule = ModulePromotionalCard | ModuleUspCarousel

export type ProductPageInput = {
  parentDepartmentID: Scalars['String']['input']
  preview?: InputMaybe<Scalars['Boolean']['input']>
  subDepartmentID: Scalars['String']['input']
}

export type Recipient = BaseRecipient & {
  __typename: 'Recipient'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  nickname?: Maybe<Scalars['String']['output']>
  relationship?: Maybe<Relationship>
}

export enum RendererContentType {
  jpeg = 'jpeg',
  png = 'png',
}

export enum RendererFontFacePreset {
  BOLD = 'BOLD',
  REGULAR = 'REGULAR',
}

export enum RendererProductCategory {
  LandscapeCard = 'LandscapeCard',
  Mug = 'Mug',
  PortraitCard = 'PortraitCard',
  SquareCard = 'SquareCard',
  TShirt = 'TShirt',
  Unknown = 'Unknown',
}

export enum RequestResendErrorCode {
  ORDER_NOT_ELIGIBLE = 'ORDER_NOT_ELIGIBLE',
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  RESEND_ALREADY_EXISTS = 'RESEND_ALREADY_EXISTS',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export type RequestResendFailure = RequestResendResult & {
  __typename: 'RequestResendFailure'
  errorCode: RequestResendErrorCode
  message: Scalars['String']['output']
}

export type RequestResendSuccess = RequestResendResult & {
  __typename: 'RequestResendSuccess'
  message: Scalars['String']['output']
  orderId: Scalars['String']['output']
}

export type SelfRecipient = BaseRecipient & {
  __typename: 'SelfRecipient'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SelfServeCheck = {
  __typename: 'SelfServeCheck'
  ruleName: Scalars['String']['output']
  userFriendlyError: Scalars['String']['output']
}

export type SelfServeFailure = SelfServe & {
  __typename: 'SelfServeFailure'
  canSelfServe: Scalars['Boolean']['output']
  failedChecks?: Maybe<Array<SelfServeCheck>>
}

export type SelfServeSuccess = SelfServe & {
  __typename: 'SelfServeSuccess'
  canSelfServe: Scalars['Boolean']['output']
}

export enum Sorts {
  WEIGHTED_ADD_TO_BASKET_RANK = 'WEIGHTED_ADD_TO_BASKET_RANK',
  WEIGHTED_ADD_TO_BASKET_RANK_PLATFORM = 'WEIGHTED_ADD_TO_BASKET_RANK_PLATFORM',
  WEIGHTED_ADD_TO_BASKET_RANK_USER_AFFINITY = 'WEIGHTED_ADD_TO_BASKET_RANK_USER_AFFINITY',
  WEIGHTED_ADD_TO_BASKET_RANK_V2 = 'WEIGHTED_ADD_TO_BASKET_RANK_V2',
  WEIGHTED_CLICK_RANK = 'WEIGHTED_CLICK_RANK',
  WEIGHTED_PERSONALISED_RANK = 'WEIGHTED_PERSONALISED_RANK',
}

export enum TileLabelVariant {
  Default = 'Default',
  Promo = 'Promo',
}

export type TrackRecipientResultFailed = TrackRecipientResult & {
  __typename: 'TrackRecipientResultFailed'
  message: Scalars['String']['output']
}

export type TrackRecipientResultSuccess = TrackRecipientResult & {
  __typename: 'TrackRecipientResultSuccess'
  message: Scalars['String']['output']
}

export type UserNotFound = Error & {
  __typename: 'UserNotFound'
  email: Scalars['String']['output']
  message: Scalars['String']['output']
}
